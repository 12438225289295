import { defineComponent, Ref, ref, computed } from 'vue';
import { EclLink, EclLinkType, EclMenu, MenuItems, EclSiteHeaderType } from '../../';
import EclSiteHeaderCore from '../EclSiteHeaderCore/EclSiteHeaderCore.component.vue';
import { baseClassName } from '../../../helpers/helpers';
import useEcl from './../../AppCurrentInstance';

export enum EclSiteHeaderHarmonisedGroup {
  Group1 = 'group1',
}

export interface ClassName {
  label: string;
  url: string;
}

export default defineComponent({
  name: 'EclSiteHeaderHarmonised',
  emits: ['performSearch'],
  components: {
    EclSiteHeaderCore,
    EclLink,
    EclMenu,
  },
  props: {
    className: {
      type: Object as () => ClassName,
      required: false,
      default: null,
    },
    menuItems: {
      type: Array as () => MenuItems,
      required: true,
    },
    siteName: {
      type: String,
      required: true,
    },
    showAuth: EclSiteHeaderCore.props.showAuth,
    showSearch: EclSiteHeaderCore.props.showSearch,
    showLanguage: EclSiteHeaderCore.props.showLanguage,
  },
  setup(props, { emit }) {
    const refHeaderCore: Ref<HTMLElement | undefined> = ref();

    const isEc = computed(() => useEcl().theme === 'ec');

    const menuIsOpen = ref(false);

    return {
      refHeaderCore,
      isEc,
      menuIsOpen,
      performSearch: (value: string, event: Event) => {
        emit('performSearch', value, event);
      },
      classNameLabel: props.className && props.className.label ? props.className.label : null,
      classNameUrl: props.className && props.className.url ? props.className.url : null,
      elementClassName: (suffix: string): string => {
        // return baseClassName('ecl-site-header', EclSiteHeaderType.Harmonised, suffix);
        return baseClassName('ecl-site-header', null, suffix);
      },
      EclSiteHeaderType,
      EclSiteHeaderHarmonisedGroup,
      EclLinkType,
    };
  },
});
