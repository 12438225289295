import { defineComponent, unref } from 'vue';
import { assign } from 'lodash';
import { useRouter } from 'vue-router';
import LocaleManager, { Language } from '@/managers/LocaleManager';
import { EclIcon, EclButton, EclButtonType, EclIconSize, EclLinkType } from '../';
import { EclLink } from '../Navigation/EclLink';
import useLanguage from './composables/useLanguage';

export default defineComponent({
  name: 'EclLanguageList',
  components: {
    EclIcon,
    EclButton,
    EclLink,
  },
  emits: ['close'],
  setup(props, { emit }) {
    const router = useRouter();
    const languages = useLanguage();

    const urlLocaleChange = (language: Language): string => {
      if (language.urlToRoot === false) {
        const currentRoute = unref(router.currentRoute);
        const url = LocaleManager.makeUrl({
          name: currentRoute.name as string,
          params: assign({}, currentRoute.params),
          query: assign({}, currentRoute.query, { lng: language.code }),
        });
        return url;
      }
      return LocaleManager.makeUrlLocaleChange(language.code);
    };

    const performLocaleChange = (language: Language): void => {
      // const url = urlLocaleChange(language);
      // window.location.href = url;
      const currentRoute = unref(router.currentRoute);
      LocaleManager.performLocaleChange(language, currentRoute);
      emit('close');
    };

    return {
      LocaleManager,
      ...languages,
      performLocaleChange,
      urlLocaleChange,
      languageIsActive: (language: Language): boolean => {
        return language.code === LocaleManager.getCurrentCode();
      },
      EclButtonType,
      EclIconSize,
      EclLinkType,
    };
  },
});
