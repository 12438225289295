import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "ecl-language-list ecl-language-list--overlay",
  role: "dialog"
}
const _hoisted_2 = { class: "ecl-language-list--overlay__header" }
const _hoisted_3 = { class: "ecl-language-list__list" }
const _hoisted_4 = { class: "ecl-language-list__list" }

export function render(_ctx, _cache) {
  const _component_ecl_button = _resolveComponent("ecl-button")
  const _component_ecl_column = _resolveComponent("ecl-column")
  const _component_ecl_icon = _resolveComponent("ecl-icon")
  const _component_ecl_link = _resolveComponent("ecl-link")
  const _component_ecl_row = _resolveComponent("ecl-row")
  const _component_ecl_container = _resolveComponent("ecl-container")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_ecl_container, { class: "ecl-language-list__container" }, {
      default: _withCtx(() => [
        _createVNode("div", _hoisted_2, [
          _createVNode(_component_ecl_column, {
            class: "ecl-language-list__close",
            col: "12",
            l: "8",
            "offset-l": "2",
            m: "11"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ecl_button, {
                class: "ecl-language-list__close-button",
                icon: "close",
                iconSize: _ctx.EclIconSize.S,
                type: _ctx.EclButtonType.Ghost,
                text: _ctx.$t('ECLV3.GENERAL.CLOSE'),
                ariaLabel: _ctx.$t('ECLV3.GENERAL.CLOSE'),
                onClick: _cache[1] || (_cache[1] = $event => (this.$emit('close')))
              }, null, 8, ["iconSize", "type", "text", "ariaLabel"])
            ]),
            _: 1
          }),
          _createVNode(_component_ecl_column, {
            id: "ecl-language-list__title",
            class: "ecl-language-list__title",
            col: "12",
            l: "8",
            "offset-l": "2",
            m: "11",
            "offset-m": "1"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ecl_icon, {
                class: "ecl-language-list__title-icon",
                icon: "generic-lang",
                size: _ctx.EclIconSize.M
              }, null, 8, ["size"]),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('ECLV3.LANGUAGES.SELECT_LANGUAGE_LABEL')), 1)
            ]),
            _: 1
          })
        ]),
        _createVNode(_component_ecl_row, { class: "ecl-language-list__eu" }, {
          default: _withCtx(() => [
            _createVNode(_component_ecl_column, {
              class: "ecl-language-list__category",
              col: "12",
              l: "8",
              "offset-l": "2",
              m: "11",
              "offset-m": "1",
              innerHTML: _ctx.$t('ECLV3.LANGUAGES.EU_LABEL')
            }, null, 8, ["innerHTML"]),
            _createVNode(_component_ecl_column, {
              class: "ecl-language-list__column",
              col: "12",
              l: "8",
              "offset-l": "2",
              m: "10",
              "offset-m": "1"
            }, {
              default: _withCtx(() => [
                _createVNode("ul", _hoisted_3, [
                  (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.euLanguages, (language, index) => {
                    return (_openBlock(), _createBlock("li", {
                      key: index,
                      class: ["ecl-language-list__item", { 'ecl-language-list__item--is-active': _ctx.languageIsActive(language) }]
                    }, [
                      _createVNode(_component_ecl_link, {
                        class: "ecl-language-list__link",
                        type: _ctx.EclLinkType.Standalone,
                        url: _ctx.urlLocaleChange(language),
                        label: language.name,
                        ariaLabel: language.name,
                        lang: language.code,
                        hrefLang: language.code,
                        rel: "alternate",
                        icon: _ctx.languageIsActive(language) ? 'check' : null,
                        onClick: _withModifiers($event => (_ctx.performLocaleChange(language)), ["prevent"])
                      }, null, 8, ["type", "url", "label", "ariaLabel", "lang", "hrefLang", "icon", "onClick"])
                    ], 2))
                  }), 128))
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (_ctx.extraEuLanguages.length > 0)
          ? (_openBlock(), _createBlock(_component_ecl_row, {
              key: 0,
              class: "ecl-language-list__non-eu"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ecl_column, {
                  class: "ecl-language-list__category",
                  col: "12",
                  l: "8",
                  "offset-l": "2",
                  m: "11",
                  "offset-m": "1",
                  innerHTML: _ctx.$t('ECLV3.LANGUAGES.NON_EU_LABEL')
                }, null, 8, ["innerHTML"]),
                _createVNode(_component_ecl_column, {
                  class: "ecl-language-list__column",
                  col: "12",
                  l: "8",
                  "offset-l": "2",
                  m: "10",
                  "offset-m": "1"
                }, {
                  default: _withCtx(() => [
                    _createVNode("ul", _hoisted_4, [
                      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.extraEuLanguages, (language, index) => {
                        return (_openBlock(), _createBlock("li", {
                          key: index,
                          class: ["ecl-language-list__item", { 'ecl-language-list__item--is-active': _ctx.languageIsActive(language) }]
                        }, [
                          _createVNode(_component_ecl_link, {
                            class: "ecl-language-list__link",
                            type: _ctx.EclLinkType.Standalone,
                            url: _ctx.urlLocaleChange(language),
                            label: language.name,
                            ariaLabel: language.name,
                            lang: language.code,
                            hrefLang: language.code,
                            rel: "alternate",
                            icon: _ctx.languageIsActive(language) ? 'check' : null,
                            onClick: _withModifiers($event => (_ctx.performLocaleChange(language)), ["prevent"])
                          }, null, 8, ["type", "url", "label", "ariaLabel", "lang", "hrefLang", "icon", "onClick"])
                        ], 2))
                      }), 128))
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}