import { GetterTree } from 'vuex';
import { ToolModuleStore } from './types';
import { RootState } from '@/store/types';

export const getters: GetterTree<ToolModuleStore, RootState> = {
  clickFo(state): boolean {
    return state.tool.clickFo;
  },
  clickFoId(state): string {
    return state.tool.clickFoId;
  },
  clickFoName(state): string {
    return state.tool.clickFoName;
  },
  clickFoColor(state): string {
    return state.tool.clickFoColor;
  },
  bboxSv(state): [] {
    return state.tool.bboxSv;
  },
  filterWho(state): [] {
    return state.tool.filterWho;
  },
  filterThemFocus(state): [] {
    return state.tool.filterThemFocus;
  },
  filterType(state): [] {
    return state.tool.filterType;
  },
  funding(state): [] {
    return state.tool.funding;
  },
  inspired(state): [] {
    return state.tool.inspired;
  },
  typeColor(state): [] {
    return state.tool.typeColor;
  },
  text1(state): string {
    return state.tool.text1;
  },
  clickInspire(state): boolean {
    return state.tool.clickInspire;
  },
  clickInspireId(state): string {
    return state.tool.clickInspireId;
  },
  clickInspireIds(state): [] {
    return state.tool.clickInspireIds;
  },
  openInspire(state): boolean {
    return state.tool.openInspire;
  },
  combInspire(state): boolean {
    return state.tool.combInspire;
  },
  monoInspire(state): boolean {
    return state.tool.monoInspire;
  },
  totalCountInspire(state): number {
    return state.tool.totalCountInspire;
  },
  panelOpen(state): boolean {
    return state.tool.panelOpen;
  },
  inspireKey(state): [] {
    return state.tool.inspireKey;
  },
  inspireFund(state): [] {
    return state.tool.inspireFund;
  },
  listFund(state): [] {
    return state.tool.listFund;
  },
  listPillar(state): [] {
    return state.tool.listPillar;
  },
  pillarInspire(state): [] {
    return state.tool.pillarInspire;
  },
  listGlossary(state): [] {
    return state.tool.listGlossary;
  },
  listKeyw(state): [] {
    return state.tool.listKeyw;
  },
};
