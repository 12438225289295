import { ref, toRefs, computed, Ref, unref, ComputedRef } from 'vue';
import { indexOf } from 'lodash';
import { isMobileOrTablet } from '@/shared/ecl/src/helpers/detect-device';
import { BaseDependencies } from '../JclAutocomplete.component';

export default function useAutocomplete(props, dependencies: BaseDependencies) {
  const { disabled } = toRefs(props);

  // ================ DATA ================

  const UUID = dependencies.UUID;
  const autocompleteBlur: Ref<HTMLElement>[] = [];
  const searchInputElement: Ref<HTMLElement> = ref(null);
  const useNative: boolean = isMobileOrTablet();
  const focused: Ref<boolean> = ref(false);
  const skipGenericBlur: Ref<boolean> = ref(false);

  // ============== COMPUTED ==============

  const searchId = computed(() => {
    return 'autocomplete-' + unref(UUID);
  });

  const tabindex = computed(() => {
    return -1;
  });

  const isDisabled: ComputedRef<boolean> = computed(() => {
    return disabled.value;
  });

  // =============== METHODS ==============

  const addBlurElementRef = (e: Ref<HTMLElement>) => {
    if (e && indexOf(autocompleteBlur, e) < 0) {
      autocompleteBlur.push(e);
    }
  };

  const onFocus = (): void => {
    focused.value = true;
  };

  const triggerFocus = (): void => {
    skipGenericBlur.value = true;
    onFocus();
  };

  return {
    skipGenericBlur,
    triggerFocus,
    onFocus,
    focused,
    useNative,
    addBlurElementRef,
    autocompleteBlur,
    searchInputElement,
    searchId,
    tabindex,
    isDisabled
  };
}
