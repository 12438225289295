import { SetupContext } from 'vue';
import useData from './useData';

export default function useModal(context: SetupContext, dependencies: { useData: ReturnType<typeof useData> }) {
  // ================ DATA ================

  // ============ DEPENDENCIES ============
  const visible = dependencies.useData.visible;

  // ============== COMPUTED ==============

  // =============== METHODS ==============
  const modalHide = (): void => {
    visible.value = false;
    context.emit('hide');
  };

  // ============== WATCHERS ==============

  return {
    modalHide,
  };
}
