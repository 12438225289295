import { defineComponent, SetupContext } from 'vue';

import { EclParagraph, EclButton, EclButtonType, EclButtonIconPosition, EclIconSize } from '../';

import useData from './composables/useData';
import useModal from './composables/useModal';
import useActions from './composables/useActions';
import useTransitions from './composables/useTransitions';

export default defineComponent({
  name: 'EclModal',
  components: {
    EclButton,
    EclP: EclParagraph,
  },
  emits: ['update:modelValue', 'hide', 'show', 'close', 'close:backdrop'],
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    backdropClose: {
      type: Boolean,
      required: false,
      default: true,
    },
    title: {
      type: String,
      required: false,
    },
    body: {
      type: String,
      required: false,
    },
  },
  setup(props, context: SetupContext) {
    const data = useData(props, context);
    const transitions = useTransitions({ useData: data });
    const modal = useModal(context, { useData: data });
    const actions = useActions(props, context, { useModal: modal });

    return {
      ...transitions,
      ...data,
      ...actions,
      EclButtonIconPosition,
      EclButtonType,
      EclIconSize,
    };
  },
});
