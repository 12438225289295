import { onMounted, watch, toRefs, ref, unref } from 'vue';
import { assign, isNil, isEqual, isEmpty } from 'lodash';

import { CustomInteraction, OlSettings } from '../types';

import { Map, Collection } from 'ol';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import { fromLonLat } from 'ol/proj';
import { defaults as interactionDefaults, DragPan, Interaction, MouseWheelZoom } from 'ol/interaction';
import { Control, defaults as controlDefaults } from 'ol/control';
import { platformModifierKeyOnly } from 'ol/events/condition';

import useWms from './useWms';
import useWfs from './useWfs';
import useAttributions from './useAttributions';
import { isMobileOrTablet } from '../../../helpers/detect-device';
import { t } from '@/locale';
import { UseHelper } from './useHelper';
import { UseData } from './useData';
import { UseTooltip } from './useTooltip';

export default function useMap(
  props,
  dependencies: { helper: UseHelper; data: UseData; tooltip: UseTooltip },
  context
) {
  const { zoom, center } = toRefs(props);

  // ============ DEPENDENCIES ============
  const settings = dependencies.data.settings;
  const mapElement = dependencies.data.mapElement;
  const mapInstance = dependencies.data.mapInstance;
  const mapComponent = dependencies.data.mapComponent;
  const tooltipData = dependencies.tooltip.tooltipData;
  const helper: UseHelper = dependencies.helper;

  // ================ DATA ================
  const WFS = useWfs({ settings });
  const WMS = ref(null);
  const currentSettings = {
    defaultSourceUrl: '',
    view: {}
  };
  const attributions = useAttributions({
    mapComponent: mapComponent,
    settings: settings
  });

  // ============== COMPUTED ==============

  // =============== METHODS ==============
  const makeMapInteractionsAndControls = (): {
    interactions: Collection<Interaction>;
    controls: Collection<Control>;
  } => {
    const _olSettings: OlSettings = unref(settings).olSettings;
    const _customInteraction: CustomInteraction = unref(settings).customInteraction;

    // Interactions update
    const currentInteractions: Collection<Interaction> = unref(mapInstance)
      ? unref(mapInstance).getInteractions()
      : new Collection();
    const interactionsObject = _olSettings?.interactions ?? {};
    const interactionExtraList = _olSettings?.extra?.interactions ?? [];

    if (_customInteraction) {
      if (isMobileOrTablet() && _customInteraction?.mobile?.useDoubleTouch) {
        assign(interactionsObject, { dragPan: false });
        interactionExtraList.push(
          new DragPan({
            condition: function() {
              const condition = this.getPointerCount() === 2;
              if (condition) {
                helper.helperHide();
              } else {
                helper.helperShow(t(_customInteraction?.mobile?.useDoubleTouchLabel));
              }

              return condition;
            }
          })
        );
      }
      if (_customInteraction?.desktop?.useWheelPlatformKey) {
        assign(interactionsObject, { mouseWheelZoom: false });
        interactionExtraList.push(
          new MouseWheelZoom({
            condition: event => {
              if (['wheel', 'trackpad'].includes(event.type)) {
                const conditionTest = platformModifierKeyOnly(event);
                if (conditionTest) {
                  helper.helperHide();
                } else {
                  helper.helperShow(
                    t(_customInteraction?.desktop?.useWheelPlatformKeyLabel, helper.platformModifierLabel())
                  );
                }
                return conditionTest;
              }
              return false;
            }
          })
        );
      }
    }

    const interactionDefaultList = !isEmpty(interactionsObject)
      ? interactionDefaults(interactionsObject)
      : interactionDefaults();

    if (!isNil(interactionDefaultList)) {
      currentInteractions.clear();
      interactionDefaultList.forEach((k: Interaction) => {
        currentInteractions.push(k);
      });
      interactionExtraList.forEach((k: Interaction) => {
        currentInteractions.push(k);
      });
    }

    // Interactions update
    const currentControls: Collection<Control> = unref(mapInstance)
      ? unref(mapInstance).getControls()
      : new Collection();
    const constrolsObject = _olSettings?.controls ?? {};
    const constrolExtraList = _olSettings?.extra?.controls ?? [];

    const controlDefaultList = !isEmpty(interactionsObject) ? controlDefaults(constrolsObject) : controlDefaults();

    if (!isNil(controlDefaultList)) {
      currentControls.clear();
      controlDefaultList.forEach((k: Control) => {
        currentControls.push(k);
      });
      constrolExtraList.forEach((k: Control) => {
        currentControls.push(k);
      });
    }

    return {
      interactions: currentInteractions,
      controls: currentControls
    };
  };

  /**
   * Setta la vista
   */
  const makeView = (save = false): View => {
    const params = settings.value.view;
    // if (!isNil(settings.value.WMS) && !isNil(settings.value.WMS.defaultOptions)) {
    //   const crs = settings.value.WMS.defaultOptions.srs;
    //   assign(params, { projection: crs });
    // }
    if (save === true) currentSettings.view = params;

    return new View(params);
  };

  const getAttribuitionList = (): string[] => {
    let attribuitionList = [];
    if (settings.value.attributions) {
      attribuitionList = unref(attributions.attributionsContent);
    }
    return attribuitionList;
  };

  /**
   * Crea l'istanza di OpenLayer
   */
  const createInstance = (): void => {
    currentSettings.defaultSourceUrl = settings.value.tile.url;

    const tileLayer = new TileLayer({
      source: new XYZ({
        url: settings.value.tile.url,
        attributions: getAttribuitionList()
      })
    });

    mapInstance.value = new Map({
      target: mapElement.value,
      layers: [tileLayer],
      view: makeView(true),
      ...makeMapInteractionsAndControls()
    });
  };

  // ============== WATCHERS ==============

  // const attachWMS = (): void => {
  //   // attach WMS if is not present
  //   if (settings.value.WMS.layers.length > 0 && isNil(WMS)) {
  //     WMS = useWms({ settings, mapInstance, tooltipData }, context);
  //   }
  // };

  onMounted(() => {
    createInstance();

    // attachWMS();
    WMS.value = useWms({ settings, mapInstance, tooltipData }, context);

    context.emit('mounted');
  });

  watch(
    () => settings.value.tile.url,
    (newTileUrl: string) => {
      // aggiorna la source solo se i parametri sono cambiati
      if (!isNil(newTileUrl) && !isEqual(currentSettings.defaultSourceUrl, newTileUrl)) {
        const baseLayer = mapInstance.value.getLayers().getArray()[0];
        const source = new XYZ({
          url: newTileUrl,
          attributions: getAttribuitionList()
        });
        baseLayer.set('source', source);
        currentSettings.defaultSourceUrl = newTileUrl;
      }
    }
  );

  watch(
    () => settings.value.view,
    newSettings => {
      // aggiorna la view solo se i parametri sono cambiati
      if (!isEqual(currentSettings.view, newSettings)) {
        const view = makeView(true);
        mapInstance.value.setView(view);
      }
    },
    {
      deep: true
    }
  );

  watch([() => settings.value.olSettings, () => settings.value?.customInteraction], () => {
    makeMapInteractionsAndControls();
  });

  // on Zoom update
  watch(
    () => unref(zoom),
    (newZoom: number) => {
      if (!isNil(zoom)) {
        settings.value.view.zoom = newZoom;
        const time = settings.value.view.animateTime.zoom;
        if (time <= 0) {
          mapInstance.value.getView().setZoom(newZoom);
        } else {
          mapInstance.value.getView().animate({
            zoom: newZoom,
            duration: time
          });
        }
      }
    }
  );

  // on Center update
  watch(
    () => unref(center),
    (newCenter: number[]) => {
      if (!isNil(center)) {
        settings.value.view.center = fromLonLat(newCenter);
        const time = settings.value.view.animateTime.center;

        if (time <= 0) {
          mapInstance.value.getView().setCenter(settings.value.view.center);
        } else {
          mapInstance.value.getView().animate({
            center: settings.value.view.center,
            duration: time
          });
        }
      }
    }
  );

  // watch(
  //   () => settings.value.WMS.layers,
  //   (newSettings, oldSettings) => {
  //     if (oldSettings.length === 0 && newSettings.length >= 1) {
  //       useWms({ settings, mapInstance, tooltipData }, context);
  //     }
  //   }
  // );

  return {
    WFS,
    WMS
  };
}
