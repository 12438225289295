import store from '@/store';
import { SEOConfigs } from '@/configs';
import { isNil } from 'lodash';

class SEOManager {
  private static _instance: SEOManager = new SEOManager();
  private defaultSeo = null;
  private timeout = {
    title: null
  };

  constructor() {
    if (SEOManager._instance) {
      throw new Error('Error: Instantiation failed: Use SEOManager.getInstance() instead of new.');
    }
    SEOManager._instance = this;
    this.defaultSeo = SEOConfigs;
  }

  /**
   * Retrieve the class instance
   */
  public static getInstance(): SEOManager {
    return SEOManager._instance;
  }

  /**
   * Set the default metadata
   */
  public default(): void {
    this.setTitles(this.defaultSeo.title);
    this.setDescriptions(this.defaultSeo.description);
    if (!isNil(this.defaultSeo.meta)) {
      const meta = this.defaultSeo.meta;
      const metaList = Object.keys(meta).map(key => {
        return {
          name: key,
          content: meta[key]
        };
      });
      store.commit('meta/list', metaList);
    }
  }

  /**
   * Set metadata title
   *
   * @param {string} msg - The string text
   */
  public setMetaTitle(msg: string): void {
    store.commit('meta/title', msg);
  }

  /**
   * Set metadata description
   *
   * @param {string} msg - The string text
   */
  public setMetaDescription(msg: string): void {
    store.commit('meta/description', msg);
  }

  /**
   * Set page header component title
   *
   * @param {string} msg - The string text
   */
  public setPageHeaderTitle(msg: string): void {
    store.commit('site/title', msg);
  }

  /**
   * Set page header component description
   *
   * @param {string} msg - The string text
   */
  public setPageHeaderDescription(msg: string): void {
    store.commit('site/description', msg);
  }

  /**
   * Set all title on the page (metadata and page header component)
   *
   * @param {string} msg - The string text
   */
  public setTitles(msg: string): void {
    if (!isNil(this.timeout.title)) {
      clearTimeout(this.timeout.title);
    }
    this.timeout.title = setTimeout(() => {
      this.setPageHeaderTitle(msg);
      this.setMetaTitle(msg);
    }, 15);
  }

  /**
   * Set all description on the page (metadata and page header component)
   *
   * @param {string} msg - The string text
   */
  public setDescriptions(msg: string): void {
    this.setPageHeaderDescription(msg);
    this.setMetaDescription(msg);
  }
}

export default SEOManager.getInstance();
