/* eslint-disable @typescript-eslint/no-use-before-define */

import { computed, ComputedRef, nextTick, ref, Ref, toRefs, watch, unref, SetupContext } from 'vue';
import { assign, head, some } from 'lodash';
import { JclAutocomplete } from '../';
import Popper, { createPopper, Placement } from '@popperjs/core';

export type UseDropdown = {
  isOpen: ComputedRef<boolean>;
  open: () => void;
  close: () => void;
  toggleDropdown: () => void;
  focusedOnDropdown: Ref<boolean>;
  globalBlurEvent: (event: Event | FocusEvent) => void;
  popperInstance: Ref<Popper.Instance>;
};

export default function useDropdown(
  props: InstanceType<typeof JclAutocomplete>['$props'],
  context: SetupContext,
  dependencies
): UseDropdown {
  const { disabled, contentWrapper, dropdownPopperOptions } = toRefs(props);

  // ============ DEPENDENCIES ============

  // const hasList: ComputedRef<boolean> = dependencies.hasList;
  const focused: Ref<boolean> = dependencies.focused;
  const autocompleteBlur = dependencies.autocompleteBlur;
  const skipGenericBlur = dependencies.skipGenericBlur;
  // const searchInputElement: Ref<HTMLElement> = dependencies.searchInputElement;

  // ================ DATA ================
  const focusedOnDropdown: Ref<boolean> = ref(false);
  const popperInstance = ref() as Ref<Popper.Instance>;

  // ============== COMPUTED ==============

  const isOpen: ComputedRef<boolean> = computed((): boolean => {
    return focused.value;
  });

  // =============== METHODS ==============

  let throttleGBE: number | null = null;
  const globalBlurEvent = (event: Event | FocusEvent): void => {
    const target = event instanceof FocusEvent ? event.relatedTarget : event.target;
    const isInsideAutocomplete = some(autocompleteBlur, element => {
      return element === target || element.contains(target);
    });
    if (unref(skipGenericBlur)) {
      skipGenericBlur.value = false;
    } else if (isInsideAutocomplete === false) {
      if (throttleGBE) {
        clearTimeout(throttleGBE);
      }
      throttleGBE = setTimeout(() => {
        close();
      }, 120);
    }
  };

  const open = (): void => {
    if (disabled.value) {
      return;
    }
    focused.value = true;

    document.body.addEventListener('click', globalBlurEvent);
    context.emit('open');
  };

  const close = (): void => {
    document.body.removeEventListener('click', globalBlurEvent);
    focused.value = false;
    context.emit('close');
  };

  const toggleDropdown = (): void => {
    if (throttleGBE) {
      clearTimeout(throttleGBE);
    }
    throttleGBE = setTimeout(() => {
      unref(isOpen) ? close() : open();
    }, 120);
  };

  // ============== WATCHERS ==============
  watch(focused, (value: boolean) => {
    if (value === true) {
      open();
    }
  });

  watch(isOpen, () => {
    const _main = head(autocompleteBlur) as HTMLElement;
    const select = head(_main.getElementsByClassName('ecl-form-group')) as HTMLElement;
    const dropdown = head(_main.getElementsByClassName('jcl-autocomplete__dropdown')) as HTMLElement;
    const _cw = unref(contentWrapper);

    nextTick(() => {
      if (_cw) {
        // se esiste il content wrapper, imposta l'altezza massima alla dropdown
        const _cwRect = _cw.getBoundingClientRect();
        const dropdownMaxHeight = _cwRect.height - (dropdown.getBoundingClientRect().top - _cwRect.top);
        dropdown.style.maxHeight = `${dropdownMaxHeight}px`;
      }
      if (popperInstance.value) {
        unref(popperInstance).destroy();
        popperInstance.value = null;
      } else {
        const opts = assign({ placement: 'bottom-start' as Placement }, unref(dropdownPopperOptions));
        popperInstance.value = createPopper(select, dropdown, opts);
      }
    });
  });

  return {
    isOpen,
    open,
    close,
    toggleDropdown,
    focusedOnDropdown,
    globalBlurEvent,
    popperInstance
  };
}
