import { MetaModuleStore, MetaListElement, MetaList } from './types';
import { MutationTree } from 'vuex';

export const mutations: MutationTree<MetaModuleStore> = {
  title(state, payload: string): void {
    state.meta.title = payload;
    const title: MetaList = [
      {
        name: 'og:title',
        content: payload
      },
      {
        name: 'twitter:title',
        content: payload
      }
    ];
    mutations.list(state, title);
  },
  description(state, payload: string): void {
    const description: MetaList = [
      {
        name: 'description',
        content: payload
      },
      {
        name: 'og:description',
        content: payload
      },
      {
        name: 'twitter:description',
        content: payload
      }
    ];
    mutations.list(state, description);
  },
  keywords(state, payload: string[]): void {
    const keywords: MetaListElement = {
      name: 'keywords',
      content: payload.join(',')
    };
    mutations.setMeta(state, keywords);
  },
  list(state, payload: MetaList): void {
    state.meta.list = payload;
  },
  info(state, payload: MetaListElement): void {
    state.meta.list.push(payload);
  }
};
