import { ComponentPublicInstance, createApp, getCurrentInstance, nextTick } from 'vue';
import { each } from 'lodash';

import i18n, { t } from '@/locale';
import { EclModal } from '@/shared/ecl/src/components';
import ECL from '@/shared/ecl/src';

export default function useModal() {
  // ================ DATA ================
  const modalList = [];
  const currentApp = getCurrentInstance();

  // ============ DEPENDENCIES ============

  // ============== COMPUTED ==============

  // =============== METHODS ==============

  const generateModal = (opts: { name?: string; title: string; content?: string }) => {
    if (opts?.name) {
      const allModalByName = document.getElementsByClassName(`modal-wrapper--${opts.name}`);
      each(allModalByName, (modalEl: Element) => modalEl.remove());
    }

    const modalElement: HTMLDivElement = document.createElement('div');
    modalElement.className = `modal-wrapper modal-wrapper--${opts?.name ?? modalList.length}`;
    document.body.appendChild(modalElement);

    const disclaimerTitle = opts.title;
    const disclaimerContent = opts?.content;

    const modalApp = createApp(EclModal, { modelValue: true, title: disclaimerTitle, body: disclaimerContent });
    modalApp.use(
      ECL,
      currentApp?.appContext?.config?.globalProperties?.$ecl ?? { theme: process.env.VUE_APP_ECL_THEME }
    );
    modalApp.use(i18n);

    nextTick(() => {
      const instanceMounted = modalApp.mount(modalElement) as ComponentPublicInstance<typeof EclModal>;
      modalList.push({ element: modalElement, app: modalApp, instance: instanceMounted });
    });
  };

  const alertETranslation = () => {
    generateModal({
      name: 'language',
      title: t('general.languages.alert.title'),
      content: t('general.languages.alert.content')
    });
  };

  // ============== WATCHERS ==============

  return {
    alertETranslation
  };
}
