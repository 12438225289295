import { renderSlot as _renderSlot, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

export function render(_ctx, _cache) {
  const _component_router_link = _resolveComponent("router-link")

  return (_ctx.isExternalLink)
    ? (_openBlock(), _createBlock("a", _mergeProps({ key: 0 }, _ctx.$attrs, {
        href: _ctx.finalUrl,
        target: _ctx.targetLink
      }), [
        _renderSlot(_ctx.$slots, "default")
      ], 16, ["href", "target"]))
    : (_openBlock(), _createBlock(_component_router_link, _mergeProps({ key: 1 }, _ctx.$props, { to: _ctx.finalUrl }), {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 16, ["to"]))
}