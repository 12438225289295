import { toRefs, computed, ComputedRef, unref } from 'vue';
import { assign, isArray, uniqueId } from 'lodash';
import { EclCheckbox, Checkbox, CheckboxItem } from '../';

export default function useCheckbox(props: InstanceType<typeof EclCheckbox>['$props']) {
  const { checkboxes, valueProp, modelValue } = toRefs(props);

  // ============== COMPUTED ==============

  const makeId = (item?: Checkbox) => {
    return `ecl-checkbox__checkbox-${item?.value ?? uniqueId()}`;
  };

  const extendedCheckboxes: ComputedRef<CheckboxItem[]> = computed(() => {
    const _ck = unref(checkboxes);
    if (isArray(_ck)) {
      return _ck.map((checkbox: any) => {
        return assign(
          {
            id: makeId(checkbox),
            helpText: null,
            checked: null,
            disabled: null,
            [unref(valueProp) as keyof CheckboxItem]: _ck,
          },
          checkbox,
        ) as CheckboxItem;
      });
    } else {
      const item = (assign(
        {},
        {
          id: makeId(),
          [unref(valueProp) as keyof CheckboxItem]: _ck,
          checked: unref(modelValue) === _ck,
          disabled: null,
        },
      ) as unknown) as CheckboxItem;
      return [item];
    }
  });

  return {
    extendedCheckboxes,
  };
}
