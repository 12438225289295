import { Module } from 'vuex';
import { RootState } from '../../types';
import { SiteModuleStore } from './types';

import { getters } from './getters';
import { mutations } from './mutations';

export const getDefaultStateSite = (): SiteModuleStore => {
  return {
    i18nLoading: true,
    loading: true,
    name: null,
    contentOwnerDetails: null,
    mainNav: null,
    title: null,
    metaInfo: null,
    description: null,
    breadcrumb: [],
    // contextNav: null,
    // pagesConfig: {},
    contextListAvailable: []
  };
};

export const state: SiteModuleStore = getDefaultStateSite();

export const site: Module<SiteModuleStore, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations
};
