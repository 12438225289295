import { ref, watch, SetupContext, unref, Ref } from 'vue';

export type UseSearch = {
  search: Ref<string | null>;
  input: Ref<HTMLElement | null>;
  clearSearch: () => void;
  blurSearch: () => void;
};

export default function useSearch(context: SetupContext) {
  // ============ DEPENDENCIES ============

  // ================ DATA ================

  const search: Ref<string | null> = ref(null);

  const input: Ref<HTMLElement | null> = ref(null);

  // ============== COMPUTED ==============

  // =============== METHODS ==============

  const clearSearch = (): void => {
    search.value = '';
  };

  const blurSearch = (): void => {
    const _i = unref(input);
    if (_i) {
      _i.blur();
    }
  };

  // ============== WATCHERS ==============

  watch(search, val => {
    context.emit('search-change', val);
  });

  return {
    search,
    input,
    clearSearch,
    blurSearch,
  };
}
