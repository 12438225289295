import { ApolloClient as ApolloClientOriginal, HttpLink, InMemoryCache, ApolloLink, from } from '@apollo/client/core';
import ApolloLinkTimeout from 'apollo-link-timeout';
import { LocaleManager } from '@/managers';
import { isNil } from 'lodash';
import { unref } from 'vue';

const timeoutLink = new ApolloLinkTimeout(10000); // 10 second timeout

// HTTP connection to the API
const makeLink = (type = 'public'): HttpLink => {
  const httpLink = new HttpLink({
    uri:
      type === 'private'
        ? process.env.VUE_APP_API_GRAPHQL_BASEURL_PRIVATE
        : process.env.VUE_APP_API_GRAPHQL_BASEURL_PUBLIC
  });

  return httpLink;
};

const languageLink = new ApolloLink((operation, forward) => {
  // retrive the saved header
  const customHeaders = !isNil(operation.getContext().headers) ? operation.getContext().headers : {};
  const lang = !isNil(LocaleManager) && !isNil(LocaleManager.locale) && !isNil(unref(LocaleManager.locale))
    ? unref(LocaleManager.locale)
    : 'en';
  // const lang = 'en';
  // console.log('>>SISTEMARE lang');

  // update the header with the language
  operation.setContext({
    headers: {
      ...customHeaders,
      'Accept-Language': lang || ''
    }
  });

  return forward(operation);
});

const makeApolloClient = (type = 'public') => {
  const cache = new InMemoryCache();
  return new ApolloClientOriginal({
    link: from([languageLink, timeoutLink, makeLink(type)]),
    cache,
    connectToDevTools: true
  });
};

// Create the apollo client
const ApolloClient = makeApolloClient();

export default ApolloClient;
export { makeApolloClient };
