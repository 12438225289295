interface SEOConfigs {
  title: string;
  description: string;
  meta?: {
    author?: string;
    creator?: string;
    'og:site_name'?: string;
    'og:type'?: string;
    'og:url'?: string;
    'og:image'?: string;
    'og:image:width'?: string;
    'og:image:height'?: string;
    'twitter:card'?: string;
    'twitter:site'?: string;
    'twitter:image'?: string;
  };
}

const SEOConfigs: SEOConfigs = {
  title: process.env.VUE_APP_PROJECT_NAME,
  description: '',
  meta: {
    author: 'European Commission, European Union',
    creator: 'COMM/JRC/B3',
    'og:site_name': process.env.VUE_APP_PROJECT_NAME,
    'og:type': 'website',
    'og:url': process.env.VUE_APP_BASE_URL,
    'og:image': process.env.VUE_APP_BASE_URL + '/startup-banner.png',
    'og:image:width': '1200',
    'og:image:height': '630',
    // 'twitter:card': 'summary_large_image',
    // 'twitter:site': '@EU_Commission',
    // 'twitter:image': process.env.VUE_APP_BASE_URL + '/startup-banner.png'
  }
};
export default SEOConfigs;
