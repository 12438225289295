import breadcrumbConfigs from './breadcrumb';
import SEOConfigs from './seo';
import localeConfigs from './locale';
import headerConfigs from './header';
import { UrlMappingParams } from '../helpers/url/Url';

export type UrlMapping = Record<string, UrlMappingParams>;

export type AppConfigsType = {
  api: {
    params: {
      site: string;
      language: string;
      interface: string;
      menu: string;
    };
  };
  // context: {
  //   slug: string;
  // };
  url: {
    mapping: UrlMapping;
  };
  presets: {
    trends: {
      models: Record<string, string | number>;
    };
  };
};

const appConfigs: Partial<AppConfigsType> = {
  api: {
    params: {
      site: process.env.VUE_APP_CONFIG_API_PARAMS_SITE,
      language: process.env.VUE_APP_CONFIG_API_PARAMS_LANGUAGE,
      interface: process.env.VUE_APP_CONFIG_API_PARAMS_INTERFACE,
      menu: process.env.VUE_APP_CONFIG_API_PARAMS_MENU
    }
  }
};

export { appConfigs, breadcrumbConfigs, SEOConfigs, localeConfigs, headerConfigs };
