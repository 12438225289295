import { Ref, ref } from 'vue';

class AppManager {
  private static _instance: AppManager = new AppManager();
  public loading: Ref<boolean> = ref(true);

  constructor() {
    if (AppManager._instance) {
      throw new Error('Error: Instantiation failed: Use AppManager.getInstance() instead of new.');
    }
    AppManager._instance = this;
  }

  /**
   * Retrieve the class instance
   */
  public static getInstance(): AppManager {
    return AppManager._instance;
  }

  /**
   * Start the loading
   */
  public startLoading(): void {
    this.loading.value = true;
  }

  /**
   * Stop the loading
   */
  public stopLoading(): void {
    this.loading.value = false;
  }
}

export default AppManager.getInstance();
