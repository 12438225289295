/* eslint-disable @typescript-eslint/no-use-before-define */

import { onMounted, Ref, ref, watch } from 'vue';
import { isBoolean } from 'lodash';
import { EclButton } from '@/shared/ecl/src/components';
import { useRouter } from 'vue-router';

export default function useFooterNavigation(dependencies: {
  refFooter: Ref<typeof EclButton | undefined>;
  minimalInterface: Ref<boolean>;
}) {
  // ================ DATA ================
  const footerIsVisible: Ref<boolean> = ref(false);
  const refFooterButtonClose: Ref<typeof EclButton> = ref(null);
  const refHeaderButtonToggleFooter: Ref<typeof EclButton> = ref(null);
  const footerOverrides = ref();
  const router = useRouter();
  // const footerNavItems = ref(EclFooterGenerator.generateHarmonisedGroup1());

  // ============ DEPENDENCIES ============
  const refFooter = dependencies.refFooter;
  const minimalInterface = dependencies.minimalInterface;

  // ============== COMPUTED ==============

  // =============== METHODS ==============
  const clickOutsideFooter = (event: MouseEvent): void => {
    if (!refFooter.value.contains(event.target)) {
      footerMenuToggle(false);
    } else {
      document.addEventListener('click', clickOutsideFooter, { once: true, passive: true });
    }
  };

  const footerMenuToggle = (forcedValue?: boolean): void => {
    footerIsVisible.value = forcedValue && isBoolean(forcedValue) ? forcedValue : !footerIsVisible.value;
    document.removeEventListener('click', clickOutsideFooter);
    if (footerIsVisible.value) {
      setTimeout(() => {
        refFooterButtonClose.value.$el.focus();
        document.addEventListener('click', clickOutsideFooter, { once: true, passive: true });
      }, 150);
    } else {
      setTimeout(() => {
        refHeaderButtonToggleFooter.value.$el.focus();
      }, 150);
    }
  };

  // ============== WATCHERS ==============

  watch(
    () => minimalInterface.value,
    (newValue: boolean) => {
      footerIsVisible.value = newValue === false;
    }
  );

  onMounted((): void => {
    const hasRouteAccessibility = router.hasRoute('accessibility-statement');

    if (hasRouteAccessibility) {
      let url = router.resolve({ name: 'accessibility-statement' }).href;
      url = url.replace(process.env.BASE_URL, '/');
      footerOverrides.value = {
        linkList: {
          accessibility: url
        }
      };
    }

    footerIsVisible.value = minimalInterface.value === false;
  });

  // watch(
  //   LocaleManager.locale,
  //   (locale: string) => {
  //     if (!isNil(locale)) {
  //       footerNavItems.value = EclFooterGenerator.generateHarmonisedGroup1();
  //     }
  //   },
  //   {
  //     immediate: true
  //   }
  // );

  return {
    footerOverrides,
    footerIsVisible,
    refFooterButtonClose,
    refHeaderButtonToggleFooter,
    // footerNavItems,
    footerMenuToggle
  };
}
