import { isNil } from 'lodash';

interface CheckExternalUrl {
  (url: string): boolean;
}

interface GetTarget {
  (target: string): string;
}

/**
 * Verify if the string is an external link
 *
 * @param {string} url - String to check
 * @return {boolean} Whether or not @url is an external link
 */
const checkExternalUrl: CheckExternalUrl = (url: string): boolean => {
  return (
    typeof url === 'string' &&
    (url.startsWith('http') ||
      url.startsWith('www.') ||
      url.startsWith('tel:') ||
      url.startsWith('mailto:') ||
      url.startsWith('javascript:void(0)'))
  );
};

/**
 * Convert string to the correct link target
 *
 * @param {string} target
 * @return {string} The link target based on @target string
 */
const getTarget: { (target: string): string } = (target: string): string => {
  return !isNil(target) && target.includes('self') ? '_self' : '_blank';
};

export { checkExternalUrl, getTarget };
