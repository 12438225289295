import { defineComponent, h } from 'vue';

export enum EclParagraphType {
  'LEAD' = 'lead',
  'M' = 'm',
  'S' = 's',
  'XS' = 'xs',
}

export default defineComponent({
  name: 'EclParagraph',
  props: {
    class: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: false,
      default: EclParagraphType.M,
      validator: (value: string) => Object.values<string>(EclParagraphType).includes(value),
    },
  },
  render() {
    if (typeof this.$slots.default === 'function') {
      return h(
        'p',
        {
          class: this.classes(),
        },
        this.$slots.default(),
      );
    } else {
      return h('p', {
        class: this.classes(),
        domProps: this.$attrs.innerHTML,
      });
    }
  },
  methods: {
    classes() {
      const propClasses = this.class ?? '';
      return [`ecl-u-type-paragraph-${this.type}`, propClasses].join(' ').trim();
    },
  },
});
