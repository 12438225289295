import { Ref, ref, onMounted } from 'vue';
import { isNil } from 'lodash';
import { DeviceTypeCheckerTypes, DeviceTypeChecker } from '@/shared/ecl/src/helpers/detect-device';
import { useRoute } from 'vue-router';

export default function useSticky() {
  // ================ DATA ================
  const curentRoute = useRoute();
  const stickyElement: Ref<HTMLElement | undefined> = ref(undefined);
  const stickyOffset = ref({ top: 0, bottom: 0 });

  // ============ DEPENDENCIES ============

  // ============== COMPUTED ==============

  // =============== METHODS ==============
  const setStickyOffset = () => {
    if (!isNil(stickyElement.value)) {
      const el = stickyElement.value['@@vue-sticky-directive'].getContainerEl();
      let top = 0;
      const isMobile =
        [DeviceTypeCheckerTypes.MOBILE, DeviceTypeCheckerTypes.TABLET].indexOf(DeviceTypeChecker('width')) >= 0;
      if (!isNil(el) && !isMobile) {
        top = (el as HTMLElement).getBoundingClientRect().top;
        top = top < 0 ? 0 : top;
      }
      stickyOffset.value = {
        top: top,
        bottom: 0
      };
    }
  };

  // ============== WATCHERS ==============

  onMounted(() => {
    window.addEventListener('resize', setStickyOffset);
    window.addEventListener('scroll', setStickyOffset);
    setStickyOffset();
  });

  return {
    stickyElement,
    stickyOffset,
    setStickyOffset
  };
}
