import { defineComponent, computed } from 'vue';
import ecIconNameList from '@ecl/preset-ec/dist/images/icons/lists/all.json';
import ecIconsSprite from '@ecl/preset-ec/dist/images/icons/sprites/icons.svg';
import euIconNameList from '@ecl/preset-eu/dist/images/icons/lists/all.json';
import euIconsSprite from '@ecl/preset-eu/dist/images/icons/sprites/icons.svg';
import { concat } from 'lodash';
import useEcl from './../AppCurrentInstance';
export { ecIconNameList, euIconNameList };

const allIconName = concat(ecIconNameList, euIconNameList);

export enum EclIconSize {
  'XS2' = '2xs',
  'XS' = 'xs',
  'S' = 's',
  'M' = 'm',
  'L' = 'l',
  'XL' = 'xl',
  'XL2' = '2xl',
  'Fluid' = 'fluid',
}

export enum EclIconColor {
  None = '',
  Default = 'default',
  Inverted = 'inverted',
  Primary = 'primary',
}

export const EclIconRotate = [0, 45, 90, 135, 180, 225, 270, 315];

export default defineComponent({
  name: 'EclIcon',
  props: {
    classes: {
      required: false,
      type: Array as () => Array<string>,
    },
    icon: {
      required: true,
      type: String,
      validator: (value: string) => {
        return allIconName.includes(value);
      },
    },
    rotate: {
      required: false,
      // type: Number,
      validator: (value: number) => EclIconRotate.includes(value),
    },
    size: {
      default: EclIconSize.S,
      required: false,
      type: String,
      validator: (size: string) => Object.values<string>(EclIconSize).includes(size),
    },
    color: {
      required: false,
      validator: (color: string) => Object.values<string>(EclIconColor).includes(color),
    },
  },
  setup(props) {
    const theme = useEcl().theme;

    // import(`./EclIcon.component.${useEcl().theme}.scss`);

    return {
      iconName: computed(() => {
        if (theme === 'eu') {
          return euIconsSprite + '#' + props.icon;
        }
        return ecIconsSprite + '#' + props.icon;
      }),
      svgClass: computed(() => {
        const className: string[] = props.classes ? [...props.classes] : [];
        if (props.size && props.size !== '') {
          className.push(`ecl-icon--${props.size}`);
        }
        if (props.color && props.color !== '') {
          className.push(`ecl-icon--${props.color}`);
        }
        if (props.rotate && props.rotate !== '') {
          className.push(`ecl-icon--rotate-${props.rotate}`);
        }
        className.unshift('ecl-icon');
        return className.join(' ');
      }),
    };
  },
});
