import { SiteModuleStore } from './types';
import { MutationTree } from 'vuex';

export const mutations: MutationTree<SiteModuleStore> = {
  i18nLoading(state, payload: SiteModuleStore['i18nLoading']): void {
    state.i18nLoading = payload;
  },
  loading(state, payload: SiteModuleStore['loading']): void {
    state.loading = payload;
  },
  name(state, payload: SiteModuleStore['name']): void {
    state.name = payload;
  },
  contentOwnerDetails(state, payload: SiteModuleStore['contentOwnerDetails']): void {
    state.contentOwnerDetails = payload;
  },
  mainNav(state, payload: SiteModuleStore['mainNav']): void {
    state.mainNav = payload;
  },
  title(state, payload: SiteModuleStore['title']): void {
    state.title = payload;
  },
  metaInfo(state, payload: SiteModuleStore['metaInfo']): void {
    state.metaInfo = payload;
  },
  description(state, payload: SiteModuleStore['description']): void {
    state.description = payload;
  },
  breadcrumb(state, payload: SiteModuleStore['breadcrumb']): void {
    state.breadcrumb = payload;
  },
  // contextNav(state, payload: SiteModuleStore['contextNav']): void {
  //   const _default: SiteModuleStore['contextNav'] = {
  //     enabled: false,
  //     id: null,
  //     type: '',
  //     name: '',
  //     itemList: []
  //   };
  //   state.contextNav = merge(_default, payload);
  // },
  // pageConfig(state, config: { name: string; data: SitePageConfigResponse }) {
  //   const name: string = config.name;
  //   console.log('@Mutation > pageConfig - name:', name);
  //   if (name) {
  //     console.log('@Mutation > pageConfig - config:', config.data);
  //     state.pagesConfig[name] = config.data;

  //     console.log('@Mutation > pagesConfig:', state.pagesConfig);
  //   }
  // },
  contextListAvailable(state, payload: SiteModuleStore['contextListAvailable']): void {
    state.contextListAvailable = payload;
  }
};
