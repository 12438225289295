import { computed } from 'vue';

import { LocaleManager } from '@/managers';

import { store } from '@/store';

export default function useMainNavigation() {
  // ================ DATA ================

  // ============ DEPENDENCIES ============

  // ============== COMPUTED ==============
  const menuPrimary = computed(() => store.state?.site?.mainNav?.items ?? []);

  // =============== METHODS ==============
  const performSearch = (value: string): void => {
    window.open(`https://ec.europa.eu/search?QueryText=${value}&op=Search&swlang=${LocaleManager.locale.value}`);
  };
  // ============== WATCHERS ==============

  return {
    menuPrimary,
    performSearch
  };
}
