import { defineComponent, onMounted, ComputedRef, unref, watch } from 'vue';
import { useRouter } from 'vue-router';

// @ts-ignore
import Menu from '@ecl/vanilla-component-menu/menu.js';

import {
  AppLink,
  EclLink,
  EclLinkType,
  EclIconSize,
  EclIcon,
  EclButton,
  EclButtonIconPosition,
  EclButtonType,
} from '../..';
import useNavItem from './composables/useNavItem';
import useActions from './composables/useActions';
import useData from './composables/useData';
// import useEcl from '../../AppCurrentInstance';

export type MenuItem = {
  label: string;
  to: string | ComputedRef<string> | null;
  target?: string;
  current?: boolean;
  children?: MenuItem[];
};
export type MenuItems = {
  [index: number]: MenuItem;
};

export default defineComponent({
  name: 'EclMenu',
  emits: ['open', 'close'],
  components: {
    AppLink,
    EclLink,
    EclIcon,
    EclButton,
  },
  props: {
    items: {
      type: Array as () => Array<MenuItem>,
      required: true,
      default: null,
    },
    attachSwipeListener: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    // import(`./EclMenu.component.${useEcl().theme}.scss`);

    let menuJS: Menu = null;
    const router = useRouter();
    const data = useData();
    const navItems = useNavItem(props, {
      router: router,
    });
    const actions = useActions({
      useData: data,
      router: router,
    });

    onMounted(() => {
      menuJS = Menu.autoInit(unref(data.eclMenuRoot), { MENU: { attachSwipeListener: props.attachSwipeListener } });
      watch(
        props.items,
        _i => {
          if (_i.length > 0) {
            // Inizializza il JS per il Menu delle ECL
            menuJS = Menu.autoInit(unref(data.eclMenuRoot), {
              MENU: { attachSwipeListener: props.attachSwipeListener },
            });
          }
        },
        { immediate: true },
      );
    });

    // Chiude il menu al cambio path (external JS)
    router.afterEach(() => {
      menuJS.handleClickOnClose();
      menuJS.inner.setAttribute('aria-hidden', 'false');
      setTimeout(() => emit('close'), 200);
    });

    const toggleMenu = () => {
      console.log('menuJS open', menuJS.isOpen, JSON.stringify(menuJS.isOpen));
      if (menuJS.isOpen) {
        setTimeout(() => emit('close'), 200);
      } else {
        emit('open');
      }
    };

    /**
     * Verifica se il menu è espanso o chiuso
     *
     * @param {number} indexRef - indice dell'DOM Element
     */
    const isExpanded = (indexRef: number): boolean => {
      const element = unref(data.navItemsElWrapper)[indexRef];
      if (element) {
        return element.getAttribute('aria-expanded') === 'true';
      }
      return false;
    };

    return {
      ...data,
      ...navItems,
      ...actions,
      toggleMenu,
      isExpanded,
      EclLinkType,
      EclButtonIconPosition,
      EclButtonType,
      EclIconSize,
    };
  },
});
