import { computed, unref } from 'vue';
import { useI18n } from 'vue-i18n';
import { AccordionData } from '../../';
import useData from './useData';

export default function useAccordion(dependencies: { useData: ReturnType<typeof useData> }) {
  // ================ DATA ================
  const accordion: AccordionData = dependencies.useData.accordion;
  const index: number = dependencies.useData.index;

  // ============ DEPENDENCIES ============

  // ============== COMPUTED ==============
  const isExpanded = computed((): boolean => {
    return accordion.itemActive.value.indexOf(index) >= 0;
  });

  const statusLabel = computed((): string => {
    // translation function
    const t = useI18n().t;
    return unref(isExpanded) ? t('ECLV3.GENERAL.CLOSE') : t('ECLV3.GENERAL.OPEN');
  });

  // =============== METHODS ==============
  const activate = (): void => {
    if (!isExpanded.value) {
      accordion.activate(index);
    }
  };

  const deactivate = (): void => {
    if (isExpanded.value) accordion.deactivate(index);
  };

  const toggleActivation = (): void => {
    isExpanded.value ? deactivate() : activate();
  };

  // ============== WATCHERS ==============

  return {
    isExpanded,
    statusLabel,
    toggleActivation,
  };
}
