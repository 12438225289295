import { defineComponent, ref, computed, toRefs, unref } from 'vue';
import { isNil, each, concat, uniqueId } from 'lodash';

import { EclIcon, EclIconSize } from '@/shared/ecl/src/components';
import { ListItem } from './../types';
import useKeyboard from './composables/useKeyboard';

export default defineComponent({
  name: 'Tree',
  emits: ['selectItem'],
  components: {
    EclIcon
  },
  props: {
    item: Object,
    valueKey: {
      type: String,
      required: false,
      default: 'value'
    },
    labelKey: {
      type: String,
      required: false,
      default: 'label'
    },
    childrenKey: {
      type: String,
      required: false,
      default: 'children'
    },
    value: {
      type: [String, Number],
      required: false,
      default: ''
    },
    openIfChildActive: {
      type: Boolean,
      required: false,
      default: true
    },
    count: {
      type: Number,
      required: false,
      default: -1
    },
    inputType: {
      type: String,
      required: false,
      default: 'radio' // checkbox or radio
    },
    forceOpen: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props, { emit }) {
    const { item, value, openIfChildActive, labelKey, valueKey, childrenKey, count, forceOpen } = toRefs(props);
    const internalCount = count.value + 1;
    const isOpen = ref(unref(forceOpen) ?? false);

    const _item = item.value;

    const id = `_item[valueKey.value]_${internalCount}_${uniqueId()}`;
    const label = _item[labelKey.value];
    const inputValue = _item[valueKey.value];

    const isMain: boolean = isNil(label) || isNil(inputValue);

    const isSelected = computed(
      (): boolean => !!inputValue && !!unref(value) && inputValue.toString() === unref(value).toString()
    );

    const listChildren = computed((): ListItem[] => {
      return _item[childrenKey.value] ? _item[childrenKey.value] : null;
    });

    const countChildren = computed((): number => {
      return listChildren.value ? listChildren.value.length : 0;
    });

    const hasChildren = computed((): boolean => {
      return countChildren.value > 0;
    });

    const iconRotation = computed((): number => {
      return isOpen.value === true ? 180 : 90;
    });

    // verifica se ha un children attivo
    if (openIfChildActive.value === true && hasChildren.value === true && isSelected.value === false) {
      const flattenc = (list: ListItem[]) => {
        let _r = [];
        each(list, (item: ListItem) => {
          if (item.children) {
            const f = flattenc(item.children);
            _r = concat(_r, f);
          }
          _r.push(item.value.toString());
        });
        return _r;
      };

      const flattenValue = flattenc(listChildren.value);
      if (!isNil(unref(value)) && flattenValue.indexOf(unref(value).toString()) >= 0) {
        isOpen.value = true;
      }
    }

    const toggle = (): void => {
      isOpen.value = !isOpen.value;
    };

    const open = (): void => {
      isOpen.value = true;
    };

    const close = (): void => {
      isOpen.value = false;
    };

    const blur = (): void => {
      isOpen.value = false;
    };

    const handleClickItem = (propItem: ListItem): void => {
      emit('selectItem', propItem);
    };

    const keyboard = useKeyboard({
      extendedList: item,
      close: close,
      toggle: toggle,
      handleClickItem: handleClickItem
    });

    return {
      internalCount,
      isMain,
      ...keyboard,
      id,
      label,
      inputValue,
      isSelected,
      listChildren,
      iconRotation,
      isOpen,
      hasChildren,
      toggle,
      open,
      close,
      blur,
      handleClickItem,
      EclIconSize
    };
  }
});
