import { defineComponent } from 'vue';
import { each, isNil } from 'lodash';

export default defineComponent({
  inheritAttrs: false,
  name: 'EclCol',
  props: {
    class: {
      type: String,
      required: false,
    },
    col: {
      type: [Number, String],
      required: false,
    },
    s: {
      type: [Number, String],
      required: false,
    },
    m: {
      type: [Number, String],
      required: false,
    },
    l: {
      type: [Number, String],
      required: false,
    },
    xl: {
      type: [Number, String],
      required: false,
    },
    offset: {
      type: [Number, String],
      required: false,
    },
    offsetS: {
      type: [Number, String],
      required: false,
    },
    offsetM: {
      type: [Number, String],
      required: false,
    },
    offsetL: {
      type: [Number, String],
      required: false,
    },
    offsetXl: {
      type: [Number, String],
      required: false,
    },
  },
  setup(props) {
    const createClassSize = () => {
      const classes: string[] = [];

      // Generate column classes
      each(['col', 's', 'm', 'l', 'xl'], (propName: keyof typeof props) => {
        const columnSize = props[propName];
        if (!isNil(columnSize)) {
          const split = propName.toLowerCase().split('col');
          const name = split?.length > 1 ? split[1] : `-${propName}`;
          classes.push(`ecl-col${name}-${columnSize}`);
        }

        if (classes.length === 0) {
          classes.push('ecl-col-fluid');
        }
      });

      // Generate offset classes
      each(['offset', 'offsetS', 'offsetM', 'offsetL', 'offsetXl'], (propName: keyof typeof props) => {
        const offsetSize = props[propName];
        if (!isNil(offsetSize)) {
          const split = propName.toLowerCase().split('offset');
          const name = split?.length > 1 ? split[1] : `-${propName}`;
          classes.push(`ecl-offset-${name}-${offsetSize}`);
        }
      });

      return `${classes.join(' ')} ${props.class ?? ''}`.trim();
    };

    return {
      classes: createClassSize(),
    };
  },
});
