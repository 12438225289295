import {
  computed,
  ComputedRef,
  onMounted,
  Ref,
  onUnmounted,
  createApp,
  ComponentPublicInstance,
  nextTick,
  App,
  unref,
  WatchStopHandle,
  getCurrentInstance
} from 'vue';

import { MapAttribution, MapSettings } from '../types';
import { isNil, map, each } from 'lodash';
import { EclModal } from '@/shared/ecl/src/components';
import ECL from '@/shared/ecl/src';
import i18n from '@/locale';

type ModalItem = {
  element: HTMLElement;
  app: App<Element>;
  instance: ComponentPublicInstance<typeof EclModal>;
};

export default function useAttributions(dependencies) {
  // ================ DATA ================

  // ============ DEPENDENCIES ============
  const mapComponent: Ref<HTMLElement> = dependencies.mapComponent;
  const settings: ComputedRef<MapSettings> = dependencies.settings;
  const modals: ModalItem[] = [];
  const watches: WatchStopHandle[] = [];
  const currentApp = getCurrentInstance();

  // ============== COMPUTED ==============
  const attributionsContent = computed((): string[] => {
    const attributions = settings.value.attributions;
    return map(attributions, (attribution: MapAttribution) => {
      let html = attribution.content;
      if (attribution.disclaimer?.create) {
        html += ` ${attribution.disclaimer.separator} `;
        const title = attribution.disclaimer.title.replace(/"/g, '&quot;') || '';
        const content = attribution.disclaimer.content.replace(/"/g, '&quot;') || '';
        html += `<a class="perform-disclaimer" href="javascript:void(0)" data-disclaimer-title="${title}" data-disclaimer-content="${content}">${attribution.disclaimer.label}</a>`;
      }
      return html;
    });
  });

  // =============== METHODS ==============
  const generateModal = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (!isNil(target)) {
      const modalElement = document.createElement('div');
      modalElement.className = `modal-wrapper modal-wrapper--${modals.length}`;
      document.body.appendChild(modalElement);

      const disclaimerTitle = target.getAttribute('data-disclaimer-title');
      const disclaimerContent = target.getAttribute('data-disclaimer-content');

      const modalApp = createApp(EclModal, { modelValue: true, title: disclaimerTitle, body: disclaimerContent });
      modalApp.use(ECL, currentApp.appContext?.config?.globalProperties?.$ecl ?? {});
      modalApp.use(i18n);

      nextTick(() => {
        const instanceMounted = modalApp.mount(modalElement) as ComponentPublicInstance<typeof EclModal>;
        modals.push({ element: modalElement, app: modalApp, instance: instanceMounted });
      });
    }
  };

  // ============== WATCHERS ==============
  onMounted(() => {
    nextTick(() => {
      const map = unref(mapComponent);
      if (!isNil(map)) {
        map.addEventListener('click', (event: MouseEvent) => {
          const classes = (event.target as HTMLElement).classList;
          if (classes && classes.contains('perform-disclaimer')) {
            generateModal(event);
          }
        });
      }
    });
  });

  onUnmounted(() => {
    each(watches, watch => watch());
    each(modals, (modal: ModalItem) => {
      // modal.app.unmount();
      modal.element.remove();
    });
  });

  return {
    attributionsContent
  };
}
