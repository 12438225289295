import { defineComponent, computed, PropType, unref, toRefs } from 'vue';
import { baseClassName, logos } from '../../../helpers/helpers';
import { EclLink, EclLinkType, EclLinkIconPosition, EclIconSize, FooterNavItem } from '../../';
import { LocaleManager } from '@/managers';
import useEcl from './../../AppCurrentInstance';
import { isNil } from 'lodash';

export type SiteContentColumns = { title: string; items: { url: string; label: string } }[];

export default defineComponent({
  name: 'EclFooterHarmonised',
  components: {
    EclLink,
  },
  props: {
    siteName: {
      type: Object as PropType<FooterNavItem>,
      required: true,
    },
    contentOwnerDetail: {
      type: String,
      required: true,
    },
    showLogo: {
      type: Boolean,
      default: true,
      required: false,
    },
    overrides: {
      type: Object,
      required: false,
    },
    siteContentColumns: {
      type: Array as () => SiteContentColumns,
      required: false,
    },
  },
  setup(props) {
    const { overrides } = toRefs(props);
    const baseClass = 'ecl-footer-harmonised';

    const currentLocale = computed(() => LocaleManager.getCurrentCode());
    const isEc = computed(() => useEcl().theme === 'ec');
    console.log('>>>FOOTER');
    console.log(unref(currentLocale));

    const logoImage = computed(() => {
      return logos.get(unref(currentLocale), unref(isEc) ? 'negative' : 'positive');
    });

    const accessibilityLink = computed(() => {
      const accessibility = unref(overrides)?.linkList?.accessibility;

      if (!isNil(accessibility)) {
        return accessibility;
      }

      // return unref(isEc)
      //   ? `https://commission.europa.eu/accessibility-statement_${unref(currentLocale)}`
      //   : `https://european-union.europa.eu/accessibility-statement_${unref(currentLocale)}`;
      return undefined;
    });

    const identity = computed(() => {
      return {
        title: unref(isEc) ? 'ECLV3.FOOTER.EC.IDENTITY' : 'ECLV3.FOOTER.EU.IDENTITY',
        url: unref(isEc)
          ? `https://ec.europa.eu/info/index_${unref(currentLocale)}`
          : `https://european-union.europa.eu/index_${unref(currentLocale)}`,
        logo: logos.get(unref(currentLocale), unref(isEc) ? 'negative' : 'positive'),
        accessibility: {
          label: unref(isEc) ? 'ECLV3.FOOTER.EC.LEGAL.ACCESSIBILITY' : 'ECLV3.FOOTER.EU.LEGAL.ACCESSIBILITY',
          link: unref(accessibilityLink)
        }
      };
    });

    const cookieLink = computed(() => {
      const cookies = unref(overrides)?.linkList?.cookies;
      if (!isNil(cookies)) {
        return cookies;
      }

      return unref(isEc)
        ? `https://ec.europa.eu/info/cookies_${unref(currentLocale)}`
        : `https://european-union.europa.eu/cookies_${unref(currentLocale)}`;
    });

    return {
      identity,
      cookieLink,
      baseClass,
      isEc,
      elementClassName: (suffix: string): string => {
        return baseClassName(baseClass, null, suffix);
      },
      currentLocale,
      EclIconSize,
      EclLinkType,
      EclLinkIconPosition,
      logoImage,
      accessibilityLink
    };
  },
});
