import { defineComponent, inject, computed, ComputedRef, watch, ref, onMounted, unref, reactive, toRefs, toRef } from 'vue';
import { isNil, each } from 'lodash';
import { store } from '@/store';
import { LocaleManager } from '@/managers';
import EclIcon from '@/shared/ecl/src/components/EclIcon/EclIcon.component.vue';
import EclButton from '@/shared/ecl/src/components/EclButton/EclButton.component.vue';
import i18n from '@/locale';
import { decodeString } from '../../helpers/string/mutate';
import caseStudiesImages from '@/_static/casestudies_images.json';
import Modal from '@/components/Modal/Modal.component.vue';

export default defineComponent({
  name: 'FactSheet',
  props: {
    id: {
      type: String,
      required: true
    },
  },
  directives: {
  },
  components: {
    EclIcon,
    EclButton,
    Modal
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  },
  setup(props) {
    const LFPTEXT = ref('');
    const LFPTITLE = ref('');
    const LPFSUBTITLE = ref('');
    const TYPE = ref('');
    const category = ref('');
    const bb1 = ref('');
    const bb2 = ref('');
    // const keyw = ref([]);
    const caseStudyTypology = ref([]);
    const row: any = ref({});
    const imageFile = ref('');

    const classColors = ref([]);
    classColors.value = ['underline-textcolor1', 'underline-textcolor2', 'underline-textcolor3'];

    const modalKey = ref('');
    modalKey.value = decodeString(i18n.global.t('modal.key'));
    const inspireCountry = ref('');
    inspireCountry.value = decodeString(i18n.global.t('inspire.country'));
    const inspireLocation = ref('');
    inspireLocation.value = decodeString(i18n.global.t('inspire.location'));
    const inspireOrg = ref('');
    inspireOrg.value = decodeString(i18n.global.t('inspire.org'));
    const inspireBudget = ref('');
    inspireBudget.value = decodeString(i18n.global.t('inspire.budget'));
    const inspireFunds = ref('');
    inspireFunds.value = decodeString(i18n.global.t('inspire.funds'));
    const inspireTimeframe = ref('');
    inspireTimeframe.value = decodeString(i18n.global.t('inspire.timeframe'));
    const inspireDblink = ref('');
    inspireDblink.value = decodeString(i18n.global.t('inspire.dblink'));
    const inspireKeyw = ref('');
    inspireKeyw.value = decodeString(i18n.global.t('inspire.keyw'));
    const inspireProjectDes = ref('');
    inspireProjectDes.value = decodeString(i18n.global.t('inspire.projectDes'));
    const inspirePillars = ref('');
    inspirePillars.value = decodeString(i18n.global.t('inspire.pillars'));
    const projectWebsite = ref('');
    projectWebsite.value = decodeString(i18n.global.t('inspire.projectWebsite'));
    const multiFunds = ref('');
    multiFunds.value = decodeString(i18n.global.t('inspire.multifunds'));
    const caseStudy = ref('');
    caseStudy.value = decodeString(i18n.global.t('inspire.caseStudy'));
    const tips = ref('');
    tips.value = decodeString(i18n.global.t('inspire.tips'));

    const DATABASE_LINK = ref('');
    const PROJECT_WEBSITE = ref([]);
    const PILLAR_LTVRA = ref([]);
    const FUND_KEY = ref([]);
    const KEY_WORDS = ref([]);

    const listLtvra = ref([]);
    listLtvra.value = store.getters['tool/listPillar'];

    const listKeyw = ref([]);
    listKeyw.value = store.getters['tool/listKeyw'];

    watch(() => store.getters['tool/openInspire'], function() {
      const ID = store.getters['tool/clickInspireId'];
      // console.log(ID);
      // row.value = caseStudies.find(element => element.FICHE_ID === ID);
      row.value = store.getters['tool/inspired'].find(element => element.FICHE_ID === ID);
      imageFile.value = caseStudiesImages.find(element => element.FICHE_ID === ID).IMAGE_FILE;

      KEY_WORDS.value = [];
      if (!isNil(row.value.KEY_WORDS_CODE)) {
        row.value.KEY_WORDS_CODE.toString().replaceAll('.', ',').split(',').forEach(c => {
          const des = listKeyw.value.find(element => element.CODE.toString().trim() === c.toString().trim());
          KEY_WORDS.value.push(des.DES);
        });
      }
      // keyw.value = isNil(row.value.KEY_WORDS) ? [] : row.value.KEY_WORDS.split(';');
      caseStudyTypology.value = isNil(row.value.CASE_STUDY_TYPOLOGY) ? [] : row.value.CASE_STUDY_TYPOLOGY.split(';');

      if (!isNil(row.value.DATABASE_LINK)) {
        const link = decodeString(i18n.global.t(isNil(row.value.DATABASE_LINK_DES) ? 'Database link' : row.value.DATABASE_LINK_DES));
        DATABASE_LINK.value = '<a target="_blank" href="' + row.value.DATABASE_LINK + '">' + link + '</a>';
      }
      const projectWebsite = ref('');
      projectWebsite.value = decodeString(i18n.global.t('inspire.projectWebsite'));

      if (!isNil(row.value.PROJECT_WEBSITE)) {
        PROJECT_WEBSITE.value = [];
        const pwd = isNil(row.value.PROJECT_WEBSITE_DES) ? [projectWebsite.value, projectWebsite.value] : row.value.PROJECT_WEBSITE_DES.split(';');
        let n = 0;
        row.value.PROJECT_WEBSITE.split(';').forEach(c => {
          const link = decodeString(i18n.global.t(isNil(pwd[n]) ? projectWebsite.value : pwd[n]));
          c = c.indexOf('http') === -1 ? 'http://' + c : c;
          n += 1;

          const alink = '<a target="_blank" href="' + c + '">' + link + '</a>';
          PROJECT_WEBSITE.value.push(alink);
        });
      }

      if (!isNil(row.value.PILLAR_LTVRA_CODE)) {
        PILLAR_LTVRA.value = [];
        row.value.PILLAR_LTVRA_CODE.split(',').forEach(c => {
          let k = '';
          k = c;

          const des = listLtvra.value.find(element => element.id === c.trim());
          PILLAR_LTVRA.value.push({ img: 'ltvra_' + c.trim() + '_icon.svg', des: des.value });
        });
      }

      const listFund = ref([]);
      listFund.value = store.getters['tool/listFund'];

      if (!isNil(row.value.FUND_KEY)) {
        FUND_KEY.value = [];
        row.value.FUND_KEY.toString().split(',').forEach(c => {
          if (!isNil(c) && c !== '') {
            const f = listFund.value.find(element => element.id === Number(c).toString().trim());
            FUND_KEY.value.push([f.id, f.value, f.value2]);
          }
        });
      }

      document.getElementById('topDiv').scrollIntoView({ behavior: 'smooth' });
    });

    const handleClickFiche = (e, color): void => {
      console.log(e);
      console.log(color);
      store.commit('tool/clickFoId', String(e));
      store.commit('tool/clickFo', !store.getters['tool/clickFo']);
      store.commit('tool/clickFoColor', String(color));
    };

    return {
      handleClickFiche,
      LFPTEXT,
      LFPTITLE,
      LPFSUBTITLE,
      category,
      TYPE,
      row,
      imageFile,
      bb1,
      bb2,
      // keyw,
      modalKey,
      inspireCountry,
      inspireLocation,
      inspireOrg,
      inspireBudget,
      inspireFunds,
      inspireTimeframe,
      inspireDblink,
      inspireKeyw,
      inspireProjectDes,
      inspirePillars,
      projectWebsite,
      DATABASE_LINK,
      listLtvra,
      PILLAR_LTVRA,
      PROJECT_WEBSITE,
      FUND_KEY,
      classColors,
      KEY_WORDS,
      caseStudyTypology,
      multiFunds,
      caseStudy,
      tips
    };
  }

});
