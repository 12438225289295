import { computed, ComputedRef, Ref, ref, toRefs, unref, watch, onMounted, nextTick } from 'vue';
import { each, flattenDeep, head, reduce, map } from 'lodash';

import { ListItem } from '../types';

type UseStylesDependencies = {
  extendedList: ComputedRef<ListItem[][]>;
  extendedTreeList: ComputedRef<{ children: ListItem[] }>;
  autocompleteBlur: Ref<HTMLElement>[];
};

export default function useStyles(props, dependencies: UseStylesDependencies) {
  const { tree, calculateWidth, hiddenPlaceholder } = toRefs(props);

  // ================ DATA ================
  const optionWidth: Ref<number> = ref(0);

  // ============ DEPENDENCIES ============
  const extendedList = dependencies.extendedList;
  const extendedTreeList = dependencies.extendedTreeList;
  const autocompleteBlur = dependencies.autocompleteBlur;

  // ============== COMPUTED ==============

  const calculatedStyles = computed((): string | null => {
    if (unref(optionWidth)) {
      return `width: ${unref(optionWidth)}px`;
    }
    return null;
  });

  // const internalDropdownWidth: ComputedRef<string> = computed((): string => {
  //   const option = unref(dropdownWidth);
  //   if (typeof option !== 'string') {
  //     return `${option}px`;
  //   }
  //   return option;
  // });

  // =============== METHODS ==============

  const performStyleCalculation = () => {
    const _elements = unref(autocompleteBlur);
    if (_elements) {
      const _main = unref(head(_elements));
      const _placeholder = head(_main?.getElementsByClassName('jcl-autocomplete__placeholder')) as HTMLElement;
      if (_placeholder) {
        const fakeElement = _placeholder.cloneNode(true) as HTMLElement;
        fakeElement.style.display = 'block';
        fakeElement.style.position = 'relative';
        fakeElement.style.width = 'auto';
        // _main.appendChild(fakeElement);

        const fakeItemToRemove = [];
        each(
          map(flattenDeep(unref(extendedList)), item => item.label),
          item => {
            const _f = fakeElement.cloneNode(true) as HTMLElement;
            _f.lastElementChild.innerHTML = `<span>${item}</span>`;
            fakeItemToRemove.push(_f);
            _main.appendChild(_f);
          }
        );

        let newWidthValue = 0;
        nextTick(() => {
          each(fakeItemToRemove, item => {
            const calc = item.getBoundingClientRect().width + 4;
            newWidthValue = calc > newWidthValue ? calc : newWidthValue;
            item.remove();
          });
          optionWidth.value = newWidthValue;
        });
      }
    }
  };

  onMounted(() => {
    if (unref(calculateWidth) && unref(hiddenPlaceholder) === false) {
      performStyleCalculation();
    }
  });

  const treeReducer = (list: { children: ListItem[] } | ListItem): ListItem[] => {
    return reduce(
      list?.children,
      (result, item) => {
        result.push(item);
        if (item?.children) {
          result.push(...treeReducer(item));
        }
        return result;
      },
      []
    );
  };

  watch(
    [extendedList, extendedTreeList],
    () => {
      if (unref(calculateWidth)) {
        const currentList = unref(tree) ? treeReducer(unref(extendedTreeList)) : flattenDeep(unref(extendedList));
        if (currentList?.length > 0) {
          if (unref(calculateWidth) && unref(hiddenPlaceholder) === false) {
            performStyleCalculation();
          }
        }
      }
    },
    {
      immediate: true
    }
  );

  return {
    calculatedStyles
  };
}
