import axios, { AxiosInstance } from 'axios';
import { cacheAdapterEnhancer, throttleAdapterEnhancer } from 'axios-extensions';

export default function useService() {
  // ================ DATA ================
  const service: AxiosInstance = axios.create({
    baseURL: '',
    timeout: 10000,
    headers: {
      'Cache-Control': 'no-cache',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    },
    adapter: throttleAdapterEnhancer(cacheAdapterEnhancer(axios.defaults.adapter))
  });

  // ============ DEPENDENCIES ============

  // ============== COMPUTED ==============

  // =============== METHODS ==============

  // ============== WATCHERS ==============

  return {
    service
  };
}
