import { isNil } from 'lodash';

export default class JclHighchartsLoader {
  private tries = 0;
  public totalTries = 5;
  public _DEBUG = false;

  public async load(extra: string[] = []): Promise<boolean> {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const $wt = (window as any).$wt;
      // Carica Highcharts
      const loadHighcharts = () => {
        if (this._DEBUG) console.log('@JclHighchartsLoader - loadHighcharts');
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const $wt = (window as any).$wt;
        if (this._DEBUG) console.log('@JclHighchartsLoader - $wt:', $wt);
        $wt.load(
          [
            'https://europa.eu/webtools/webtools.charts.js?version=2.0&theme=eu&core=latest&plugins=highcharts-default-highcharts-stock,highcharts-boost,highcharts-more,highcharts-dumbbell,highcharts-more,highcharts-exporting,highcharts-offline-exporting,highcharts-export-data,highcharts-data,highcharts-accessibility,highcharts-init:formatted',
            ...extra
          ],
          () => {
            if (this._DEBUG) console.log('@JclHighchartsLoader - loadHighcharts > loaded:');
            this.init(resolve, reject);
          }
        );
        /* $wt.load(['https://europa.eu/webtools/libs/highcharts/highstock.php', ...extra], () => {
          if (this._DEBUG) console.log('@JclHighchartsLoader - loadHighcharts > loaded:');
          this.init(resolve, reject);
        }); */
      };
      if (isNil($wt)) {
        if (this._DEBUG) console.log('@JclHighchartsLoader - UEC NON esiste!');
        // Se non esiste UEC (Unified Embed Code)
        const uec = document.createElement('script');
        uec.setAttribute('src', '//europa.eu/webtools/load.js');
        uec.onload = () => {
          // carica Highcharts
          loadHighcharts();
        };
        document.head.appendChild(uec);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const Highcharts = (window as any).Highcharts;
        if (this._DEBUG) console.log('@JclHighchartsLoader - window:', window);
        if (isNil(Highcharts)) {
          if (this._DEBUG) console.log('@JclHighchartsLoader - Highcharts NON esiste!');
          // Esiste UEC (Unified Embed Code) ma deve caricare Highcharts
          loadHighcharts();
        } else {
          if (this._DEBUG) console.log('@JclHighchartsLoader - Highcharts e UEC esistono!');
          // Esiste sia UEC (Unified Embed Code) che Highcharts
          this.init(resolve, reject);
        }
      }
    });
  }

  /**
   * Reale inizializzazione del componenete e di Highcharts
   */
  private init(resolve, reject) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const Highcharts = (window as any).Highcharts;

    if (Highcharts) {
      resolve(true);
      // emit('loaded');
    } else {
      if (this._DEBUG) console.log('@JclHighchartsLoader - tries:', this.tries);
      if (this.tries <= this.totalTries) {
        setTimeout(() => {
          if (this._DEBUG) console.log('@JclHighchartsLoader - timeout');

          this.load().then(
            () => resolve(),
            e => {
              if (this._DEBUG) console.error('Highcharts loading error', e);
              reject(false);
            }
          );
        }, 250 * this.tries);
        this.tries++;
      } else {
        reject(false);
        if (this._DEBUG) console.warn('Highcharts has not been imported');
      }
    }
  }
}
