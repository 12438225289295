import { defineComponent } from 'vue';
import { EclIcon, EclIconSize } from '../../';
import useAccordion from './composables/useAccordion';
import useData from './composables/useData';
import { makeUUID } from '../../../helpers/random';

export default defineComponent({
  name: 'EclAccordionItem',
  components: {
    EclIcon,
  },
  setup() {
    const id = makeUUID();
    const data = useData();
    const item = useAccordion({
      useData: data,
    });

    return {
      ...item,
      id,
      EclIconSize,
    };
  },
});
