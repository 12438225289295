import { GetterTree } from 'vuex';
import { MetaModuleStore, MetaList } from './types';
import { RootState } from '@/store/types';

export const getters: GetterTree<MetaModuleStore, RootState> = {
  title(state): string {
    return state.meta.title;
  },
  list(state): MetaList {
    return state.meta.list;
  }
};
