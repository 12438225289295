import { toRefs, ref, Ref, unref, SetupContext } from 'vue';
import { isArray } from 'lodash';
import { BaseDependencies, EclSelect, ModelValueData, EclSelectOptionExtended } from '../';
import useSelect from './useSelect';

// export type UseValue = {
//   internalValue: Ref<string[] | object>;
//   externalValue: Ref<string | number | object | string[] | undefined>;
//   checkboxSelectAll: Ref<{
//     checked: boolean;
//     id: string;
//   }>;
//   checkboxDeselectAll: Ref<{
//     checked: boolean;
//     id: string;
//   }>;
// };

export default function useValue(
  props: InstanceType<typeof EclSelect>['$props'],
  context: SetupContext,
  dependencies: BaseDependencies & {
    useSelect: ReturnType<typeof useSelect>;
  },
) {
  const { modelValue } = toRefs(props);

  // ============ DEPENDENCIES ============

  const UUID = dependencies.UUID;
  const modeSingle = dependencies.useSelect.modeSingle;

  // ================ DATA ================

  // definisce la value di partenza in base alla tipologia della select (singola o multipla)
  const makeDefault = (): undefined | ModelValueData | ModelValueData[] => {
    if (unref(modelValue)) {
      return unref(modeSingle) === false && !isArray(unref(modelValue))
        ? (unref(modelValue) as ModelValueData)
        : (unref(modelValue) as ModelValueData[]);
    }
    return unref(modeSingle) === false ? undefined : [];
  };

  // gestione oggetti delle options
  const internalValue: Ref<undefined | EclSelectOptionExtended | EclSelectOptionExtended[]> = ref();

  // gestione select native
  const checkboxSelectAll = ref({
    checked: false,
    id: `optchbx_${UUID}_all`,
  });

  // gestione select native
  const checkboxDeselectAll = ref({
    checked: false,
    id: `optchbx_${UUID}_deselect_all`,
  });

  // ============== COMPUTED ==============

  const externalValue = (context.expose !== undefined ? modelValue : ref(makeDefault())) as Ref<
    undefined | ModelValueData | ModelValueData[]
  >;

  return {
    internalValue,
    externalValue,
    checkboxSelectAll,
    checkboxDeselectAll,
  };
}
