import { App } from 'vue';
import { merge } from 'lodash';

import { EclContainer, EclRow, EclColumn, EclHeading, EclParagraph } from './components';

export type EclOption = {
  theme: string;
};

const ECL = {
  install(app: App, options?: EclOption) {
    const finalOptions = merge({ theme: 'ec' }, options);
    // inject a globally available ecl-theme variable
    app.config.globalProperties.$ecl = {
      theme: finalOptions.theme,
    };

    import(`./components/themes/${finalOptions.theme}-theme.scss`);

    // configure the app
    app.component('EclContainer', EclContainer);
    app.component('EclRow', EclRow);
    app.component('EclColumn', EclColumn);
    app.component('EclH', EclHeading);
    app.component('EclP', EclParagraph);
  },
};

declare module 'vue' {
  interface ComponentCustomProperties {
    $ecl: {
      theme: string;
    };
  }
}

export { ECL };
export default ECL;
