import { defineComponent, h } from 'vue';

export default defineComponent({
  name: 'EclHeading',
  props: {
    level: {
      type: [String, Number],
      required: true,
    },
    class: {
      type: String,
      required: false,
    },
  },
  render() {
    if (typeof this.$slots.default === 'function') {
      return h(
        'h' + this.level,
        {
          class: this.classes(),
        },
        this.$slots.default(),
      );
    } else {
      return h('h' + this.level, {
        class: this.classes(),
        domProps: this.$attrs.innerHTML,
      });
    }
  },
  methods: {
    classes() {
      return ['ecl-u-type-heading-' + this.level, this.class].join(' ');
    },
  },
});
