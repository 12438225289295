import { toRefs, ref, SetupContext, Ref } from 'vue';
import { CheckboxItem, EclCheckbox } from '../';

export default function useValue(props: InstanceType<typeof EclCheckbox>['$props'], context: SetupContext) {
  const { value, modelValue } = toRefs(props);

  // ================ DATA ================

  const internalValue: Ref<CheckboxItem[]> = ref([]);

  // ============== COMPUTED ==============

  const externalValue = context.expose !== undefined ? modelValue : value;

  return {
    internalValue,
    externalValue,
  };
}
