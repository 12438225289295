import { defineComponent } from 'vue';
import { RouterLink, useRouter } from 'vue-router';
import { find, isNil } from 'lodash';

import useExternalLink from './composables/useExternalLink';

export default defineComponent({
  name: 'AppLink',
  props: {
    // @ts-ignore
    ...RouterLink.props,
    external: {
      type: Boolean,
      required: false,
      default: null,
    },
    target: {
      type: String,
      required: false,
      default: null, // self, blank
    },
    autoCheckExternal: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props) {
    const externalLink = useExternalLink(props);

    const foundRouteTo = (name: string): string | null => {
      const founded = find(useRouter().getRoutes(), r => r.name === name);
      return !isNil(founded) ? useRouter().resolve({ name: name }).href : props.to;
    };

    return {
      ...externalLink,
      finalUrl: foundRouteTo(props.to),
    };
  },
});
