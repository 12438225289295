import { computed, ComputedRef, toRefs, unref } from 'vue';
import { isArray, isEmpty, isNil } from 'lodash';
import { EclSelect } from '../';
import useValue from './useValue';
import useOptions from './useOptions';
import useSelect from './useSelect';

export default function useVisibility(
  props: InstanceType<typeof EclSelect>['$props'],
  dependencies: {
    useSelect: ReturnType<typeof useSelect>;
    useValue: ReturnType<typeof useValue>;
    useOptions: ReturnType<typeof useOptions>;
  },
) {
  // ================ DATA ================
  const { selectAll, deselectAll, limit } = toRefs(props);

  // ============ DEPENDENCIES ============
  const internalValue = dependencies.useValue.internalValue;
  const extendedOptions = dependencies.useOptions.extendedOptions;
  const isMultiple = dependencies.useSelect.isMultiple;

  // ============== COMPUTED ==============

  const hasSelected: ComputedRef<boolean> = computed((): boolean => {
    const val = unref(internalValue);
    if (isArray(val)) {
      return (val as []).length > 0;
    }
    return !isEmpty(val);
  });

  const allSelected: ComputedRef<boolean> = computed((): boolean => {
    const val = unref(internalValue);
    if (isArray(val)) {
      const selectedCount = (val as []).length;
      const optionsCount = unref(extendedOptions)?.length || 0;
      const _limit = unref(limit);
      const totalCount = !isNil(_limit) && _limit !== 0 && optionsCount > _limit ? unref(limit) : optionsCount;
      return selectedCount === totalCount;
    }
    return !isEmpty(val);
  });

  const showSelectAll: ComputedRef<boolean> = computed(() => {
    if (unref(allSelected)) {
      return false;
    }
    return unref(selectAll) ?? false;
  });

  const showDeselectAll: ComputedRef<boolean> = computed(() => {
    // se ne ha almeno uno selezionato
    if (unref(showSelectAll) === false) {
      return unref(deselectAll) ?? false;
    }
    return false;
  });

  const showMultipleToolbar: ComputedRef<boolean> = computed(() => {
    return unref(isMultiple) && unref(hasSelected);
  });

  // =============== METHODS ==============

  // ============== WATCHERS ==============

  return {
    showDeselectAll,
    showSelectAll,
    showMultipleToolbar,
  };
}
