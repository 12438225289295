import store from '@/store';
import { provide, computed } from 'vue';
import { breadcrumbConfigs } from '@/configs';
import { EclBreadcrumbItem } from '@/shared/ecl/src/components';

const defaultBreadcrumb: EclBreadcrumbItem[] = breadcrumbConfigs?.default || [];

class BreadcrumbManager {
  private static _instance: BreadcrumbManager = new BreadcrumbManager();

  constructor() {
    if (BreadcrumbManager._instance) {
      throw new Error('Error: Instantiation failed: Use BreadcrumbManager.getInstance() instead of new.');
    }
    BreadcrumbManager._instance = this;

    this.default();
  }

  /**
   * Retrieve the class instance
   */
  public static getInstance(): BreadcrumbManager {
    return BreadcrumbManager._instance;
  }

  /**
   * Set the default breadcrumb
   */
  public default(): void {
    store.commit('site/breadcrumb', defaultBreadcrumb);
  }

  /**
   * Set the default breadcrumb
   */
  public addMultiple(itemList: EclBreadcrumbItem[]): void {
    store.commit('site/breadcrumb', defaultBreadcrumb.concat(itemList));
  }

  /**
   * Set the default breadcrumb
   */
  public addSingle(item: EclBreadcrumbItem): void {
    store.commit('site/breadcrumb', defaultBreadcrumb.concat([item]));
  }

  /**
   * Provide data to children
   */
  public provide() {
    return provide(
      'breadcrumb',
      computed(() => store.getters['site/breadcrumb'])
    );
  }
}

export default BreadcrumbManager.getInstance();
