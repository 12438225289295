import { Ref, ComputedRef, computed, toRefs, unref } from 'vue';
import { isNil, map, uniq } from 'lodash';
import { ListItem } from '../types';
import { useI18n } from 'vue-i18n';

export default function usePlaceholder(props, dependencies) {
  const { t } = useI18n();
  const { hiddenPlaceholder, multiplePlaceholderLimit } = toRefs(props);
  // ================ DATA ================

  // ============ DEPENDENCIES ============
  const focused: Ref<boolean> = dependencies.focused;
  const internalSelectedObject: Ref<ListItem> = dependencies.internalSelectedObject;

  // ============== COMPUTED ==============
  const placeholderText: ComputedRef<string> = computed((): string => {
    const _iso = unref(internalSelectedObject);
    if (_iso instanceof Array) {
      // Se è maggiore di "multipleGroupLimit" imposta il raggruppamento
      if (_iso.length > multiplePlaceholderLimit.value && multiplePlaceholderLimit.value !== -1) {
        return t('ecl.form.select.group_label', [_iso.length]);
      }
      return uniq(map(_iso, i => i.label)).join(', ');
    }
    return !isNil(_iso) && _iso.label;
  });

  const placeholderItemSelection = computed(() => {
    const _iso = unref(internalSelectedObject);
    if (_iso) {
      if (_iso instanceof Array && _iso?.length > 0) {
        return map(_iso, i => i._original);
      } else {
        return _iso?._original ?? null;
      }
    }
    return null;
  });

  const placeholderShow = computed((): boolean => {
    return hiddenPlaceholder.value === false && focused.value === false && !isNil(unref(placeholderItemSelection));
  });

  // =============== METHODS ==============

  // ============== WATCHERS ==============

  return {
    placeholderShow,
    placeholderText,
    placeholderItemSelection
  };
}
