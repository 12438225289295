import { toDisplayString as _toDisplayString, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, resolveComponent as _resolveComponent, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "app-page" }
const _hoisted_2 = { class: "app-page__content" }

export function render(_ctx, _cache) {
  const _component_ecl_site_header_harmonised = _resolveComponent("ecl-site-header-harmonised")
  const _component_ecl_page_header_harmonised = _resolveComponent("ecl-page-header-harmonised")
  const _component_router_view = _resolveComponent("router-view")
  const _component_ecl_footer_harmonised = _resolveComponent("ecl-footer-harmonised")

  return (_openBlock(), _createBlock(_Fragment, null, [
    (_openBlock(), _createBlock(_Teleport, { to: "head" }, [
      _createVNode("title", null, _toDisplayString(_ctx.title), 1),
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.list, (metaItem, i) => {
        return (_openBlock(), _createBlock(_Fragment, { key: i }, [
          (metaItem.name)
            ? (_openBlock(), _createBlock("meta", {
                key: 0,
                name: metaItem.name,
                content: metaItem.content
              }, null, 8, ["name", "content"]))
            : (metaItem.property)
              ? (_openBlock(), _createBlock("meta", {
                  key: 1,
                  property: metaItem.property,
                  content: metaItem.content
                }, null, 8, ["property", "content"]))
              : _createCommentVNode("", true)
        ], 64))
      }), 128)),
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.languages, (language, i) => {
        return (_openBlock(), _createBlock("link", {
          key: i,
          rel: "alternate",
          hreflang: language.code,
          href: language.url
        }, null, 8, ["hreflang", "href"]))
      }), 128))
    ])),
    _withDirectives(_createVNode("main", _hoisted_1, [
      _createVNode(_component_ecl_site_header_harmonised, {
        ref: "refHeader",
        className: _ctx.className,
        menuItems: _ctx.menuPrimary,
        siteName: "Rural toolkit",
        showLanguage: true,
        onPerformSearch: _ctx.performSearch
      }, null, 8, ["className", "menuItems", "onPerformSearch"]),
      _createVNode(_component_ecl_page_header_harmonised, {
        metaInfo: _ctx.metaInfo,
        description: _ctx.description,
        breadcrumbList: _ctx.breadcrumbList,
        breadcrumbEllipsisLimit: 0
      }, null, 8, ["metaInfo", "description", "breadcrumbList"]),
      _createVNode("div", _hoisted_2, [
        _createVNode(_component_router_view)
      ]),
      _createVNode(_component_ecl_footer_harmonised, {
        siteName: { label: 'Rural toolkit', url: _ctx.baseUrl},
        contentOwnerDetail: _ctx.contentOwnerDetails,
        siteContentColumns: _ctx.footerSiteContentColumns,
        overrides: _ctx.footerOverrides
      }, null, 8, ["siteName", "contentOwnerDetail", "siteContentColumns", "overrides"])
    ], 512), [
      [_vShow, _ctx.i18nLoading === false]
    ])
  ], 64))
}