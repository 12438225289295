import { logoEc } from './logosEc';
import { logoEu } from './logosEu';

console.log(process.env.VUE_APP_ECL_THEME);
console.log(process.env.VUE_APP_ECL_THEME === 'ec');

const logos = process.env.VUE_APP_ECL_THEME === 'ec' ? logoEc : logoEu;

export { logos };
export default logos;
