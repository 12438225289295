import { defineComponent, reactive, ref, Ref, watch, computed, unref } from 'vue';
import { each, forEach, isNil } from 'lodash';
// import useMap from './composables/useMap';

import { EclModal } from '@/shared/ecl/src/components/EclModal';
import { EclAccordion, EclAccordionItem } from '@/shared/ecl/src/components/EclAccordion';
import { EclLink } from '@/shared/ecl/src/components/Navigation/EclLink';
import { EclButton, EclButtonType } from '@/shared/ecl/src/components';
import EclIcon from '@/shared/ecl/src/components/EclIcon/EclIcon.component.vue';
import { useRoute } from 'vue-router';
import { SEOManager, BreadcrumbManager, LocaleManager } from '@/managers';
import SectionJSON from '@/_static/sections.json';
import ParagraphMoreLess from '@/components/ParagraphMoreLess/ParagraphMoreLess.component.vue';
import i18n from '@/locale';
import { decodeString } from '../../helpers/string/mutate';
import store from '@/store';
import { manageTooltip } from '../App/composables/useGlossary';

export default defineComponent({
  name: 'Home',
  components: {
    EclButton,
    EclAccordion,
    EclAccordionItem,
    EclModal,
    EclLink,
    EclIcon,
    ParagraphMoreLess
  },
  setup() {
    SEOManager.setTitles(process.env.VUE_APP_PROJECT_NAME);

    // BreadcrumbManager.default();
    // const route = useRoute();
    // const sections: Sections = JSON.parse(route.params.sections as string);
    const sections = SectionJSON;

    const lang = !isNil(LocaleManager) && !isNil(LocaleManager.locale) && !isNil(unref(LocaleManager.locale))
      ? unref(LocaleManager.locale)
      : 'en';
    // const lang = 'en';
    // console.log('>>SISTEMARE lang');
    const loading: Ref<boolean> = ref(false);

    const homeDes = ref('');
    const homeDesF = ref('');
    const homeFinder = ref('');
    const homeInspire = ref('');
    const homeResources = ref('');
    const homeNote = ref('');

    homeDes.value = decodeString(i18n.global.t('home.description'));
    homeNote.value = decodeString(i18n.global.t('home.note'));
    homeFinder.value = decodeString(i18n.global.t('home.finder'));
    homeInspire.value = decodeString(i18n.global.t('home.inspire'));
    homeDesF.value = decodeString(i18n.global.t('home.descriptionFooter'));

    homeResources.value = manageTooltip(decodeString(i18n.global.t('home.resources')))[0];
    const buttonDiscover = ref('');
    buttonDiscover.value = decodeString(i18n.global.t('button.discover'));
    const buttonMoreAbout = ref('');
    buttonMoreAbout.value = decodeString(i18n.global.t('button.moreabout'));
    const buttonWebpage = ref('');
    buttonWebpage.value = decodeString(i18n.global.t('button.webpage'));
    const buttonInspire = ref('');
    buttonInspire.value = decodeString(i18n.global.t('button.inspire'));
    const buttonResources = ref('');
    buttonResources.value = decodeString(i18n.global.t('button.resources'));
    const buttonGo = ref('');
    buttonGo.value = decodeString(i18n.global.t('button.go'));
    const buttonLTV = ref('');
    buttonLTV.value = decodeString(i18n.global.t('home.buttonLTV'));
    const buttonRO = ref('');
    buttonRO.value = decodeString(i18n.global.t('home.buttonRO'));
    const buttonTA = ref('');
    buttonTA.value = 'Territorial Agenda 2030'; // decodeString(i18n.global.t('home.buttonTA'));
    const buttonRP = ref('');
    buttonRP.value = decodeString(i18n.global.t('home.buttonRP'));
    const homeWhatIs = ref('');
    homeWhatIs.value = decodeString(i18n.global.t('home.whatIs'));

    function setSpecificFrame(): void {
      const video = document.getElementById('videoRT') as HTMLVideoElement;
      video.currentTime = 10;
    }

    return {
      loading,
      sections,
      homeDes,
      homeDesF,
      homeNote,
      homeFinder,
      homeInspire,
      homeResources,
      buttonDiscover,
      buttonMoreAbout,
      buttonWebpage,
      buttonInspire,
      buttonResources,
      buttonGo,
      buttonLTV,
      buttonRO,
      buttonRP,
      buttonTA,
      homeWhatIs,
      // ...map,
      EclButtonType,
      lang,
      setSpecificFrame
    };
    // return await promise;
  }
});
