import { defineComponent, ref } from 'vue';
import { isNil, isNumber } from 'lodash';
import { store } from '@/store';
// import { SEOManager } from '@/managers';
import { EclAccordion, EclAccordionItem } from '@/shared/ecl/src/components/EclAccordion';
import { EclLink } from '@/shared/ecl/src/components/Navigation/EclLink';
import { EclButton, EclButtonType } from '@/shared/ecl/src/components';
import EclIcon from '@/shared/ecl/src/components/EclIcon/EclIcon.component.vue';
import ParagraphMoreLess from '@/components/ParagraphMoreLess/ParagraphMoreLess.component.vue';
import i18n from '@/locale';
import { decodeString } from '../../helpers/string/mutate';
import { manageTooltip } from '../../views/App/composables/useGlossary';

export default defineComponent({
  name: 'About',
  props: {
  },
  directives: {
  },
  components: {
    EclButton,
    EclAccordion,
    EclAccordionItem,
    EclLink,
    EclIcon,
    ParagraphMoreLess
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  },
  setup(props) {
    // SEOManager.setTitles('About');
    const listGlossary = ref([]);
    listGlossary.value = store.getters['tool/listGlossary'];

    /* function getTextBetweenCharacters(inputString, startChar, endChar) {
      const startIndex = inputString.indexOf(startChar);
      const endIndex = inputString.indexOf(endChar, startIndex + 1);

      if (startIndex === -1 || endIndex === -1 || endIndex <= startIndex) {
        return '';
      }

      return inputString.substring(startIndex + 1, endIndex);
    }

    const manageTooltip = (s): string => {
      if (isNumber(s)) {
        return s.toString();
      }

      if (isNil(s)) {
        return s;
      }
      console.log('>>>MANAGE TOOLTIP');

      const patternT = /\(([^)]*?)\)/g;
      const matchT = s.match(patternT);
      // v-tooltip="{ content: 'Print' }"
      if (!isNil(matchT)) {
        for (const n in matchT) {
          console.log(matchT[n]);
          console.log('>>>>>');
          const code = getTextBetweenCharacters(matchT[n], '*', ')');
          console.log(code.trim());
          const des = listGlossary.value.find(x => x.id === code.trim()).value;
          console.log(listGlossary.value.find(x => x.id === code.trim()).value);

          console.log(' * ' + code);
          const orig = matchT[n].replace('(', '').replace(')', '').replace(' * ' + code.trim(), '');

          s = s.replace(matchT[n], ' <a class="tooltip"> <span class="highlighted-text">' + orig + '</span><span class="tooltip-text">' + des + '</span></a>');
        }
        return s;
      } else {
        return s;
      }
    }; */

    const aboutWhy = ref('');
    aboutWhy.value = decodeString(i18n.global.t('about.why'));
    const aboutWhyText = ref('');
    aboutWhyText.value = decodeString(i18n.global.t('about.whyText'));
    aboutWhyText.value = manageTooltip(aboutWhyText.value)[0];

    const aboutHow = ref('');
    aboutHow.value = decodeString(i18n.global.t('about.how'));
    const aboutHowText = ref('');
    aboutHowText.value = manageTooltip(decodeString(i18n.global.t('about.howText')))[0];

    const aboutWhat = ref('');
    aboutWhat.value = decodeString(i18n.global.t('about.what'));
    const aboutWhatText = ref('');
    aboutWhatText.value = decodeString(i18n.global.t('about.whatText'));

    const aboutWhatDirect = ref('');
    aboutWhatDirect.value = decodeString(i18n.global.t('about.whatDirect'));
    const aboutWhatDirectText = ref('');
    aboutWhatDirectText.value = decodeString(i18n.global.t('about.whatDirectText'));

    const aboutWhatIndirect = ref('');
    aboutWhatIndirect.value = decodeString(i18n.global.t('about.whatIndirect'));
    const aboutWhatIndirectText = ref('');
    aboutWhatIndirectText.value = manageTooltip(decodeString(i18n.global.t('about.whatIndirectText')))[0];

    const aboutWhatFinancial = ref('');
    aboutWhatFinancial.value = manageTooltip(decodeString(i18n.global.t('about.whatFinancial')))[0];
    const aboutWhatFinancialText = ref('');
    aboutWhatFinancialText.value = manageTooltip(decodeString(i18n.global.t('about.whatFinancialText')))[0];

    return {
      EclButtonType,
      aboutWhy,
      aboutWhyText,
      aboutHow,
      aboutHowText,
      aboutWhat,
      aboutWhatText,
      aboutWhatDirect,
      aboutWhatDirectText,
      aboutWhatIndirect,
      aboutWhatIndirectText,
      aboutWhatFinancial,
      aboutWhatFinancialText,
    };
  }

});
