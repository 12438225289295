import { DefineComponent, Ref, ref, toRefs, watch, SetupContext } from 'vue';
import { EclModal } from '..';

export default function useData(props: InstanceType<typeof EclModal>['$props'], context: SetupContext) {
  const { modelValue } = toRefs(props);

  // ================ DATA ================
  const elementClose: Ref<DefineComponent | undefined> = ref();
  const internalValue: Ref<boolean> = ref(modelValue.value);
  const externalValue: Ref<boolean> = modelValue;

  // ============ DEPENDENCIES ============

  // ============== COMPUTED ==============

  // =============== METHODS ==============

  // ============== WATCHERS ==============
  watch(externalValue, (value: boolean) => {
    if (internalValue.value !== value) internalValue.value = value;
  });

  watch(internalValue, (value: boolean) => {
    if (externalValue.value !== value) context.emit('update:modelValue', value);
  });

  return {
    elementClose,
    visible: internalValue,
    externalValue,
  };
}
