import { SetupContext, toRefs, unref } from 'vue';
import { isNil, isArray, map } from 'lodash';
import { EclSelect, ModelValueData, EclSelectOptionExtended } from '../';
import useValue from './useValue';

export default function useData(
  props: InstanceType<typeof EclSelect>['$props'],
  context: SetupContext,
  dependencies: { useValue: ReturnType<typeof useValue> },
) {
  const { valueProp, mode } = toRefs(props);

  // ============ DEPENDENCIES ============

  const internalValue = dependencies.useValue.internalValue;
  // const externalValue = dependencies.externalValue;

  // =============== METHODS ==============

  const makeExternal = (
    val: EclSelectOptionExtended | EclSelectOptionExtended[] | null,
  ): ModelValueData | ModelValueData[] | null => {
    // No need to transform if empty value
    if (isNil(val)) {
      return null;
    }

    // If external should be plain transform
    // value object to plain values
    const valueKey = unref(valueProp) as keyof EclSelectOptionExtended;
    return isArray(val) ? (map(val, v => v[valueKey]) as ModelValueData[]) : (val[valueKey] as ModelValueData);
  };

  const makeInternal = (
    val: EclSelectOptionExtended | EclSelectOptionExtended[] | null,
  ): undefined | EclSelectOptionExtended | EclSelectOptionExtended[] => {
    if (isNil(val)) {
      return unref(mode) === 'single' ? undefined : [];
    }

    return val;
  };

  const update = (val: EclSelectOptionExtended | EclSelectOptionExtended[] | null): void => {
    // Setting object(s) as internal value
    internalValue.value = makeInternal(val);

    // Setting object(s) or plain value as external
    // value based on `option` setting
    const externalVal = makeExternal(val);
    // externalValue.value = externalVal;

    context.emit('change', externalVal);
    context.emit('input', externalVal);
    context.emit('update:modelValue', externalVal);
  };

  return {
    update,
  };
}
