import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';

export enum EclLoaderSize {
  XS = 'xs',
  S = 's',
  M = 'm',
  L = 'l',
  XL = 'xl',
}

export default defineComponent({
  name: 'EclLoader',
  props: {
    size: {
      type: String,
      required: false,
      default: EclLoaderSize.M,
      validator: (size: string) => Object.values<string>(EclLoaderSize).includes(size),
    },
    panel: {
      type: Boolean,
      required: false,
      default: false,
    },
    fixed: {
      type: Boolean,
      required: false,
      default: false,
    },
    opacity: {
      type: Number,
      required: false,
      default: 0.8,
    },
    background: {
      type: String,
      required: false,
      default: '#ffffff',
    },
    showLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: () => {
        const { t } = useI18n();
        return t('ECLV3.LOADER.LABEL');
      },
    },
  },
  setup(props) {
    let styles = '';
    const loaderLabel = ref(props.label);
    if (props.panel) {
      styles += `background-color: ${props.background};`;
      styles += `opacity: ${props.opacity};`;
    }
    return {
      loaderLabel,
      styles: styles,
      classes: '' + props.size,
    };
  },
});
