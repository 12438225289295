import { unref } from 'vue';
import { isString, isNil } from 'lodash';
import { MenuItemExtended } from './useNavItem';
import { checkExternalUrl, getTarget } from '../../../../helpers/link';
import useData from './useData';
import { Router } from 'vue-router';

export default function useActions(dependencies: { useData: ReturnType<typeof useData>; router: Router }) {
  // ================ DATA ================

  // ============ DEPENDENCIES ============

  const eclMenuInner = dependencies.useData.eclMenuInner;
  const navItemsElWrapper = dependencies.useData.navItemsElWrapper;
  const router = dependencies.router;

  // ============== COMPUTED ==============

  // =============== METHODS ==============

  /**
   * Effettua la navigazione al menu cliccato
   *
   * @param {number} indexRef - indice dell'DOM Element
   * @param {MenuItem} item - oggetto dell'item del menu
   */
  const navigateTo = (item: MenuItemExtended, indexRef: number | null = null): void => {
    let hasSublink = false;
    let openedChildPanel = false;
    if (!isNil(indexRef)) {
      const element = navItemsElWrapper.value[indexRef];
      hasSublink = element.classList.contains('ecl-menu__item--has-children');
      openedChildPanel = element.getAttribute('aria-expanded') === 'true';
    }
    const preventItemWithChild = unref(eclMenuInner)?.getAttribute('aria-hidden') === 'false';

    const to = unref(item.to);
    if (to) {
      if (preventItemWithChild === false || hasSublink === false || (openedChildPanel && hasSublink)) {
        if (isString(to) && checkExternalUrl(to.toString())) {
          window.open(to, getTarget(item.target));
        } else {
          // effettua il cambio di navigazione solo se il menu non ha "child" oppure se è espanso
          router.push(to);
        }
      }
    }
  };

  // ============== WATCHERS ==============
  return {
    navigateTo,
  };
}
