/* eslint-disable @typescript-eslint/camelcase */

import { ComputedRef, ref, Ref, watch, unref } from 'vue';
import { isNil, merge, isArray, each, assign, last, head, find, isString } from 'lodash';

import { MapSettings, TooltipData } from '../types';

import Map from 'ol/Map';
import TileLayer from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';
import { Feature, MapBrowserEvent } from 'ol';
import { ResponseGetFeatureInfo } from '../types/ResponseGetFeatureInfo';
import { WMSLayer, WMSOptions } from './../types/MapSettings';
import GeoJSON from 'ol/format/GeoJSON';
import Geometry from 'ol/geom/Geometry';

export default function useWms(dependencies, context) {
  // const { level } = toRefs(props);

  // ================ DATA ================
  const layers: Ref<TileLayer[]> = ref([]);

  // ============ DEPENDENCIES ============
  const settings: ComputedRef<MapSettings> = dependencies.settings;
  const mapInstance: Ref<Map> = dependencies.mapInstance;
  const tooltipData: TooltipData = dependencies.tooltipData;
  const map: Map = mapInstance.value;

  // ============== COMPUTED ==============

  // =============== METHODS ==============
  const destroy = () => {
    each(unref(layers), l => map.removeLayer(l));
    layers.value = [];
  };

  const convertParams = (options: Record<string, string | number | boolean> | WMSOptions) => {
    const response = {};
    each(options, (value: string | string[], key: string) => {
      // if (!isNil(value)) {
      // if (typeof value !== 'string' || (isString(value) && !isEmpty(value))) {
      response[key.toUpperCase()] = isArray(value) ? (value as string[]).join(',') : value;
      // }
      // }
    });
    return response;
  };

  /**
   * Merge the default options with the options in params
   *
   * @param {WMSOptions} options - source options
   * @response {WMSOptions} - options merged
   */
  const getWMSParams = (options: WMSOptions): WMSOptions => {
    const mergedOptions = assign({}, settings.value.WMS.defaultOptions, options);
    const responseOptions: WMSOptions = convertParams(mergedOptions);
    return responseOptions;
  };

  const addLayerOnMap = (layer: TileLayer): void => {
    map.addLayer(layer);
  };

  const removeLayerOnMap = (layer: TileLayer): void => {
    map.removeLayer(layer);
  };

  /**
   * Genera il layer
   *
   * @returns {TileLayer}
   */
  const makeLayer = (layerOptions: WMSLayer): TileLayer => {
    const WMSTile = new TileWMS({
      url: settings.value.WMS.api.basePath + settings.value.WMS.api.wms,
      params: getWMSParams(layerOptions.options),
      serverType: 'geoserver'
      // crossOrigin: 'anonymous'
    });
    const WMSTileLayer = new TileLayer({
      source: WMSTile
    });
    return WMSTileLayer;
  };

  /**
   * Aggiorna i parametri del layer
   *
   * @param {number} index - posizione del layer
   * @param {WMSLayer} layerOptions - nuove impostazioni del layer
   */
  const updateLayer = (index: number, layerOptions: WMSLayer): void => {
    const layer: TileWMS = unref(layers)[index].getSource() as TileWMS;
    const params = getWMSParams(assign({}, layerOptions.options));

    layer.set('name', layerOptions.name || '');
    layer.set('extraParamsAPI', layerOptions.extraParamsAPI || {});
    layer.updateParams(params);
  };

  /**
   * Genera e aggiunge tutti i WMS layers
   */
  const makeLayers = (): void => {
    // destroy();
    const _optsLayers = !isNil(settings.value.WMS.layers) ? settings.value.WMS.layers : [];
    if (_optsLayers.length > 0) {
      // Rimuove i layer che sono di troppo
      // il numero di layer su mappa sarà lo stesso di quelli passati da settings
      const itemToRemove = unref(layers).length - _optsLayers.length;

      if (itemToRemove > 0) {
        const layersToRemove = unref(layers).slice(itemToRemove * -1);
        each(layersToRemove, l => {
          removeLayerOnMap(l);
          unref(layers).pop();
        });
      }

      // Crea, aggiorna o distrugge i layer
      each(_optsLayers, (layerOptions: WMSLayer, index: number) => {
        if (index > unref(layers).length - 1) {
          const layer = makeLayer(layerOptions);
          layer.set('name', layerOptions.name || '');
          layer.set('extraParamsAPI', layerOptions.extraParamsAPI || {});
          unref(layers).push(layer);
          addLayerOnMap(layer);
        } else {
          updateLayer(index, layerOptions);
        }
      });
    } else {
      destroy();
    }

    context.emit('wmsUpdatedLayers', unref(layers));
  };

  /**
   * Restituisce il Layer da utilizzare
   *
   * @returns {TileLayer}
   */
  // const getLayer = (): TileLayer => {
  //   const _wms = unref(settings.value).WMS;
  //   const _layers = unref(layers);
  //   const _defaultLayer = head(_layers);
  //   if (!isNil(_wms.useLayer)) {
  //     if (isString(_wms.useLayer)) {
  //       if (_wms.useLayer === 'first') {
  //         return _defaultLayer;
  //       } else if (_wms.useLayer === 'last') {
  //         return last(_layers);
  //       } else {
  //         const foundedLayer = find(_layers, (l: TileLayer) => {
  //           return l.get('name') === _wms.useLayer;
  //         });
  //         return foundedLayer || _defaultLayer;
  //       }
  //     } else {
  //       if (_wms.useLayer <= _layers.length - 1) {
  //         return layers[_wms.useLayer];
  //       }
  //     }
  //   }
  //   return _defaultLayer;
  // };

  const getLayerOptions = (): WMSLayer => {
    const _wms = unref(settings.value).WMS;
    const _layers = unref(settings.value.WMS.layers);
    const _defaultLayer = head(_layers);
    if (!isNil(_wms.useLayer)) {
      if (isString(_wms.useLayer)) {
        if (_wms.useLayer === 'first') {
          return _defaultLayer;
        } else if (_wms.useLayer === 'last') {
          return last(_layers);
        } else {
          const foundedLayer = find(_layers, (l: WMSLayer) => {
            return l.name === _wms.useLayer;
          });
          return foundedLayer || _defaultLayer;
        }
      } else {
        if (_wms.useLayer <= _layers.length - 1) {
          return _layers[_wms.useLayer];
        }
      }
    }
    return _defaultLayer;
  };

  /**
   * Restituisce la Tile WMS per il Layer attivo
   *
   * @returns {TileWMS}
   */
  // const getActiveWms = (): TileWMS => {
  //   return !isNil(getLayer()) ? (getLayer().getSource() as TileWMS) : null;
  // };

  const readGeoServerResponse = (data): Feature<Geometry>[] => {
    const _r = new GeoJSON().readFeatures(data);
    return _r;
  };

  /**
   * WMS Geoserver Request
   *
   * @param {string} url - the url
   */
  const WMSRequest = (url: string): Promise<ResponseGetFeatureInfo> => {
    return new Promise((resolve, reject) => {
      if (!isNil(url) && url !== '') {
        fetch(url)
          .then(
            response => response.json(),
            error => {
              const errorMsg = `Error to fetch url: ${url}. Error: ${error}`;
              reject(errorMsg);
            }
          )
          .then(response => resolve(response));
      } else {
        const error = `Error on url: ${url}`;
        reject(error);
      }
    });
  };

  /**
   * Click event on map
   */
  map.on('singleclick', (event: MapBrowserEvent): void => {
    const viewResolution: number = map.getView().getResolution();

    // Genero un layer da utilizzare solo per la richiesta,
    // così è possibile impostare/sovrascrivere tutti i valori necessari
    const layerOptions = getLayerOptions();
    if (!isNil(layerOptions)) {
      const opts = merge({}, layerOptions, { options: layerOptions.extraParamsAPI || {} });
      const wmsTile = makeLayer(opts);
      const params = assign({
        REQUEST: 'GetFeatureInfo'
      });

      const url = (wmsTile.getSource() as TileWMS).getFeatureInfoUrl(
        event.coordinate,
        viewResolution,
        settings.value.WFS.crs,
        params
      );

      WMSRequest(url).then(response => {
        context.emit('clickedTerritory', { response, event });
      });
    }
  });

  /**
   * MouseEvent (move) on map
   */
  map.on('pointermove', (event: MapBrowserEvent): void => {
    if (event.dragging || settings.value.WMS.identifyOnOver === false) {
      return;
    }

    const originalEvent = event.originalEvent as PointerEvent;
    tooltipData.position = [originalEvent.x, originalEvent.y];

    window.clearTimeout(tooltipData.throttle);
    tooltipData.throttle = null;

    if (!tooltipData.throttle) {
      tooltipData.throttle = window.setTimeout(() => {
        tooltipData.throttle = null;

        const viewResolution: number = map.getView().getResolution();
        // const activeWMS = getActiveWms();
        const layerOptions = getLayerOptions();
        if (!isNil(layerOptions)) {
          const opts = merge({}, layerOptions, { options: layerOptions.extraParamsAPI || {} });
          const wmsTile = makeLayer(opts);
          const params = assign({
            REQUEST: 'GetFeatureInfo'
          });
          const url = (wmsTile.getSource() as TileWMS).getFeatureInfoUrl(
            event.coordinate,
            viewResolution,
            settings.value.WFS.crs,
            params
          );
          // const url = getActiveWms().getFeatureInfoUrl(event.coordinate, viewResolution, settings.value.WFS.crs, {
          //   REQUEST: 'GetFeatureInfo'
          // });

          WMSRequest(url).then(response => {
            context.emit('hoverTerritory', { response, event });
            if (!isNil(response.features) && response.features.length > 0) {
              tooltipData.text = settings.value.WMS.tooltip.formatting(response);
              tooltipData.dataObject = response;
            } else {
              tooltipData.text = null;
              tooltipData.dataObject = null;
            }
          });

          // if (url) {
          //   fetch(url)
          //     .then((response: Response) => {
          //       return response.text();
          //     })
          //     .then((data: string) => {
          //       const wmsResponse: ResponseGetFeatureInfo = JSON.parse(data);
          //       if (!isNil(wmsResponse.features) && wmsResponse.features.length > 0) {
          //         tooltipData.text = wmsResponse.features[0].properties.name_html;
          //       } else {
          //         tooltipData.text = null;
          //       }
          //     });
          // }
        }
      }, 100);
    }
  });

  // ============== WATCHERS ==============

  watch(
    () => settings.value.WMS.layers,
    (layers: WMSLayer[]) => {
      context.emit('wmsUpdatingLayers', layers);
      makeLayers();
    },
    { deep: true }
  );

  // ================ INIT ================

  makeLayers();

  return {
    readGeoServerResponse,
    destroy,
    layers
  };
}
