import { nextTick } from 'vue';
import { createI18n } from 'vue-i18n';
import { LocaleManager } from '@/managers';
import LocaleClient from '@/services/api/LocaleClient';
import { makeApolloClient } from '@/services/clients/ApolloClient';
import { provideApolloClient } from '@vue/apollo-composable';
import store from '@/store';

const i18n = createI18n({
  globalInjection: true,
  legacy: false,
  warnHtmlMessage: false,
  missingWarn: false,
  fallbackWarn: false
});

const t = (key: string, ...args) => {
  if (!key) {
    return '';
  }
  return i18n.global.t(key, args);
};

export async function loadLocales() {
  if (LocaleManager.getLanguages().value.length === 0) {
    return new Promise((resolve, reject) => {
      const providedClient = provideApolloClient(makeApolloClient('private'));
      const { onResult, onError } = providedClient(LocaleClient.languageList);
      onResult(response => {
        LocaleManager.setLanguages(response.data.site.languageList);
        resolve(true);
      });
      onError(error => {
        reject(error);
      });
    });
  } else {
    return nextTick();
  }
}

export async function loadLocaleMessages(locale: string) {
  if (!i18n.global.availableLocales.includes(locale)) {
    return new Promise((resolve, reject) => {
      LocaleManager.setLocale(locale);
      const providedClient = provideApolloClient(makeApolloClient('private'));
      const { onResult, onError } = providedClient(() => LocaleClient.languageInterface());
      onResult(response => {
        try {
          console.log('>>>interfaceaaaaaaa');
          LocaleManager.setLocaleMessage(locale, response.data.interface);
          console.log(response.data.interface);
          store.commit('site/i18nLoading', false);
          resolve(true);
        } catch (exc) {
          LocaleManager.removeLanguage(locale);
          reject(exc);
        }
      });
      onError(error => {
        reject(error);
      });
    });
  } else {
    LocaleManager.setLocale(locale);
    return nextTick();
  }
}

export default i18n;
export { t };
