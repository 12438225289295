import { defineComponent, ref, watch, unref, ComputedRef, computed, Ref, SetupContext } from 'vue';
import { isNil } from 'lodash';

import { makeUUID } from '@/shared/ecl/src/helpers/random';

import useAutocomplete from './composables/useAutocomplete';
import useDropdown from './composables/useDropdown';
import useKeyboard from './composables/useKeyboard';
import useValue from './composables/useValue';
import useList from './composables/useList';
import usePlaceholder from './composables/usePlaceholder';

import Tree from './Tree/Tree.component.vue';

import { EclIcon, EclIconSize } from '@/shared/ecl/src/components';
import useStyles from './composables/useStyles';
import { Modifier, OptionsGeneric } from '@popperjs/core';

export type BaseDependencies = {
  UUID: Ref<string>;
};

export default defineComponent({
  name: 'JclAutocomplete',
  components: {
    Tree,
    EclIcon
  },
  emits: ['search', 'searchClear', 'open', 'close', 'selected', 'deselected', 'update:modelValue'],
  props: {
    size: {
      type: String,
      required: false,
      default: 'm' // s, m, l
    },
    tree: {
      type: Boolean,
      required: false,
      default: false
    },
    id: {
      type: String,
      required: false
    },
    list: {
      type: [Array, null],
      required: false
    },
    modelValue: {
      type: [String, Number, Object, Array],
      required: false,
      default: null
    },
    /**
     * Usare solo se il modello necessita di due o più valori
     * Definisce la key da utilizzare per definire le value nel modello (vedi esempio)
     *
     * es:
     *
     * props: {
     *   multipleModelKeyValue: 'itemType'
     * }
     *
     * lista: [
     *  {
     *    itemType: 'TERRITORIAL_SCOPE',
     *    id: 'BORDER',
     *    name: 'Border regions'
     *  },
     *  {
     *    itemType: 'TERRITORIAL_SCOPE',
     *    id: 'ARDECO',
     *    name: 'ARDECO'
     *  },
     *  {
     *    itemType: 'INDICATOR_SCOPE',
     *    id: 'Default',
     *    name: 'EU (Default indicators)'
     *  }
     * ]
     *
     *
     * model: {
     *   TERRITORIAL_SCOPE: null,
     *   INDICATOR_SCOPE: null
     * }
     *
     *
     */
    multipleModelKeyValue: {
      type: String,
      required: false,
      default: null
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },
    // only if multiple is active
    // if value is -1 the limit will be removed
    multiplePlaceholderLimit: {
      required: false,
      type: Number,
      default: 2
    },
    listGrouped: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    valueKey: {
      type: String,
      required: false,
      default: 'value'
    },
    labelKey: {
      type: String,
      required: false,
      default: 'label'
    },
    searchPlaceholder: {
      type: String,
      required: true
    },
    hiddenPlaceholder: {
      type: Boolean,
      required: false,
      default: false
    },
    clearSearchOnSelect: {
      type: Boolean,
      required: false,
      default: true
    },
    theme: {
      type: String,
      required: false,
      default: 'dark' // dark, light
    },
    // Calcola la larghezza della select in base alla option più lunga (conteggio delle lettere)
    calculateWidth: {
      type: Boolean,
      required: false,
      default: false
    },
    // dropdownWidth: {
    //   type: [String, Number],
    //   required: false,
    //   default: '100%'
    // },
    showArrowIcon: {
      type: Boolean,
      required: false,
      default: true
    },
    showClearButton: {
      type: Boolean,
      required: false,
      default: false
    },
    defaultSearchAction: {
      type: Boolean,
      required: false,
      default: true
    },
    treeOnSearchOpen: {
      type: Boolean,
      required: false,
      default: true
    },
    treeForceOpen: Tree.props.forceOpen,
    treeChildrenKey: Tree.props.childrenKey,
    treeOpenIfChildActive: Tree.props.openIfChildActive,
    // Il wrapper che conterrà la select
    // se passato, viene usato per determinare l'altezza massima della dropdown
    contentWrapper: {
      type: HTMLElement,
      required: false,
      default: undefined
    },
    dropdownPopperOptions: {
      type: Object as () => Partial<OptionsGeneric<Modifier<any, any>>>,
      required: false,
      default: {
        placement: 'bottom-start'
      }
    },
    DEBUG: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props, context: SetupContext) {
    const UUID = ref(makeUUID());
    const mainId = !isNil(props.id) ? props.id : `autocomplete__${unref(UUID)}`;
    const value = useValue(props, context);

    const autocomplete = useAutocomplete(props, {
      UUID: UUID
    });

    const dropdown = useDropdown(props, context, {
      skipGenericBlur: autocomplete.skipGenericBlur,
      autocompleteBlur: autocomplete.autocompleteBlur,
      focused: autocomplete.focused,
      searchInputElement: autocomplete.searchInputElement
    });

    const list = useList(props, context, {
      internalValue: value.internalValue,
      internalSelectedObject: value.internalSelectedObject,
      searchValue: value.searchValue,
      update: value.update,
      close: dropdown.close,
      clearInternalSelectedObject: value.clearInternalSelectedObject,
      searchInputElement: autocomplete.searchInputElement,
      popperInstance: dropdown.popperInstance
    });

    watch([() => props.list, list.extendedTreeList, value.forceOpenOnTree], () => {
      UUID.value = makeUUID();
    });

    const placeholder = usePlaceholder(props, {
      focused: autocomplete.focused,
      internalSelectedObject: value.internalSelectedObject,
      internalValue: value.internalValue
    });

    const keyboard = useKeyboard({
      extendedList: list.extendedList,
      close: dropdown.close,
      handleClickItem: list.handleClickItem
    });

    const styles = useStyles(props, {
      extendedList: list.extendedList,
      extendedTreeList: list.extendedTreeList,
      autocompleteBlur: autocomplete.autocompleteBlur
    });

    const iconSize: ComputedRef<string> = computed((): string => {
      if (props.size === 'l') {
        return EclIconSize.M;
      }
      if (props.size === 's') {
        return EclIconSize.XS;
      }
      return EclIconSize.S;
    });

    const handleBlurComponent = () => {
      // dropdown.isOpen.value === true && list.hasList.value === false;
      if (dropdown.focusedOnDropdown.value === false) {
        dropdown.close();
      }
    };

    const performClearSelection = (): void => {
      value.clear();
    };

    const canClearSelection = computed((): boolean => {
      if (props.showClearButton === false) {
        return props.showClearButton;
      }
      const _iv = value.internalValue.value;
      if (props.multiple) {
        return _iv instanceof Array && _iv?.length > 0;
      } else {
        return !isNil(_iv);
      }
    });

    // ============== WATCHERS ==============
    watch(
      () => props.modelValue,
      (newValue: string | number) => {
        value.internalValue.value = newValue;
        if (isNil(newValue)) {
          value.internalSelectedObject.value = null;
        }
      }
    );

    return {
      UUID,
      mainId,
      ...value,
      ...autocomplete,
      ...dropdown,
      ...list,
      ...placeholder,
      ...keyboard,
      ...styles,
      handleBlurComponent,
      performClearSelection,
      canClearSelection,
      iconSize
    };
  }
});
