import { defineComponent, computed } from 'vue';
import { isNil, isEmpty } from 'lodash';
// import useEcl from '../AppCurrentInstance';
import { AppLink, EclIcon } from '../';
import { getTarget } from '../../helpers/link';

export enum EclButtonIconPosition {
  Before = 'before',
  After = 'after',
}

export enum EclButtonType {
  Primary = 'primary',
  Secondary = 'secondary',
  CallToAction = 'call',
  CTA = 'call',
  Text = 'ghost',
  Ghost = 'ghost',
}

export default defineComponent({
  name: 'EclButton',
  components: { EclIcon, AppLink },
  props: {
    isDisabled: {
      required: false,
      default: false,
      type: Boolean,
    },
    text: {
      required: false,
      type: String,
    },
    ariaLabel: {
      required: false,
      type: String,
    },
    type: {
      required: false,
      default: EclButtonType.Primary,
      type: String,
      validator: (type: string) => Object.values<string>(EclButtonType).includes(type),
    },
    class: {
      required: false,
      type: String,
    },
    url: {
      required: false,
      type: String,
      default: null,
    },
    target: {
      required: false,
      type: String,
      default: '_self',
    },
    icon: {
      ...EclIcon.props.icon,
      required: false,
    },
    iconPosition: {
      required: false,
      type: String,
      default: EclButtonIconPosition.After,
      enum: Object.values(EclButtonIconPosition),
    },
    iconSize: EclIcon.props.size,
    iconRotate: EclIcon.props.rotate,
    iconContainerClass: {
      default: '',
      required: false,
      type: String,
    },
  },
  setup(props) {
    // import(`./EclButton.component.${useEcl().theme}.scss`);

    const className = computed(() => ['ecl-button', 'ecl-button--' + props.type, props.class].join(' '));

    const isLink = computed(() => !isNil(props.url) && !isEmpty(props.url));
    const linkTarget = computed(() => getTarget(props.target));
    const hasIcon = computed(() => !isNil(props.icon) && !isEmpty(props.icon) && props.icon !== '');
    const iconInPosition = (position: string): boolean => {
      if (props.iconPosition) {
        return props.iconPosition === position;
      }
      return false;
    };

    const iconContainerAdditionalClass = (): string => {
      const className = ['ecl-button__container'];
      if (props.iconContainerClass) {
        className.push(props.iconContainerClass);
      }
      return className.join(' ');
    };

    const iconClassName = (position: string): string => {
      const className = ['ecl-button__icon'];
      if (props.text) {
        position === EclButtonIconPosition.After
          ? className.push('ecl-button__icon--after')
          : className.push('ecl-button__icon--before');
        return className.join(' ');
      }
      return className.join(' ');
    };

    const ariaText = computed(() => (props.ariaLabel ? props.ariaLabel : props.text));

    return {
      className,
      iconInPosition,
      EclButtonIconPosition,
      iconClassName,
      iconContainerAdditionalClass,
      ariaText,
      isLink,
      hasIcon,
      linkTarget,
    };
  },
});
