import { toRefs, computed, ComputedRef, unref } from 'vue';
import { checkExternalUrl, getTarget } from '../../../helpers/link';
import AppLink from '../AppLink.component';

export default function useExternalLink(props: InstanceType<typeof AppLink>['$props']) {
  const { external, target, autoCheckExternal, to } = toRefs(props);

  const isExternalLink: ComputedRef<boolean> = computed((): boolean => {
    if (autoCheckExternal) {
      return checkExternalUrl(to.value);
    }
    return unref(external) ?? false;
  });

  const targetLink: ComputedRef<string> = computed((): string => getTarget(target.value));

  return {
    isExternalLink,
    targetLink,
  };
}
