import { createStore, StoreOptions } from 'vuex';
import { meta, getDefaultStateMeta } from './modules/meta';
import { site, getDefaultStateSite } from './modules/site';
import { RootState } from './types';
import { tool } from './modules/tool';

const storeVuex: StoreOptions<RootState> = {
  state: {
    site: getDefaultStateSite(),
    meta: getDefaultStateMeta()
  },
  modules: {
    site,
    meta,
    tool
  }
};

const store = createStore<RootState>(storeVuex);

export default store;
export { store };
