import { inject } from 'vue';
import { AccordionData } from '../../';

export default function useData() {
  // ================ DATA ================
  const accordion = inject('accordion') as AccordionData;
  const index: number = accordion.itemCount.value++;

  // ============ DEPENDENCIES ============

  // ============== COMPUTED ==============

  // =============== METHODS ==============

  // ============== WATCHERS ==============

  return {
    index,
    accordion,
  };
}
