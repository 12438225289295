import { Module } from 'vuex';
import { RootState } from '../../types';
import { MetaModuleStore } from './types';

import { getters } from './getters';
import { mutations } from './mutations';

export const getDefaultStateMeta = (): MetaModuleStore => {
  return {
    meta: {
      title: 'European Commission',
      list: [
        {
          name: 'theme-color',
          content: '#004494'
        }
      ]
    }
  };
};

export const state: MetaModuleStore = getDefaultStateMeta();

export const meta: Module<MetaModuleStore, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations
};
