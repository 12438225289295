import { defineComponent, unref, computed, ComputedRef, ref } from 'vue';
import { isArray } from 'lodash';

import EclButton from '@/shared/ecl/src/components/EclButton/EclButton.component.vue';
import { EclButtonType } from '@/shared/ecl/src/components/EclButton/EclButton.component';

export default defineComponent({
  name: 'ParagraphMoreLess',
  emits: ['toggleCollapse'],
  components: {
    EclButton
  },
  props: {
    abstract: {
      type: String,
      required: true
    },
    descriptions: {
      type: [String, Array],
      required: false
    },
    labels: {
      type: Object as () => { more: string; less: string },
      required: false,
      default: {
        more: 'more',
        less: 'less'
      }
    },
    initCollapsed: {
      type: Boolean,
      required: false,
      default: true
    },
    // attiva l'ellipsis sull'abstract (solo se non ci sono descriptions impostate)
    breakAbstract: {
      type: Boolean,
      required: false,
      default: false
    },
    // numero di battute per l'ellipsis sull'abstract
    breakAbstractCount: {
      type: Number,
      required: false,
      default: 250
    }
  },
  setup(props, { emit }) {
    const makeUUID = (length = 7): string => {
      length = length < 30 ? length : 30;
      return Math.random()
        .toString(36)
        .substr(2, length + 2);
    };

    const id = ref(`long-description-${makeUUID()}`);

    const ellipsis = '...';

    const isCollapsed = ref(props.initCollapsed);
    const isExpanded = computed(() => unref(isCollapsed) === false);

    const splitAbstract = computed(() => {
      const _d = props.descriptions;
      if (isArray(_d) && _d.length > 0) {
        return false;
      }
      return props.breakAbstract === true && props.abstract.length > props.breakAbstractCount;
    });

    const abstractText = computed(() => {
      const _a = props.abstract;
      if (unref(splitAbstract)) {
        return _a.substr(0, props.breakAbstractCount) + (isCollapsed.value ? ellipsis : '');
      } else {
        return _a;
      }
    });

    const paragraphList = computed((): string[] | undefined => {
      if (unref(splitAbstract)) {
        const _a = props.abstract;
        return [_a.substr(props.breakAbstractCount)];
      } else {
        const _d = props.descriptions;
        // if (_d.length === 0) {
        if (isArray(_d) && _d.length > 0) {
          return (_d as unknown) as string[];
        } else if (typeof _d === 'string') {
          return [_d];
        }
        // }
      }
      return undefined;
    });

    const buttonLabel: ComputedRef<string> = computed((): string => {
      return unref(isCollapsed) ? props.labels.more : props.labels.less;
    });

    const toggleCollapse = () => {
      isCollapsed.value = !unref(isCollapsed);
      emit('toggleCollapse', unref(isCollapsed));
    };

    const showExpand = computed(() => {
      return unref(isExpanded);
    });

    const showCollapsed = computed(() => {
      return unref(isCollapsed);
    });

    return {
      id,
      abstractText,
      splitAbstract,
      showExpand,
      showCollapsed,
      isCollapsed,
      isExpanded,
      buttonLabel,
      paragraphList,
      toggleCollapse,
      EclButtonType
    };
  }
});
