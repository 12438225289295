import { defineComponent, computed, unref, SetupContext } from 'vue';
import useSearch from './composables/useSearch';
import useLanguage from './composables/useLanguage';
import { EclIcon, EclLink, EclLinkType, EclLinkIconPosition, EclLanguageList } from '../../';
// import EclSearchForm from '../../Forms/EclSearchForm/EclSearchForm.component.vue';
import { baseClassName, logos } from '../../../helpers/helpers';
import useEcl from '../../AppCurrentInstance';
import { isMobileOrTablet } from '../../../helpers/detect-device';

export enum EclSiteHeaderType {
  Core = 'core',
  Harmonised = 'harmonised',
}

export default defineComponent({
  name: 'EclSiteHeaderCore',
  emits: ['performSearch'],
  components: {
    EclLink,
    EclIcon,
    EclLanguageList,
    // EclSearchForm,
  },
  props: {
    group: {
      type: String,
      required: false,
      default: '',
    },
    type: {
      type: String,
      required: false,
      default: EclSiteHeaderType.Core,
    },
    showSearch: {
      type: Boolean,
      required: false,
      default: false,
    },
    showLanguage: {
      type: Boolean,
      required: false,
      default: null,
    },
    showAuth: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, context: SetupContext) {
    const search = useSearch(context);
    const language = useLanguage(props);

    const isEc = computed(() => useEcl().theme === 'ec');

    const isMobileTablet = computed(() => isMobileOrTablet());

    if (props.type === EclSiteHeaderType.Harmonised) {
      if (props.group === null || props.group === undefined || props.group === '') {
        throw new Error('EclSiteHeader: In ' + props.type + ' tipology you must provide a group!');
      }
    }

    const identity = computed(() => {
      return {
        title: unref(isEc) ? 'ECLV3.HEADER.EC.IDENTITY' : 'ECLV3.HEADER.EU.IDENTITY',
        url: unref(isEc)
          ? `https://ec.europa.eu/info/index_${unref(language.currentLocale)}`
          : `https://european-union.europa.eu/index_${unref(language.currentLocale)}`,
        logo: logos.get(unref(language.currentLocale), 'positive'),
      };
    });

    return {
      ...search,
      ...language,
      identity,
      isMobileTablet,
      className: (suffix: string): string => {
        return baseClassName('ecl-site-header', null, suffix);
      },
      EclLinkType,
      EclLinkIconPosition,
    };
  },
});
