import { last, isEqual } from 'lodash';
import { EclSelectOptionExtended } from '../';
import useDropdown from './useDropdown';
import useOptions from './useOptions';

// export type UseKeyboard = {
//   handleEnter;
//   handleSpace;
//   handleSpaceSelectAll;
//   handleEnterSelectAll;
//   handleSpaceDeselectAll;
//   handleEnterDeselectAll;
//   handleOptionBlur: (option: EclSelectOptionExtended) => void;
// };

export default function useKeyboard(dependencies: {
  useDropdown: ReturnType<typeof useDropdown>;
  useOptions: ReturnType<typeof useOptions>;
}) {
  // ============ DEPENDENCIES ============

  const close = dependencies.useDropdown.close;
  const filteredOptions = dependencies.useOptions.filteredOptions;
  const handleOptionClick = dependencies.useOptions.handleOptionClick;
  const handleOptionClickSelectAll = dependencies.useOptions.handleOptionClickSelectAll;
  const handleOptionClickDeselectAll = dependencies.useOptions.handleOptionClickDeselectAll;

  // =============== METHODS ==============

  const handleSpaceSelectAll: () => void = handleOptionClickSelectAll;
  const handleEnterSelectAll: () => void = handleOptionClickSelectAll;
  const handleSpaceDeselectAll: () => void = handleOptionClickDeselectAll;
  const handleEnterDeselectAll: () => void = handleOptionClickDeselectAll;

  // =============== METHODS ==============

  const handleSpace: (option: EclSelectOptionExtended) => void = handleOptionClick;

  const handleEnter: (option: EclSelectOptionExtended) => void = handleOptionClick;

  /**
   * Chiude il pannello se è l'ultima option
   * Così se l'utente, con il pannello aperto, usa il tab, il dropdown si chiude automaticamente
   *
   * @param {EclSelectOptionExtended} option - option with blur event
   */
  const handleOptionBlur = (option: EclSelectOptionExtended): void => {
    if (isEqual(option, last(filteredOptions.value))) {
      close();
    }
  };

  return {
    handleEnter,
    handleSpace,
    handleSpaceSelectAll,
    handleEnterSelectAll,
    handleSpaceDeselectAll,
    handleEnterDeselectAll,
    handleOptionBlur,
  };
}
