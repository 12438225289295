import { toRefs, unref, SetupContext, computed } from 'vue';
import { find, isNil } from 'lodash';
import { CheckboxItem, EclCheckbox } from '../';
import useValue from './useValue';
import useCheckbox from './useCheckbox';

export default function useData(
  props: InstanceType<typeof EclCheckbox>['$props'],
  context: SetupContext,
  dependencies: { useValue: ReturnType<typeof useValue>; useCheckbox: ReturnType<typeof useCheckbox> },
) {
  const { valueProp } = toRefs(props);

  // ============ DEPENDENCIES ============

  const internalValue = dependencies.useValue.internalValue;
  const extendedCheckboxes = dependencies.useCheckbox.extendedCheckboxes;

  // =============== DATA ==============
  internalValue.value = extendedCheckboxes.value.filter(checkbox => checkbox.checked === true);

  // ============== COMPUTED ==============

  const valueKey = computed(() => unref(valueProp) as keyof CheckboxItem);

  // =============== METHODS ==============

  const makeExternal = (val: CheckboxItem[]) => {
    // No need to transform if empty value
    if (isNil(val)) {
      return val;
    }

    if (unref(extendedCheckboxes).length > 1) {
      return val.map(v => v[unref(valueKey)]);
    }

    const foundItem = find(val, i => i.checked === true);
    return foundItem ? foundItem[unref(valueKey)] : undefined;
  };

  const makeInternal = (val: CheckboxItem[]) => {
    if (isNil(val)) {
      return [];
    }

    return val;
  };

  const update = (val: CheckboxItem[]) => {
    // Setting object(s) as internal value
    internalValue.value = makeInternal(val);

    // Setting object(s) or plain value as external
    // value based on `option` setting
    const externalVal = makeExternal(val);

    context.emit('change', externalVal);
    context.emit('update:modelValue', externalVal);
  };

  return {
    update,
  };
}
