import { computed, defineComponent } from 'vue';
import { isNil } from 'lodash';

// import useEcl from './../../AppCurrentInstance';
import { AppLink, EclIcon } from '../../';
import { getTarget } from '../../../helpers/link';

export enum EclLinkIconPosition {
  Before = 'before',
  After = 'after',
}

export enum EclLinkType {
  Default = 'default',
  Standalone = 'standalone',
  CallToAction = 'cta',
  CTA = 'cta',
  Primary = 'primary',
  Secondary = 'secondary',
}

export default defineComponent({
  name: 'EclLink',
  components: {
    AppLink,
    EclIcon,
  },
  props: {
    type: {
      type: String,
      required: false,
      default: EclLinkType.Default as string,
      validator: (type: string) => Object.values<string>(EclLinkType).includes(type),
    },
    url: {
      type: String,
      required: false,
      default: 'javascript:void(0)',
    },
    target: {
      type: String,
      required: false,
      default: '_self',
    },
    label: {
      type: String,
      required: false,
    },
    ariaLabel: {
      type: String,
      required: false,
    },
    negative: {
      type: Boolean,
      required: false,
      default: false,
    },
    iconPosition: {
      type: String,
      required: false,
      default: EclLinkIconPosition.After as string,
      validator: (position: string) => Object.values<string>(EclLinkIconPosition).includes(position),
    },
    icon: {
      ...EclIcon.props.icon,
      required: false,
    },
    iconRotate: EclIcon.props.rotate,
    iconSize: EclIcon.props.size,
  },
  setup(props) {
    // import(`./EclLink.component.${useEcl().theme}.scss`);

    if (isNil(props.ariaLabel) && isNil(props.label)) {
      console.error('EclLink: you must provide almost ariaLabel or label!');
    }
    const className = function(): string {
      const classes: string[] = ['ecl-link'];
      classes.push('ecl-link--' + props.type);
      if (props.negative) {
        classes.push('ecl-link--negative');
      }
      if (props.icon) {
        classes.push('ecl-link--icon');
        classes.push('ecl-link--icon-' + props.iconPosition);
      }
      return classes.join(' ');
    };

    const linkTarget = computed(() => {
      return getTarget(props.target);
    });

    const cAriaLabel = computed((): string | undefined => {
      if (props.ariaLabel) return props.ariaLabel;
      return props?.label;
    });

    return {
      cAriaLabel,
      EclLinkIconPosition,
      className,
      linkTarget,
    };
  },
});
