const headerConfigs: {
  className?: {
    label: string;
    url: string;
  };
  siteName: string;
} = {
  className: null,
  siteName: 'Urban Data Platform Plus'
};
export default headerConfigs;
