import { defineComponent } from 'vue';
import { baseClassName } from '../../helpers/helpers';
import { EclIcon, EclIconSize, EclLink, EclLinkIconPosition, EclLinkType } from '../';

export default defineComponent({
  name: 'EclPageHeaderCore',
  components: {
    EclIcon,
    EclLink,
  },
  props: {
    title: {
      required: true,
      type: String,
    },
    url: {
      required: true,
      type: String,
    },
    language: {
      required: false,
      type: String,
    },
    meta: {
      required: false,
      type: String,
    },
  },
  setup() {
    return {
      className: (suffix?: string): string => {
        return baseClassName('ecl-file', null, suffix);
      },
      EclIconSize,
      EclLinkIconPosition,
      EclLinkType,
    };
  },
});
