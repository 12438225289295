import { defineComponent, ref, Ref, nextTick } from 'vue';
import { head } from 'lodash';
import { makeUUID } from '../../../helpers/random';

export default defineComponent({
  name: 'EclFileUpload',
  emits: ['update:modelValue', 'change'],
  props: {
    id: {
      type: String,
      default: `eclfileupload-${makeUUID()}`,
    },
    required: {
      type: Boolean,
      default: false,
    },
    requiredText: {
      type: String,
      default: '*',
    },
    label: {
      type: String,
      default: null,
    },
    buttonChooseLabel: {
      type: String,
      default: 'Choose file',
    },
    buttonReplaceLabel: {
      type: String,
      default: 'Replace file',
    },
    showHelperText: {
      type: Boolean,
      default: false,
    },
    helperText: {
      type: String,
      default:
        // eslint-disable-next-line quotes
        "This is the input's helper text.<div class='ecl-u-mt-xs ecl-u-type-color-grey-75'>Only <strong>txt doc docx pdf odt rtf</strong> files. Maximum size is <strong>5 MB</strong>.<br />Encrypted documents and those containing macros are not accepted.</div>",
    },
  },
  setup(props, { emit }) {
    const inputEl = ref() as Ref<HTMLInputElement | undefined>;
    const buttonLabel = ref(props.buttonChooseLabel);

    const fileChange = (event: Event) => {
      const input = event.target as HTMLInputElement;
      if (input?.files && input.files.length > 0) {
        emit('update:modelValue', head(input.files));
        emit('change', head(input.files));
        buttonLabel.value = props.buttonReplaceLabel;
      }
    };

    /**
     * External API for interface
     */
    const reset = (): void => {
      nextTick(() => {
        inputEl.value = undefined;
        buttonLabel.value = props.buttonChooseLabel;
      });
    };

    return {
      inputEl,
      buttonLabel,
      fileChange,
      reset,
    };
  },
});
