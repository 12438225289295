import { computed, unref, ref } from 'vue';
import { map, concat, uniqBy, isNil } from 'lodash';
import { useRouter } from 'vue-router';

import { appConfigs } from '@/configs';
import store from '@/store';
import { VueRoute } from '@/helpers/url/VueRoute';
import { decodeString } from '../../../helpers/string/mutate';
import i18n from '@/locale';

export default function useBreadcrumb() {
  // ================ DATA ================

  const mapping = appConfigs?.url?.mapping?.placeOrTrends || {};
  const router = useRouter();

  // ============ GENERAL =================

  const route = computed(() => unref(router.currentRoute));
  const routeName = computed(() => unref(route)?.name as string);
  // const urlManager = computed(() => new Url(mapping, unref(routeName)));

  // const pageConfig = computed(() => store.getters['site/pageConfig'](unref(routeName)) as SitePageConfigDashboard);

  // ============ DEPENDENCIES ============

  // const t = useI18n().t;

  // =============== METHODS ==============

  // const urlMaker = (params?: UrlParams): string => {
  //   return unref(urlManager).makeUrl(params);
  // };

  // ============== COMPUTED ==============

  const defaultList = computed(() => store.state.site.breadcrumb);
  // const breadcrumbType = computed(
  //   () => (unref(route)?.meta?.breadcrumb as SitePageConfigMeta['breadcrumb'])?.type ?? null
  // ); decodeString(

  const breadcrumbList = computed(() => {
    const vueRouter = new VueRoute(router);
    let list = map(unref(defaultList), item => ({
      ...item,
      url: vueRouter.convertToRouteOrUrl(unref(item.url))
    }));
    const currentRoute = unref(router.currentRoute);

    list.map(function(oggetto) {
      oggetto.title = decodeString(oggetto.title);
      // oggetto.title = oggetto.title === 'Rural toolkit' ? store.state.site.name : oggetto.title;
    });

    const pageTitle = ref('');
    pageTitle.value = currentRoute.meta.label as string;

    if (pageTitle.value) {
      const title = ref('');
      title.value = decodeString(i18n.global.t(pageTitle.value));

      list = concat(list, [
        {
          title: title.value, // pageTitle.value,
          // url: unref(routeName)
          url: vueRouter.convertToRouteOrUrl(unref(routeName))
        }
      ]);
      // uniqBy(list, 'url');
    }

    return uniqBy(list, 'url');
  });

  // const cmpDatabrick = useDatabrickSelection();
  // const cmpTercet = useTercetSelection();

  // ============== WATCHERS ==============

  return {
    breadcrumbList
    // ...cmpDatabrick,
    // ...cmpTercet
  };
}
