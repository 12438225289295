import { ref, Ref, toRefs, unref } from 'vue';
import { InternalSelectedObjectType, InternalValueType, ListItem } from '../types';
import { assign, isNil, isEmpty, filter } from 'lodash';

export type UseValue = {
  searchValue: Ref<string>;
  internalValue: Ref<InternalValueType>;
  internalSelectedObject: Ref<InternalSelectedObjectType>;
  externalValue: Ref<InternalValueType>;
  forceOpenOnTree: Ref<boolean>;
  update: (item: ListItem) => void;
  clear: () => void;
  clearInternalSelectedObject: () => void;
  performInputChange: (event: Event) => void;
};

export default function useValue(props, context): UseValue {
  const { modelValue, multipleModelKeyValue, multiple, tree, treeOnSearchOpen, treeForceOpen } = toRefs(props);

  // ================ DATA ================
  const forceOpenOnTree: Ref<boolean> = ref(unref(treeForceOpen));
  const throttleInputChange: Ref<number | null> = ref(null);
  const searchValue: Ref<string> = ref('');
  const internalSelectedObject: Ref<InternalSelectedObjectType> = ref();
  const externalValue: Ref<InternalValueType> = ref();
  // const externalValue: Ref<InternalValueType> = context.expose !== undefined ? modelValue : ref(unref(modelValue));
  const internalValue: Ref<InternalValueType> = ref();

  // ============ DEPENDENCIES ============

  // ============== COMPUTED ==============

  // =============== METHODS ==============

  const makeValue = () => {
    if (unref(multiple) === true) {
      internalSelectedObject.value = [];
      externalValue.value = unref(modelValue) instanceof Array ? unref(modelValue) : [];
      internalValue.value = externalValue.value;
    } else {
      internalSelectedObject.value = null;
      externalValue.value = unref(modelValue);
      internalValue.value = externalValue.value;
    }
  };
  makeValue();

  const clearInternalSelectedObject = (): void => {
    if (unref(multiple) === true) {
      internalSelectedObject.value = [];
    } else if (unref(multipleModelKeyValue)) {
      internalSelectedObject.value = {};
    } else {
      internalSelectedObject.value = null;
    }
  };

  const clear = (): void => {
    clearInternalSelectedObject();
    if (unref(multiple) === true) {
      internalValue.value = [];
    } else if (unref(multipleModelKeyValue)) {
      internalValue.value = {};
    } else {
      internalValue.value = '';
    }

    context.emit('selected', null);
    context.emit('update:modelValue', null);
  };

  const update = (item: ListItem): void => {
    if (isNil(item)) {
      return clear();
    }

    forceOpenOnTree.value = false;

    let isSelected = true;
    const _iv = internalValue.value;
    const _iso = internalSelectedObject.value;
    if (unref(multiple) === true) {
      if (_iv instanceof Array && _iso instanceof Array) {
        if (_iv.includes(item.value)) {
          internalValue.value = filter(_iv, val => val.toString() !== item.value.toString());
          internalSelectedObject.value = filter(_iso, obj => obj.value.toString() !== item.value.toString());
          isSelected = false;
        } else {
          _iv.push(item.value);
          _iso.push(item);
        }
      } else {
        internalValue.value = [item.value];
        internalSelectedObject.value = [item];
      }
    } else if (unref(multipleModelKeyValue)) {
      if (item) {
        assign(internalValue.value, {
          [item._original[unref(multipleModelKeyValue)]]: item.value
        });
        assign(internalSelectedObject.value, {
          [item._original[unref(multipleModelKeyValue)]]: item
        });
      }
    } else {
      internalValue.value = item.value;
      internalSelectedObject.value = item;
    }

    context.emit('update:modelValue', unref(internalValue));
    if (isSelected) {
      context.emit('selected', item._original);
    } else {
      context.emit('deselected', item._original);
    }
  };

  const performInputChange = (event: Event): void => {
    if (unref(throttleInputChange)) {
      clearTimeout(unref(throttleInputChange));
    }
    throttleInputChange.value = setTimeout(() => {
      searchValue.value = (event.target as HTMLInputElement).value;
      const value = unref(searchValue);
      if (unref(tree) && unref(treeOnSearchOpen)) {
        forceOpenOnTree.value = !isNil(value) && !isEmpty(value);
      }
      context.emit('search', value);
    }, 150);
  };

  return {
    searchValue,
    internalValue,
    internalSelectedObject,
    externalValue,
    forceOpenOnTree,
    update,
    clear,
    clearInternalSelectedObject,
    performInputChange
  };
}
