import { last, isEqual } from 'lodash';
import { ListItem } from '../types';

export default function useKeyboard(dependencies) {
  // ============ DEPENDENCIES ============

  const extendedList = dependencies.extendedList;
  const close: () => void = dependencies.close;
  const handleClickItem: (item: ListItem) => void = dependencies.handleClickItem;

  // =============== METHODS ==============

  const handleSpace = handleClickItem;

  const handleEnter = handleClickItem;

  const handleArrowUp = (): void => {
    console.log('handleArrowUp');
  };

  const handleArrowDown = (): void => {
    console.log('handleArrowDown');
  };

  /**
   * Chiude il pannello se è l'ultima item
   * Così se l'utente, con il pannello aperto, usa il tab, il dropdown si chiude automaticamente
   *
   * @param {ExtendedItem} item - item with blur event
   */
  const handleItemBlur = (item: ListItem) => {
    if (isEqual(item, last(last(extendedList.value)))) {
      close();
    }
  };

  return {
    handleEnter,
    handleSpace,
    handleArrowUp,
    handleArrowDown,
    handleItemBlur
  };
}
