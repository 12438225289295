import { RouteLocationNormalized, RouteLocationRaw, Router } from 'vue-router';
import { isNil, merge, map, assign, each } from 'lodash';

import { decodeString } from '@/helpers/string/mutate';
import { LocaleManager } from '@/managers';
import { appConfigs } from '@/configs';
import { loadLocaleMessages, loadLocales } from '@/locale';
import { store } from '@/store';
import { provideApolloClient } from '@vue/apollo-composable';
import { makeApolloClient } from '../services/clients/ApolloClient';
import SiteClient, { SiteResponse } from '../services/api/SiteClient';
import { VueRoute } from '../helpers/url/VueRoute';
import { SiteModuleStore } from '../store/modules/site/types';

/**
 * Carica i settings del site
 *
 * @returns {Promise<SiteResponse>}
 */
export const loadSite = async (): Promise<SiteResponse> => {
  const siteId = appConfigs.api.params.site;

  return new Promise((resolve, reject) => {
    provideApolloClient(makeApolloClient('private'));
    const { onResult, onError } = SiteClient.site(siteId);
    onResult(response => {
      const contextList = map(response.data.site.contextList, ctx => ctx.slug);
      store.commit('site/name', response.data.site.name);
      store.commit('site/breadcrumb', response.data.site.breadcrumbs);
      store.commit('site/contentOwnerDetails', response.data.site.contentOwnerDetails);
      store.commit('site/contextListAvailable', contextList);
      resolve(response.data);
    });

    onError(error => {
      reject(error);
      throw error;
    });
  });
};

/**
 * Carica tutte le lingue disponibili per il sito
 * se non presente imposta una lingua di default
 * verifica che la lingua impostata sia disponibile tra quelle disponibili per il sito
 *
 * @param {RouteLocationNormalized} to
 * @returns {Promise<boolean | RouteLocationRaw>}
 */
export const languageCheck = async (to: RouteLocationNormalized): Promise<boolean | RouteLocationRaw> => {
  // load the available locale
  await loadLocales();

  // get the params from url
  const lang: string = to.query.lng ? to.query.lng.toString() : null;

  // verify if the lang is available
  const langIsAvailable = LocaleManager.localeIsAvailable(lang);
  if (!langIsAvailable) {
    const browserLang: string = LocaleManager.getBrowserLanguage(true);
    const defaultLang: string = LocaleManager.localeIsAvailable(browserLang) ? browserLang : 'en';
    LocaleManager.setLocale(defaultLang);
    return merge(to, {
      path: to.fullPath,
      query: {
        lng: defaultLang
      },
      replace: true
    }) as RouteLocationRaw;
  }

  await loadLocaleMessages(lang);
  LocaleManager.setLocale(lang);
  return true;
};

/**
 * Carica la navigazione principale (il main nav)
 *
 * @param {Router} router
 * @returns {Promise<SiteModuleStore['mainNav']>}
 */
export const loadMainNavigation = async (router: Router): Promise<SiteModuleStore['mainNav']> => {
  const helperVueRoute = new VueRoute(router);
  const siteId = appConfigs.api.params.menu;
  const currentMainNav = store.state?.site?.mainNav ?? ({ id: null } as SiteModuleStore['mainNav']);

  return new Promise((resolve, reject) => {
    if (siteId !== currentMainNav.id) {
      provideApolloClient(makeApolloClient('private'));
      const { onResult, onError } = SiteClient.menu(siteId);
      onResult(response => {
        const mainNav = {
          id: siteId,
          items: map(response.data.menu.menu, itemNav => {
            const item = assign({}, itemNav);
            item.label = decodeString(item.label);
            item.to = helperVueRoute.convertToRouteOrUrl(item.to);
            if (!isNil(item.children)) {
              each(item.children, itemChild => {
                itemChild.to = helperVueRoute.convertToRouteOrUrl(itemChild.to);
              });
            }

            return item;
          })
        } as SiteModuleStore['mainNav'];

        store.commit('site/mainNav', mainNav);
        resolve(mainNav);
      });

      onError(error => {
        reject(error);
        throw error;
      });
    } else {
      resolve(currentMainNav);
    }
  });
};
