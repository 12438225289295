import { createVNode as _createVNode, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  role: "navigation",
  ref: "eclMenuRoot",
  class: "ecl-menu ecl-menu--group1",
  "data-ecl-menu": "",
  "data-ecl-auto-init": "Menu"
}
const _hoisted_2 = { class: "ecl-container ecl-menu__container" }
const _hoisted_3 = {
  class: "ecl-menu__inner",
  "data-ecl-menu-inner": ""
}
const _hoisted_4 = { class: "ecl-menu__title" }
const _hoisted_5 = { class: "ecl-menu__list" }
const _hoisted_6 = {
  key: 3,
  class: "ecl-menu__mega",
  "data-ecl-menu-mega": ""
}
const _hoisted_7 = { class: "ecl-menu__sublist" }

export function render(_ctx, _cache) {
  const _component_ecl_icon = _resolveComponent("ecl-icon")
  const _component_ecl_link = _resolveComponent("ecl-link")
  const _component_ecl_button = _resolveComponent("ecl-button")

  return (_openBlock(), _createBlock("nav", _hoisted_1, [
    _createVNode("div", {
      class: "ecl-menu__overlay",
      "data-ecl-menu-overlay": "",
      onClick: _cache[1] || (_cache[1] = (...args) => (_ctx.toggleMenu && _ctx.toggleMenu(...args)))
    }),
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_ecl_link, {
        type: "default",
        class: "ecl-menu__open",
        ariaLabel: _ctx.$t('ECLV3.MENU.LABEL'),
        "data-ecl-menu-open": "",
        url: "javascript:void(0)",
        onClick: _ctx.toggleMenu
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ecl_icon, { icon: "hamburger" }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('ECLV3.MENU.LABEL')), 1)
        ]),
        _: 1
      }, 8, ["ariaLabel", "onClick"]),
      _createVNode("section", _hoisted_3, [
        _createVNode("header", {
          "aria-label": _ctx.$t('ECLV3.MENU.LABEL'),
          class: "ecl-menu__inner-header"
        }, [
          _createVNode(_component_ecl_button, {
            class: "ecl-menu__close",
            "data-ecl-menu-close": "",
            icon: "close-filled",
            iconPosition: _ctx.EclButtonIconPosition.Before,
            iconContainerClass: "ecl-menu__close-container",
            text: _ctx.$t('ECLV3.GENERAL.CLOSE'),
            ariaLabel: _ctx.$t('ECLV3.GENERAL.CLOSE'),
            type: _ctx.EclButtonType.Text,
            onClick: _ctx.toggleMenu
          }, null, 8, ["iconPosition", "text", "ariaLabel", "type", "onClick"]),
          _createVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('ECLV3.MENU.LABEL')), 1),
          _createVNode(_component_ecl_button, {
            "data-ecl-menu-back": "true",
            class: "ecl-menu__back",
            icon: "corner-arrow",
            iconPosition: _ctx.EclButtonIconPosition.Before,
            iconRotate: 270,
            text: _ctx.$t('ECLV3.MENU.BACK'),
            ariaLabel: _ctx.$t('ECLV3.MENU.BACK'),
            type: _ctx.EclButtonType.Text
          }, null, 8, ["iconPosition", "text", "ariaLabel", "type"])
        ], 8, ["aria-label"]),
        _createVNode("ul", _hoisted_5, [
          _renderSlot(_ctx.$slots, "menu-list-prefix"),
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.navItems, (navItem, index) => {
            return (_openBlock(), _createBlock("li", {
              class: ["ecl-menu__item", {
              'ecl-menu__item--current': navItem.current,
              'ecl-menu__item--has-children': navItem.hasChildren,
              'ecl-menu__item--col1': navItem.hasChildren && navItem.children.length < 5,
              'ecl-menu__item--col2': navItem.hasChildren && navItem.children.length >= 5 && navItem.children.length < 9,
              'ecl-menu__item--col3': navItem.hasChildren && navItem.children.length >= 9 && navItem.children.length < 13,
              'ecl-menu__item--full': navItem.hasChildren && navItem.children.length >= 13,
            }],
              "data-ecl-has-children": navItem.hasChildrenNullable,
              "data-ecl-menu-item": "",
              "aria-haspopup": "",
              "aria-expanded": _ctx.isExpanded(index),
              ref: el => {_ctx.navItemsElWrapper[index] = el}
            }, [
              (navItem.hasLink)
                ? (_openBlock(), _createBlock("a", {
                    key: 0,
                    href: navItem.href,
                    onClick: _withModifiers($event => (_ctx.navigateTo(navItem, index)), ["prevent"]),
                    target: navItem.target,
                    class: "ecl-menu__link",
                    "data-ecl-menu-link": "",
                    innerHTML: navItem.label
                  }, null, 8, ["href", "onClick", "target", "innerHTML"]))
                : (_openBlock(), _createBlock("span", {
                    key: 1,
                    class: "ecl-menu__link",
                    "data-ecl-menu-link": "",
                    innerHTML: navItem.label
                  }, null, 8, ["innerHTML"])),
              (navItem.hasChildren)
                ? (_openBlock(), _createBlock(_component_ecl_button, {
                    key: 2,
                    "data-ecl-menu-caret": "",
                    class: "ecl-menu__button-caret",
                    icon: "corner-arrow",
                    iconSize: "xs",
                    ariaLabel: _ctx.$t('ECLV3.MENU.ARIA_LABEL_CHILDREN_ITEM'),
                    type: "primary"
                  }, null, 8, ["ariaLabel"]))
                : _createCommentVNode("", true),
              (navItem.hasChildren)
                ? (_openBlock(), _createBlock("div", _hoisted_6, [
                    _createVNode("ul", _hoisted_7, [
                      (_openBlock(true), _createBlock(_Fragment, null, _renderList(navItem.children, (navSubItem, subIndex) => {
                        return (_openBlock(), _createBlock("li", {
                          key: subIndex,
                          class: ["ecl-menu__subitem", {'ecl-menu__subitem--current': navSubItem.current}],
                          "data-ecl-menu-subitem": ""
                        }, [
                          _createVNode("a", {
                            href: navSubItem.to,
                            onClick: _withModifiers($event => (_ctx.navigateTo(navSubItem)), ["prevent"]),
                            target: navSubItem.target,
                            class: "ecl-menu__sublink",
                            "data-ecl-menu-link": "",
                            innerHTML: navSubItem.label
                          }, null, 8, ["href", "onClick", "target", "innerHTML"])
                        ], 2))
                      }), 128))
                    ])
                  ]))
                : _createCommentVNode("", true)
            ], 10, ["data-ecl-has-children", "aria-expanded"]))
          }), 256)),
          _renderSlot(_ctx.$slots, "menu-list-suffix")
        ])
      ])
    ])
  ], 512))
}