import { toRefs, computed, ComputedRef, watch, SetupContext, unref } from 'vue';
import { assign, map, isNil, each, filter, isEmpty, isArray, indexOf, head } from 'lodash';
import { useI18n } from 'vue-i18n';
import { BaseDependencies, EclSelect, ModelValueData, EclSelectOption, EclSelectOptionExtended } from '../';
import useData from './useData';
import useValue from './useValue';
import useDropdown from './useDropdown';
import useSearch from './useSearch';

// export type UseOptions = {};

export default function useOptions(
  props: InstanceType<typeof EclSelect>['$props'],
  context: SetupContext,
  dependencies: BaseDependencies & {
    useData: ReturnType<typeof useData>;
    useValue: ReturnType<typeof useValue>;
    useDropdown: ReturnType<typeof useDropdown>;
    useSearch: ReturnType<typeof useSearch>;
  },
) {
  const {
    modelValue,
    options,
    optionLabel,
    optionValue,
    mode,
    limit,
    clearOnSelect,
    multipleGroupLabel,
    multipleGroupLimit,
  } = toRefs(props);
  const { t } = useI18n();

  // ============ DEPENDENCIES ============

  const UUID = dependencies.UUID;
  const internalValue = dependencies.useValue.internalValue;
  const externalValue = dependencies.useValue.externalValue;
  const checkboxSelectAll = dependencies.useValue.checkboxSelectAll;
  const update = dependencies.useData.update;
  const close = dependencies.useDropdown.close;
  const search = dependencies.useSearch.search;
  const clearSearch = dependencies.useSearch.clearSearch;

  // ================ DATA ================

  const valueProp = props.valueProp as keyof EclSelectOption;

  // ================ METHODS ================

  const isSelected = (option: EclSelectOption): boolean => {
    const _iv = unref(internalValue);
    const vProp = option[valueProp];
    if (!isNil(_iv) && !isEmpty(_iv) && !isNil(vProp)) {
      switch (unref(mode)) {
        case 'single':
          return (_iv as EclSelectOptionExtended)[valueProp] === vProp;
        case 'multiple':
          return (
            (_iv as EclSelectOptionExtended[]).map((o: EclSelectOptionExtended) => o[valueProp]).indexOf(vProp) !== -1
          );
      }
    }
    return false;
  };

  // ============== COMPUTED ==============

  /**
   * Options merged with base object for extend his functionality
   */
  const extendedOptions: ComputedRef<EclSelectOptionExtended[]> = computed(() => {
    let opts = unref(options) || [];

    const optL = unref(optionLabel) as keyof object;
    const optV = unref(optionValue) as keyof object;

    opts = map(
      opts,
      (option: object): EclSelectOption => {
        return {
          ...option,
          value: option[optV],
          label: option[optL],
        };
      },
    );

    const refactoredOptions = map(
      opts,
      (option: EclSelectOption): EclSelectOptionExtended => {
        const assignObject = assign(
          {
            id: `optchbx_${UUID}_${option.value}`,
            checked: isSelected(option),
            disabled: false,
          },
          option,
        );

        return assignObject;
      },
    );

    return refactoredOptions;
  });

  /**
   * Filter the options
   */
  const filteredOptions = computed(() => {
    if (unref(search)) {
      return filter(unref(extendedOptions), (option: EclSelectOptionExtended) => {
        const searchLowerCase = unref(search)?.toLowerCase();

        const s = String(option.label)
          .toLowerCase()
          .trim();
        return searchLowerCase ? s.indexOf(searchLowerCase) >= 0 : false;
      });
    }

    return unref(extendedOptions);
  });

  const getOption = (val: ModelValueData): EclSelectOptionExtended => {
    return extendedOptions.value[extendedOptions.value.map(o => String(o[valueProp])).indexOf(String(val))];
  };

  const multipleLabelText = computed((): string | number | null => {
    const _iv = unref(internalValue);
    if (!isNil(_iv) && !isEmpty(_iv)) {
      // Select Multiple
      if (isArray(_iv)) {
        const mgl = unref(multipleGroupLimit);
        // Se è maggiore di "multipleGroupLimit" imposta il raggruppamento
        if (unref(multipleGroupLabel) && !isNil(mgl) && _iv.length > mgl) {
          return t('ECLV3.FORM.SELECT.GROUP_LABEL', [_iv.length]);
        }
        return _iv.map((v: EclSelectOptionExtended) => v.label).join(', ');
      }

      // Select Single
      return _iv.label;
    }

    return null;
  });

  // =============== METHODS ==============

  const finalValue = (option: EclSelectOptionExtended) => {
    return option[valueProp];
  };

  const clear = (): void => {
    update(null);
  };

  const select = (option: EclSelectOptionExtended): void => {
    if (typeof option !== 'object') {
      option = getOption(option);
    }

    switch (unref(mode)) {
      case 'single':
        update(option);
        close();

        if (unref(clearOnSelect)) {
          clearSearch();
        }

        break;

      case 'multiple': {
        // selectedOption.checked = true;
        const _limit = unref(limit);
        if (!isNil(internalValue.value) && !isNil(_limit)) {
          const _iv = unref(internalValue) as EclSelectOptionExtended[];
          if (_limit === 0 || _limit > _iv.length) {
            update(_iv.concat(option));
          }
        }

        if (unref(clearOnSelect)) {
          clearSearch();
        }

        break;
      }
    }

    context.emit('select', finalValue(option));
  };

  const deselectAll = (): void => {
    checkboxSelectAll.value.checked = false;
    clear();
  };

  const deselect = (option: string | EclSelectOptionExtended): void => {
    checkboxSelectAll.value.checked = false;

    const optionObj = typeof option === 'string' ? getOption(option) : option;

    if (unref(mode) === 'single') {
      clear();
    } else {
      const _iv = unref(internalValue);
      if (_iv && isArray(_iv)) {
        update(_iv.filter(val => val[valueProp] !== optionObj[valueProp]));
      }
    }

    context.emit('deselect', finalValue(optionObj));
  };

  const isDisabled = (option: EclSelectOption | EclSelectOptionExtended) => {
    return option.disabled === true;
  };

  const handleOptionClick = (option: EclSelectOptionExtended) => {
    if (isDisabled(option)) {
      return;
    }

    switch (unref(mode)) {
      case 'single':
        if (isSelected(option)) {
          return;
        }

        select(option);
        // blurSearch();
        // blurInput();
        break;

      case 'multiple':
        if (isSelected(option)) {
          deselect(option);
          return;
        }

        // if (isMax()) {
        //   return;
        // }

        select(option);

        // if (clearOnSelect.value) {
        //   clearSearch();
        // }
        break;
    }
  };

  const handleOptionClickSelectAll = (): void => {
    checkboxSelectAll.value.checked = !checkboxSelectAll.value.checked;
    if (unref(mode) === 'multiple') {
      each(filteredOptions.value, (option: EclSelectOptionExtended) => {
        if (!isDisabled(option)) {
          if (checkboxSelectAll.value.checked) {
            if (isSelected(option) === false) select(option);
          } else {
            if (isSelected(option)) deselect(option);
          }
        }
      });
    }
  };

  const handleOptionClickDeselectAll = (): void => {
    deselectAll();
  };

  const handleOptionNativeChange = ($event: Event): void => {
    // @ts-ignore
    const selectedOptions = map($event?.target?.selectedOptions, (option: HTMLOptionElement) => option.value);

    if (unref(mode) === 'single' && head(selectedOptions) === '') {
      clear();
    }

    each(filteredOptions.value, (option: EclSelectOptionExtended) => {
      const nowIsActive: boolean = indexOf(selectedOptions, option.value) >= 0;
      if ((isNil(option.checked) || option.checked === false) && nowIsActive) {
        select(option);
      }
      if (option.checked && !nowIsActive) {
        deselect(option);
      }
    });
  };

  const makeInternal = (
    val: undefined | ModelValueData | ModelValueData[],
  ): EclSelectOptionExtended | EclSelectOptionExtended[] | undefined => {
    if (isNil(val)) {
      return unref(mode) === 'single' ? undefined : [];
    }

    // If external should be plain transform
    // value object to plain values
    if (unref(mode) === 'single') {
      return getOption(val as ModelValueData);
    } else if (isArray(val)) {
      return val.filter(v => !!getOption(v)).map(v => getOption(v));
    }
  };

  const initInternalValue = () => {
    if (!isNil(externalValue.value)) {
      internalValue.value = makeInternal(externalValue.value);
    }
  };

  initInternalValue();

  // ============== WATCHERS ==============

  // se è già impostato il valore dell'externalValue
  // e le options vengono aggiornate
  // watch(options, () => {
  //   if (isEmpty(internalValue.value) !== isEmpty(externalValue.value)) {
  //     // internalValue.value = makeInternal(externalValue.value);
  //   }
  // });

  // se viene aggiornato il valore dell'externalValue
  // aggiorna tutti i dati
  // watch(
  //   externalValue,
  //   newValue => {
  //     if (isNil(newValue) || isNil(internalValue.value)) {
  //       internalValue.value = makeInternal(newValue);
  //       return;
  //     }
  //     switch (mode.value) {
  //       case 'single':
  //         if (newValue != internalValue.value[valueProp]) {
  //           internalValue.value = makeInternal(newValue);
  //         }
  //         break;
  //       case 'multiple':
  //         if (
  //           !isEqual(
  //             newValue,
  //             internalValue.value.map(o => o[valueProp]),
  //           )
  //         ) {
  //           internalValue.value = makeInternal(newValue);
  //         }
  //         break;
  //     }
  //   },
  //   { deep: true },
  // );

  watch(
    () => unref(modelValue),
    newValue => {
      internalValue.value = makeInternal(newValue);
    },
    {
      immediate: true,
    },
  );

  return {
    extendedOptions,
    filteredOptions,
    multipleLabelText,
    handleOptionClick,
    handleOptionClickSelectAll,
    handleOptionClickDeselectAll,
    handleOptionNativeChange,
  };
}
