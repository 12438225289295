import { defineComponent } from 'vue';
import { EclPageHeader, EclPageHeaderType } from '../EclPageHeader';

export default defineComponent({
  name: 'EclPageHeaderHarmonised',
  components: {
    EclPageHeader,
  },
  props: {
    metaInfo: EclPageHeader.props.metaInfo,
    pageTitle: EclPageHeader.props.pageTitle,
    description: EclPageHeader.props.description,
    backgroundImage: EclPageHeader.props.backgroundImage,
    breadcrumb: EclPageHeader.props.breadcrumb,
    breadcrumbEllipsisLimit: EclPageHeader.props.breadcrumbEllipsisLimit,
  },
  setup() {
    return { EclPageHeaderType };
  },
});
