import { defineComponent, inject, computed, ComputedRef, watch, ref, onMounted, reactive, unref, Ref } from 'vue';
import { isNil, each } from 'lodash';
import store from '@/store';
import { LocaleManager } from '@/managers';
import EclButton from '@/shared/ecl/src/components/EclButton/EclButton.component.vue';
import EclIcon from '@/shared/ecl/src/components/EclIcon/EclIcon.component.vue';
import JclAutocomplete from '@/shared/jcl/src/components/JclAutocomplete/JclAutocomplete.component.vue';
import { isMobileOrTablet } from '@/shared/ecl/src/helpers/detect-device';
// import caseStudies from '@/_static/case_studies.json';
import i18n from '@/locale';
import { decodeString } from '../../helpers/string/mutate';

export default defineComponent({
  name: 'SearchFields',
  props: {
  },
  components: {
    EclButton, EclIcon, JclAutocomplete
  },
  setup(props) {
    const isMobileTablet = computed(() => isMobileOrTablet());
    // const totalCount = ref(caseStudies.length);
    const totalCount = ref(store.getters['tool/inspired'].length);

    const textTotal = ref('');
    textTotal.value = decodeString(i18n.global.t('inspire.textTotal'));
    const monoFunds = ref('');
    monoFunds.value = decodeString(i18n.global.t('inspire.monofounds'));
    const multiFunds = ref('');
    multiFunds.value = decodeString(i18n.global.t('inspire.multifunds'));
    const inspireKeyw = ref('');
    inspireKeyw.value = decodeString(i18n.global.t('inspire.keyw'));
    const inspireFunds = ref('');
    inspireFunds.value = decodeString(i18n.global.t('inspire.fundsSearch'));
    const inspireRemoveFilters = ref('');
    inspireRemoveFilters.value = decodeString(i18n.global.t('inspire.removeFilters'));

    watch(() => store.getters['tool/totalCountInspire'], function() {
      totalCount.value = store.getters['tool/totalCountInspire'];
    });

    const lang = !isNil(LocaleManager) && !isNil(LocaleManager.locale) && !isNil(unref(LocaleManager.locale))
      ? unref(LocaleManager.locale)
      : 'en';
    const l = lang === 'en' ? '' : '_' + lang.toUpperCase();

    let valueTypeAct;
    try {
      valueTypeAct = require('@/_static/filter_type_act' + l + '.json');
    } catch (ex) {
      valueTypeAct = require('@/_static/filter_type_act.json');
    }
    const filterTypeAct = ref([]);
    filterTypeAct.value = unref(valueTypeAct);
    const listFilterTypeAct = ref([]);
    const listFilterTypeActOrig = ref([]);

    each(filterTypeAct.value, w => {
      const keys = ['id', 'value'];
      const obj = {};

      for (let k = 0; k < keys.length; k++) {
        obj[keys[k]] = k === 0 ? w.CODE.toString() : w.DES;
      }
      listFilterTypeActOrig.value.push(obj);
      listFilterTypeAct.value.push(obj);
      listFilterTypeAct.value.sort((a, b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0));
    });

    const listFilterTypeActCat = ref([]);

    each(listFilterTypeAct.value, f => {
      // console.log(f.id);
      const cat = filterTypeAct.value.find(x => x.CODE.toString() === f.id);
      if (!isNil(cat)) {
        f.cat = cat.CAT;
        listFilterTypeActCat.value.push(f);
      } else {
        console.log(f.id);
      }
    });

    listFilterTypeActCat.value.sort((a, b) => (a.cat > b.cat) ? 1 : ((b.cat > a.cat) ? -1 : 0));
    let tmp = '';

    each(listFilterTypeActCat.value, f => {
      if (f.cat === tmp) {
        f.cat = '';
      } else {
        tmp = f.cat;
      }
    });

    const keySelected: Ref<object | undefined> = ref([]);
    const changeKey = (k): void => {
      // console.log(k);
      if (isNil(k)) {
        store.commit('tool/inspireKey', '');
        keySelected.value = null;
      } else {
        store.commit('tool/inspireKey', k.id);
        keySelected.value = k.id;
      }
    };

    const listFilterFund = ref([]);
    listFilterFund.value = store.getters['tool/listFund'];
    // console.log('>>>>>>>listFilterFund');
    // console.log(listFilterFund.value);

    const fundSelected: Ref<object | undefined> = ref([]);
    const changeFund = (k): void => {
      console.log(k);
      if (isNil(k)) {
        store.commit('tool/inspireFund', '');
        fundSelected.value = null;
      } else {
        store.commit('tool/inspireFund', k.id);
        fundSelected.value = k.id;
        // console.log(k.id);
      }
    };

    const listLtvra = ref([]);
    listLtvra.value = store.getters['tool/listPillar'];

    const changeSelectedSud = (sud, nonSud): void => {
      store.commit('tool/monoInspire', sud);
      store.commit('tool/combInspire', nonSud);
      // const url = new URL(window.location.href);
    };
    function monoToggle(): void {
      const sud = document.getElementById('sud') as HTMLInputElement | null;
      const nonSud = document.getElementById('nonSud') as HTMLInputElement | null;
      nonSud.checked = !sud.checked && !nonSud.checked ? true : nonSud.checked;

      changeSelectedSud(sud.checked, nonSud.checked);
    }
    function combToggle(): void {
      const nonSud = document.getElementById('nonSud') as HTMLInputElement | null;
      const sud = document.getElementById('sud') as HTMLInputElement | null;
      sud.checked = !sud.checked && !nonSud.checked ? true : sud.checked;

      changeSelectedSud(sud.checked, nonSud.checked);
    }

    function pillarToggle(p): void {
      const pi = store.getters['tool/pillarInspire'];
      const i = pi.findIndex(e => e === p);

      store.commit('tool/pillarInspire', []);
      if (i === -1) {
        pi.push(p);
        store.commit('tool/pillarInspire', pi);
      } else {
        const tmp = pi.filter(e => e !== p);
        store.commit('tool/pillarInspire', tmp);
      }
    }

    function resetPillarCheck(): void {
      const codePillar = [];
      listLtvra.value.forEach(l => {
        const bb = document.getElementById(l.id) as HTMLInputElement | null;
        bb.checked = true;
        codePillar.push(l.id);
      });

      store.commit('tool/pillarInspire', []);
      store.commit('tool/pillarInspire', codePillar);
    };

    function resetFilters(): void {
      console.log('>>resetFilters');
      const nonSud = document.getElementById('nonSud') as HTMLInputElement | null;
      const sud = document.getElementById('sud') as HTMLInputElement | null;
      sud.checked = true;
      nonSud.checked = true;
      changeFund(null);
      changeKey(null);
      changeSelectedSud(true, true);
      resetPillarCheck();
    };

    const detailPanelOpen = ref(false);
    watch(() => store.getters['tool/clickLfpIds'], function() {
      detailPanelOpen.value = store.getters['tool/clickLfpIds'].length !== 0;
    });

    onMounted(() => {
      // console.log();
    });

    return {
      // fulltextResearch,
      // fulltextResearchBtn,
      // fulltextResearchInput,
      // fts,
      listFilterTypeActCat,
      listFilterTypeAct,
      listFilterFund,
      // BBListAndColor,
      // BBSelected,
      // resetFilters,
      monoToggle,
      combToggle,
      // BBToggle,
      totalCount,
      textTotal,
      isMobileTablet,
      // changeSelectedBB,
      // detailPanelOpen,
      changeKey,
      changeFund,
      fundSelected,
      keySelected,
      resetFilters,
      listLtvra,
      pillarToggle,
      monoFunds,
      multiFunds,
      inspireKeyw,
      inspireFunds,
      inspireRemoveFilters
    };
  }
});
