import { CheckboxItem } from '../';
import useCheck from './useCheck';

export default function useKeyboard(dependencies: { useCheck: ReturnType<typeof useCheck> }) {
  // ============ DEPENDENCIES ============

  const performClick = dependencies.useCheck.handleClick;

  // ============== COMPUTED ==============

  const handleSpace = (checkbox: CheckboxItem) => {
    performClick(checkbox);
  };

  const handleEnter = (checkbox: CheckboxItem) => {
    performClick(checkbox);
  };

  return {
    handleSpace,
    handleEnter,
  };
}
