import { GetterTree } from 'vuex';
import { SiteModuleStore } from './types';
import { RootState } from '@/store/types';

export const getters: GetterTree<SiteModuleStore, RootState> = {
  i18nLoading(state): SiteModuleStore['i18nLoading'] {
    return state.i18nLoading;
  },
  loading(state): SiteModuleStore['loading'] {
    return state.loading;
  },
  name(state): SiteModuleStore['name'] {
    return state.name;
  },
  contentOwnerDetails(state): SiteModuleStore['contentOwnerDetails'] {
    return state.contentOwnerDetails;
  },
  mainNav(state): SiteModuleStore['mainNav'] {
    return state.mainNav;
  },
  title(state): SiteModuleStore['title'] {
    return state.title;
  },
  metaInfo(state): SiteModuleStore['metaInfo'] {
    return state.metaInfo;
  },
  description(state): SiteModuleStore['description'] {
    return state.description;
  },
  breadcrumb(state): SiteModuleStore['breadcrumb'] {
    return state.breadcrumb;
  },
  // contextNav(state): SiteModuleStore['contextNav'] {
  //   return state.contextNav;
  // },
  // pagesConfig(state): SiteModuleStore['pagesConfig'] {
  //   return state.pagesConfig;
  // },
  // pageConfig: state => (name: string): SitePageConfigResponse | undefined => {
  //   if (name) {
  //     return state.pagesConfig[name] ?? undefined;
  //   }
  //   return undefined;
  // },
  contextListAvailable(state): SiteModuleStore['contextListAvailable'] {
    return state.contextListAvailable;
  },
  contextListIsAvailable: state => (ctxSlug: string): boolean => {
    return state.contextListAvailable.includes(ctxSlug);
  }
};
