import { defineComponent, computed, ref, ComputedRef, watch } from 'vue';
import { assign, map } from 'lodash';

// import useEcl from '../../AppCurrentInstance';
import { baseClassName } from '../../../helpers/helpers';
import { AppLink, EclLink, EclIcon, EclIconSize, EclButton, EclButtonType } from '../../';

export enum EclBreadcrumbType {
  Core = 'core',
  Harmonised = 'harmonised',
  Standardised = 'standardised',
}

export interface EclBreadcrumbItem {
  title: string;
  label?: string; // used for aria-label (if exist)
  classes?: string; // additional class
  style?: string; // additional css styles
  url?: string | ComputedRef<string>;
  // ellipsis?: false;
  expandable?: false;
}

export interface EclBreadcrumbExtended extends EclBreadcrumbItem {
  // ellipsis: false;
  expandable: false;
  itemType: string;
}

export default defineComponent({
  name: 'EclBreadcrumb',
  components: {
    AppLink,
    EclLink,
    EclIcon,
    EclButton,
  },
  props: {
    type: {
      type: String,
      required: false,
      default: EclBreadcrumbType.Core,
    },
    breadcrumb: {
      type: Array as () => EclBreadcrumbItem[],
      required: false,
      default: () => [],
    },
    ellipsisLimit: {
      type: Number,
      required: false,
      default: 3, // if "0" don't attach ellipsis
    },
  },
  setup(props) {
    // import(`./EclBreadcrumb-${props.type}.component.${useEcl().theme}.scss`);

    // ============ DEPENDENCIES ============

    // ================ DATA ================
    const ellipsisAlreadyClicked = ref(false);
    const hasEllipsis = ref(false);

    // =============== METHODS ==============
    const className = (suffix: string): string => {
      return baseClassName('ecl-breadcrumb', props.type, suffix);
    };

    // ============== COMPUTED ==============
    const breadcrumbExtended: ComputedRef<EclBreadcrumbExtended[]> = computed(() => {
      const total = props.breadcrumb.length;
      const ellipsisLimitRight = props.ellipsisLimit - 1; // Default is 2
      return map(
        props.breadcrumb,
        (breadcrumb, index): EclBreadcrumbExtended => {
          const slicedTotal = total - ellipsisLimitRight;
          const canSetEllipsis =
            props.ellipsisLimit !== 0 && index !== 0 && slicedTotal >= index + 1 && !ellipsisAlreadyClicked.value;
          const canExpand = canSetEllipsis && index >= 1 && index < slicedTotal;
          if (hasEllipsis.value === false) hasEllipsis.value = canExpand;

          return assign(
            {
              expandable: canExpand,
              itemType: canExpand ? 'expandable' : 'static',
            },
            breadcrumb,
          );
        },
      );
    });

    const eclIconProps = computed(() => {
      return {
        role: 'presentation',
        class: className('__icon'),
        icon: 'corner-arrow',
        rotate: 90,
        size: EclIconSize.XS2,
      };
    });

    // ============== WATCHERS ==============

    return {
      EclButtonType,
      hasEllipsis,
      isNegative: computed((): boolean => props.type === EclBreadcrumbType.Core),
      className,
      expandAll: () => {
        ellipsisAlreadyClicked.value = true;
        hasEllipsis.value = false;
      },
      linkedBreadcrumb: computed(() => breadcrumbExtended.value.slice(0, -1)),
      lastBreadcrumb: computed(() => breadcrumbExtended.value[breadcrumbExtended.value.length - 1]),
      eclIconProps,
      EclIconSize,
    };
  },
});
