import { Ref, ref, unref } from 'vue';
import useData from './useData';

export enum TransitionState {
  Enter = 'enter',
  Entering = 'entering',
  Leave = 'leave',
  Leaving = 'leaving',
}

export default function useTransitions(dependencies: { useData: ReturnType<typeof useData> }) {
  // ================ DATA ================
  const overlayTransitionState: Ref<string | undefined> = ref();
  const modalTransitionState: Ref<string | undefined> = ref();

  // ============ DEPENDENCIES ============
  const elementClose = dependencies.useData.elementClose;

  // ============== COMPUTED ==============

  // =============== METHODS ==============
  const beforeOverlayEnter = (): void => {
    overlayTransitionState.value = TransitionState.Entering;
  };
  const afterOverlayEnter = (): void => {
    overlayTransitionState.value = TransitionState.Enter;
  };
  const beforeOverlayLeave = (): void => {
    overlayTransitionState.value = TransitionState.Leaving;
  };
  const afterOverlayLeave = (): void => {
    overlayTransitionState.value = TransitionState.Leave;
  };

  const beforeModalEnter = (): void => {
    modalTransitionState.value = TransitionState.Entering;
  };
  const afterModalEnter = (): void => {
    modalTransitionState.value = TransitionState.Enter;
    const _el = unref(elementClose);
    if (_el) {
      _el.$el.focus();
    }
  };
  const beforeModalLeave = (): void => {
    modalTransitionState.value = TransitionState.Leaving;
  };
  const afterModalLeave = (): void => {
    modalTransitionState.value = TransitionState.Leave;
  };

  // ============== WATCHERS ==============

  return {
    modalTransitionState,
    overlayTransitionState,
    beforeOverlayEnter,
    afterOverlayEnter,
    beforeOverlayLeave,
    afterOverlayLeave,
    beforeModalEnter,
    afterModalEnter,
    beforeModalLeave,
    afterModalLeave,
  };
}
