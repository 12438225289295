import { toRefs, unref, SetupContext, computed } from 'vue';
import { filter, isNil } from 'lodash';
import { EclCheckbox, CheckboxItem } from '../';
import useData from './useData';
import useValue from './useValue';
import useCheckbox from './useCheckbox';

export default function useCheck(
  props: InstanceType<typeof EclCheckbox>['$props'],
  context: SetupContext,
  dependencies: {
    useCheckbox: ReturnType<typeof useCheckbox>;
    useValue: ReturnType<typeof useValue>;
    useData: ReturnType<typeof useData>;
  },
) {
  const { valueProp } = toRefs(props);

  // ============ DEPENDENCIES ============

  const extendedCheckboxes = dependencies.useCheckbox.extendedCheckboxes;
  const internalValue = dependencies.useValue.internalValue;
  const update = dependencies.useData.update;

  // ============== COMPUTED ==============

  const valueKey = computed(() => unref(valueProp) as keyof CheckboxItem);

  const isDisabled = (checkbox: CheckboxItem) => {
    return checkbox.disabled === true;
  };

  const isMax = () => {
    return internalValue.value.length >= 1000;
  };

  const isChecked = (checkbox: CheckboxItem) => {
    const _iv = unref(internalValue);
    return !isNil(_iv) && _iv.map(o => o[unref(valueKey)]).indexOf(checkbox[unref(valueKey)]) !== -1;
  };

  const getCheckbox = (val: string): CheckboxItem => {
    return extendedCheckboxes.value[extendedCheckboxes.value.map(o => String(o[unref(valueKey)])).indexOf(String(val))];
  };

  const finalValue = (checkbox: CheckboxItem): CheckboxItem => {
    return checkbox;
  };

  const check = (checkbox: CheckboxItem) => {
    if (typeof checkbox !== 'object') {
      checkbox = getCheckbox(checkbox);
    }

    checkbox.checked = true;

    update(unref(internalValue).concat(checkbox));

    context.emit('check', finalValue(checkbox));
  };

  const uncheck = (checkbox: CheckboxItem) => {
    if (typeof checkbox !== 'object') {
      checkbox = getCheckbox(checkbox);
    }

    checkbox.checked = false;

    update(filter(unref(internalValue), val => val[unref(valueKey)] !== checkbox[unref(valueKey)]));

    context.emit('uncheck', finalValue(checkbox));
  };

  const handleClick = (checkbox: CheckboxItem) => {
    if (isDisabled(checkbox)) {
      return;
    }

    if (isChecked(checkbox)) {
      uncheck(checkbox);
      return;
    }

    if (isMax()) {
      return;
    }

    check(checkbox);
  };

  const handleSpace = (checkbox: CheckboxItem) => {
    handleClick(checkbox);
  };

  return {
    handleSpace,
    handleClick,
  };
}
