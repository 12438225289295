import i18n from '@/locale';
import router from '@/router';
import { isNil, assign, difference, filter, map, merge } from 'lodash';
import { Ref, ref, unref } from 'vue';
import { localeConfigs } from '@/configs';
import {
  LocationQueryRaw,
  RouteLocationNormalizedLoaded,
  RouteParamsRaw,
  RouteRecordName,
  useRouter
} from 'vue-router';
import { store } from '@/store';
const baseUrl: string = process.env.BASE_URL.replace(/\/$/, '');

export interface Language {
  code: string;
  name: string;
  extraEu?: boolean;
  urlToRoot?: boolean;
}

class LocaleManager {
  private static _instance: LocaleManager = new LocaleManager();
  public alreadyProcessed: Ref<boolean> = ref(false);
  public locale: Ref<string> = ref(null);
  public languages: Ref<Language[]> = ref([]);
  public languagesEu: Ref<Language[]> = ref([]);
  public languagesExtraEu: Ref<Language[]> = ref([]);

  constructor() {
    if (LocaleManager._instance) {
      throw new Error('Error: Instantiation failed: Use SingletonClass.getInstance() instead of new.');
    }
    LocaleManager._instance = this;
  }

  /**
   * Retrieve the class instance
   */
  public static getInstance(): LocaleManager {
    return LocaleManager._instance;
  }

  private extraLanguages(languages: Language[]): Language[] {
    return map(languages, (language: Language) => {
      return assign({ urlToRoot: false }, language, { extraEu: false });
    });
  }

  /**
   * Retrieve the class instance
   */
  public autoInit(): void {
    if (localeConfigs.languages) {
      this.setLanguages(this.extraLanguages(localeConfigs.languages));
    }
  }

  /**
   * Retrieve the fallback locale code
   *
   * @returns {string} - locale code
   */
  public getFallbackLocale(): string {
    return localeConfigs.fallbackLocale;
  }

  /**
   * Check if the locale code is available
   *
   * @param {string} locale - locale code
   * @returns {string} - code language or fallback
   */
  public localeIsAvailable(locale: string): boolean {
    const foundedLanguage: Language[] = this.languages.value.filter((l: Language) => l.code === locale);
    return foundedLanguage.length > 0;
  }

  /**
   * Get language code from browser
   * @returns {string} - the language code
   */
  public getBrowserLanguage(checkAvailability = false): string {
    let locale: string = navigator.language.split('-')[0].toLowerCase();
    if (checkAvailability && !this.localeIsAvailable(locale)) {
      locale = this.getFallbackLocale();
    }
    return locale;
  }

  /**
   * Update the locale language of i18n
   *
   * @param {string} code - Language code
   */
  public setLocale(code: string): void {
    if (this.locale.value !== code) {
      this.locale.value = code;
      i18n.global.locale.value = code;
      document.documentElement.lang = code;
      document.lang = code;

      if (!isNil(window.$wt) && !isNil(window.$wt.components) && !isNil(window.$wt.components.globan)) {
        const globanElement = document.getElementById('globan');
        if (globanElement.hasAttribute('data-nosnippet')) {
          if (globanElement.getAttribute('data-nosnippet') === 'true') {
            globanElement.remove();
            window.$wt.trigger(window, 'wtReady');
          }
        }
      }
    }
  }

  public setLocaleMessage(locale: string, messages): void {
    console.log('>>>pageConfig1');
    // console.log(locale);
    console.log(messages);
    // console.log(messages.home.description);
    // console.log('<<<<<<<');
    // store.commit('tool/text1', messages.home.description);
    store.commit('tool/text1', 'textfafafafafafaf');
    i18n.global.setLocaleMessage(locale, messages);
  }

  /**
   * Get all available languages (object)
   *
   * @returns {Language[]}
   */
  public getLanguages(): Ref<Language[]> {
    return this.languages;
  }

  /**
   * Get EU languages (object)
   *
   * @returns {Language[]}
   */
  public getEuLanguages(): Ref<Language[]> {
    return this.languagesEu;
  }

  /**
   * Get EXTRA languages (object)
   *
   * @returns {Language[]}
   */
  public getExtraEuLanguages(): Ref<Language[]> {
    return this.languagesExtraEu;
  }

  /**
   * Set all available languages
   *
   * @param {Language[]} languages - Array containing all available languages
   */
  public setLanguages(languages: Language[]): void {
    this.languages.value = this.extraLanguages(languages);

    // if the Language does NOT have an extraEU parameter or if it is TRUE
    this.languagesEu.value = unref(this.languages).filter((language: Language) => {
      return isNil(language.extraEu) || (!isNil(language.extraEu) && language.extraEu !== true);
    });

    // difference by total and eu language
    this.languagesExtraEu.value = difference(unref(this.languages), unref(this.languagesEu));
  }

  /**
   * Remove language from available languages
   *
   * @param {String} languageToRemove - The code of the language to remove
   */
  public removeLanguage(languageToRemove: string): void {
    const languageList: Language[] = this.languages.value;
    const cleanLanguageList: Language[] = filter(
      languageList,
      (language: Language) => language.code !== languageToRemove
    );
    this.setLanguages(cleanLanguageList);
  }

  /**
   * Get all languages code
   *
   * @returns {string[]}
   */
  public getCodes(): string[] {
    return this.languages.value.map(l => l.code);
  }

  /**
   * Retrieve the active language code
   *
   * @returns {string} - language code (like: en, it, ecc..)
   */
  public getCurrentCode(): string {
    return this.locale.value;
  }

  /**
   * Retrieve the active language object
   *
   * @returns {Language} - language object
   */
  public getCurrent(): Language {
    const currentCode: string = this.getCurrentCode();
    return this.getLanguages().value.filter(lang => lang.code === currentCode)[0];
  }

  /**
   * Generate the url for change locale
   *
   * @param {string} languageCode - Language code
   * @returns {string} - the url
   */
  public makeUrlLocaleChange(languageCode: string): string {
    const r = useRouter();
    console.log('rrrrrrrrrrrrrrrrrrr', r);

    return `/${languageCode}`;
  }

  /**
   * Generate the path with language
   *
   * @param { name: RouteRecordName; params?: RouteParamsRaw, query?: LocationQueryRaw; hash?: string; } row - params/query/hash for generate url
   *
   * @return {string} - the url
   */
  public makeUrl(row: {
    name: RouteRecordName;
    params?: RouteParamsRaw;
    query?: LocationQueryRaw;
    hash?: string;
  }): string {
    row.query = assign(assign({ lng: this.getCurrentCode() }, row.query));
    try {
      return router.resolve(row).href.replace(baseUrl, '');
    } catch (error) {
      console.error('@LocaleManager > makeUrl:', error);
      return '';
    }
  }

  /**
   * Generate the path with language by Name
   *
   * @param {string} name - the name of path
   * @param { params?: RouteParamsRaw; query?: LocationQueryRaw; hash?: string } row - params/query/hash for generate url
   *
   * @return {string} - the url
   */
  public makeUrlByName(
    name: string,
    row: { params?: RouteParamsRaw; query?: LocationQueryRaw; hash?: string } = {}
  ): string {
    return this.makeUrl(assign({ name: name }, row));
  }

  /**
   * Called when the locale is changed
   *
   * @param {Language} language - language object
   */
  public performLocaleChange(language: Language, currentRoute: RouteLocationNormalizedLoaded): void {
    let url = baseUrl + `/?lng=${language.code}`;

    if (!isNil(currentRoute)) {
      if (!isNil(language.urlToRoot) && language.urlToRoot === false) {
        url = this.makeUrl(
          merge(currentRoute, {
            query: {
              lng: language.code
            }
          })
        );
      }
    }

    window.location.href = baseUrl + url;
  }
}

export default LocaleManager.getInstance();
