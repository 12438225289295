import { defineComponent, ref, onMounted, onUnmounted, watch, toRefs, nextTick } from 'vue';
import JclHighchartsLoader from '../JclHighchartsLoader/JclHighchartsLoader';
import { each, assign } from 'lodash';

export default defineComponent({
  name: 'JclHighcharts',
  emits: ['updated', 'rendered', 'destroyed'],
  props: {
    type: {
      type: String,
      default: 'chart'
    },
    options: {
      type: Object,
      required: true
    },
    series: {
      type: Object,
      required: true
    },
    redrawOnUpdate: {
      type: Boolean,
      default: false
    },
    oneToOneOnUpdate: {
      type: Boolean,
      default: false
    },
    animateOnUpdate: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const chartRef = ref(null);
    const chart = ref(null);

    const { options, series } = toRefs(props);

    const watches = [];

    /**
     * Reale inizializzazione del componenete e di Highcharts
     */
    const init = () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const Highcharts = (window as any).Highcharts;

      const allowedAttributes = Highcharts?.AST?.allowedAttributes;
      if (allowedAttributes) {
        // Allow Alt attribute
        allowedAttributes.push('alt');
      }

      if (Highcharts && Highcharts[props.type]) {
        watch(
          [options, series],
          ([newOptions, newSeries]) => {
            console.time('JclHighcharts time to render');
            const opts = assign(newOptions, { series: newSeries });
            chart.value = Highcharts[props.type](chartRef.value, opts, () => {
              nextTick(() => {
                emit('rendered');
              });
              console.timeEnd('JclHighcharts time to render');
            });
          },
          {
            immediate: true
          }
        );
      } else if (!props.options) {
        console.warn('The "options" parameter is required.');
      } else {
        console.warn(`${props.type} is not a valid highcharts type or has not been imported`);
      }
    };

    onUnmounted(() => {
      if (chart.value) chart.value.destroy();
      each(watches, _w => _w());
      nextTick(() => {
        emit('destroyed');
      });
    });

    onMounted(() => {
      const loaderHighchart = new JclHighchartsLoader();
      loaderHighchart.load().then(
        () => {
          init();
        },
        error => {
          console.error('JclHighchartsLoader Error:', error);
          throw new Error('JclHighchartsLoader Error: ' + error);
        }
      );
    });

    // Rather than returning a render function here. We'll return the chart ref and highcharts
    // instance so there exposed.
    return {
      chartRef,
      chart
    };
  }
});
