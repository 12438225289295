import { defineComponent } from 'vue';

import iconSprite from '@/assets/udp-sprite-icons.svg';

export default defineComponent({
  name: 'UdpIcon',
  props: {
    name: {
      type: String,
      required: false
    },
    data: {
      type: String,
      required: false
    },
    url: {
      type: String,
      required: false
    },
    class: {
      type: String,
      required: false,
      default: 'ecl-icon ecl-icon--s'
    }
  },
  setup() {
    return {
      iconSprite
    };
  }
});
