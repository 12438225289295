import { defineComponent, SetupContext } from 'vue';
import { EclIcon, EclIconSize } from '../../';
import useCheckbox from './composables/useCheckbox';
import useValue from './composables/useValue';
import useData from './composables/useData';
import useCheck from './composables/useCheck';
import useKeyboard from './composables/useKeyboard';

export type Checkbox = {
  value: string;
  label: string;
  helpText?: string;
  checked?: boolean;
  disabled?: boolean;
  id?: string;
};

export type CheckboxItem = {
  id: string;
  helpText?: string;
  checked: boolean;
  disabled: boolean;
} & Checkbox;

export default defineComponent({
  name: 'EclCheckbox',
  components: {
    EclIcon,
  },
  emits: ['update:modelValue', 'change', 'check', 'uncheck'],
  props: {
    value: {
      required: false,
    },
    modelValue: {
      required: false,
    },
    checkboxes: {
      required: true,
      type: [Array, String, Number, Boolean],
    },
    invalid: {
      required: false,
      type: Boolean,
      default: false,
    },
    required: {
      required: false,
      type: Boolean,
      default: false,
    },
    requiredText: {
      required: false,
      type: String,
      default: '*',
    },
    showOptionalText: {
      required: false,
      type: Boolean,
      default: false,
    },
    optionalText: {
      required: false,
      type: String,
      default: ' (optional)',
    },
    legend: {
      required: false,
      type: String,
    },
    helperText: {
      type: String,
      required: false,
    },
    errorText: {
      type: String,
      required: false,
    },
    valueProp: {
      type: String,
      required: false,
      default: 'value',
    },
  },
  setup(props, context: SetupContext) {
    const cmpCheckbox = useCheckbox(props);
    const cmpValue = useValue(props, context);
    const cmpData = useData(props, context, {
      useValue: cmpValue,
      useCheckbox: cmpCheckbox,
    });
    const cmpCheck = useCheck(props, context, {
      useCheckbox: cmpCheckbox,
      useValue: cmpValue,
      useData: cmpData,
    });
    const cmpKeyboard = useKeyboard({
      useCheck: cmpCheck,
    });

    return {
      ...cmpCheckbox,
      ...cmpData,
      ...cmpCheck,
      ...cmpKeyboard,
      EclIconSize,
    };
  },
});
