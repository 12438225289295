import { toRefs, unref, SetupContext } from 'vue';
import { EclModal } from '..';
import useModal from './useModal';

export default function useActions(
  props: InstanceType<typeof EclModal>['$props'],
  context: SetupContext,
  dependencies: { useModal: ReturnType<typeof useModal> },
) {
  const { backdropClose } = toRefs(props);
  // ================ DATA ================

  // ============ DEPENDENCIES ============
  const modalHide: () => void = dependencies.useModal.modalHide;

  // ============== COMPUTED ==============

  // =============== METHODS ==============
  const backdropClicked = (): void => {
    if (unref(backdropClose)) {
      context.emit('close:backdrop');
      modalHide();
    }
  };

  const performClose = (): void => {
    context.emit('close');
    modalHide();
  };

  // ============== WATCHERS ==============

  return {
    backdropClicked,
    performClose,
  };
}
