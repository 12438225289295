import { ComputedRef, unref } from 'vue';
import { last, isEqual } from 'lodash';
import { ListItem } from '../../types';

export default function useKeyboard(dependencies) {
  // ============ DEPENDENCIES ============

  const extendedList = dependencies.extendedList as ComputedRef<ListItem[]>;
  const close: () => void = dependencies.close;
  const toggle: () => void = dependencies.toggle;
  const handleClickItem: (item: ListItem) => void = dependencies.handleClickItem;

  // =============== METHODS ==============

  const handleSpace = handleClickItem;
  const handleEnter = handleClickItem;

  const handleSpaceWithChild = toggle;
  const handleEnterWithChild = toggle;

  /**
   * Chiude il pannello se è l'ultima item
   * Così se l'utente, con il pannello aperto, usa il tab, il dropdown si chiude automaticamente
   *
   * @param {ExtendedItem} item - item with blur event
   */
  const handleItemBlur = (item: ListItem) => {
    const _el = unref(extendedList);
    if (isEqual(item, last(_el))) {
      close();
    }
  };

  return {
    handleEnter,
    handleSpace,
    handleSpaceWithChild,
    handleEnterWithChild,
    handleItemBlur
  };
}
