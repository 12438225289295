/* eslint-disable @typescript-eslint/camelcase */

import { ComputedRef, unref } from 'vue';
import { isArray, assign } from 'lodash';

import { MapSettings } from '../types';

import useService from './useService';
import { AxiosResponse, AxiosError } from 'axios';
import GeoJSON from 'ol/format/GeoJSON';
import Geometry from 'ol/geom/Geometry';
import Feature from 'ol/Feature';

export default function useWfs(dependencies) {
  // const { level } = toRefs(props);

  // ================ DATA ================
  const { service } = useService();

  // ============ DEPENDENCIES ============
  const settings: ComputedRef<MapSettings> = dependencies.settings;

  // ============== COMPUTED ==============

  // =============== METHODS ==============

  const readGeoJson = (data: string | object | ArrayBuffer | Document | Element): Feature<Geometry>[] => {
    return new GeoJSON().readFeatures(data);
  };

  const getGeometry = (data: string | object | ArrayBuffer | Document | Element | Feature<Geometry>[]): Geometry => {
    let features = data;
    if (!isArray(data) || (isArray(data) && data[0] instanceof Feature === false)) {
      features = readGeoJson(data);
    }
    return features[0].getGeometry();
  };

  /**
   * WFS Geoserver Request
   *
   * @param {string} url - the url
   */
  const request = (options: Record<string, string>): Promise<AxiosResponse | AxiosError> => {
    const params = assign(
      {
        service: 'wfs',
        version: '2.0.0',
        request: 'GetFeature',
        outputFormat: 'application/json',
        srsName: settings.value.WFS.srs
      },
      options
    );

    const urlParams = new URLSearchParams(params);
    const url = new URL(`${unref(settings).WMS.api.basePath + unref(settings).WMS.api.wfs}?${urlParams.toString()}`);

    return new Promise((resolve, reject) => {
      service.get(url.toString()).then(
        success => {
          resolve(success);
        },
        error => {
          console.log('error', error);
          reject(error);
          throw error;
        }
      );
    });
  };

  return {
    request,
    readGeoJson,
    getGeometry
  };
}
