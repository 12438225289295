import { computed, ComputedRef, defineComponent, isRef, PropType, SetupContext, unref } from 'vue';
import { useI18n } from 'vue-i18n';
// import useEcl from './../../AppCurrentInstance';
import { EclIcon, EclIconSize, EclButton, EclButtonIconPosition, EclButtonType } from '../../';
import { makeUUID } from '../../../helpers/random';

import useSelect from './composables/useSelect';
import useDropdown from './composables/useDropdown';
import useKeyboard from './composables/useKeyboard';
import useValue from './composables/useValue';
import useData from './composables/useData';
import useOptions from './composables/useOptions';
import useSearch from './composables/useSearch';
import useVisibility from './composables/useVisibility';
import { EclSelectOption } from './types';

export enum EclSelectSize {
  L = 'l',
  M = 'm',
  S = 's',
  FLUID = 'fluid',
}

export type ModelValueData = string | number;

export type BaseDependencies = {
  UUID: string;
};

export default defineComponent({
  name: 'EclSelect',
  components: {
    EclIcon,
    EclButton,
  },
  emits: ['open', 'close', 'change', 'input', 'update:modelValue', 'select', 'deselect', 'search-change'],
  props: {
    options: {
      type: Array as PropType<EclSelectOption[]>,
      required: true,
    },
    id: {
      type: String,
      default: '',
      required: false,
    },
    modelValue: {
      type: [String, Array, Number] as PropType<ModelValueData | ModelValueData[]>,
      default: () => [] || '',
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    requiredText: {
      required: false,
      type: String,
      default: '*',
    },
    showOptionalText: {
      required: false,
      type: Boolean,
      default: false,
    },
    optionalText: {
      required: false,
      type: String,
      default: () => {
        const { t } = useI18n();
        return t('ECLV3.FORM.OPTIONAL');
      },
    },
    searchable: {
      required: false,
      type: Boolean,
      default: false,
    },
    mode: {
      required: false,
      type: String,
      default: 'single', // single, multiple
    },
    // solo per mode = multiple
    limit: {
      required: false,
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    helperText: {
      type: String,
      required: false,
    },
    size: {
      default: EclSelectSize.FLUID,
      required: false,
      type: String,
      validator: (size: string) => Object.values<string>(EclSelectSize).includes(size),
    },
    invalid: {
      type: Boolean,
      required: false,
      default: false,
    },
    invalidText: {
      required: false,
      type: String,
    },
    multiplePlaceholder: {
      required: false,
      type: String,
    },
    searchLabel: {
      required: false,
      type: String,
      default: () => {
        const { t } = useI18n();
        return t('ECLV3.FORM.SELECT.SEARCH');
      },
    },
    selectAll: {
      required: false,
      type: Boolean,
      default: true,
    },
    selectAllLabel: {
      required: false,
      type: String,
      default: () => {
        const { t } = useI18n();
        return t('ECLV3.FORM.SELECT.ALL');
      },
    },
    deselectAll: {
      required: false,
      type: Boolean,
      default: true,
    },
    deselectAllLabel: {
      required: false,
      type: String,
      default: () => {
        const { t } = useI18n();
        return t('ECLV3.FORM.SELECT.DESELECT_ALL');
      },
    },
    // refactoring delle options interno
    optionLabel: {
      required: false,
      type: String,
      default: 'label',
    },
    // refactoring delle options interno
    optionValue: {
      required: false,
      type: String,
      default: 'value',
    },
    valueProp: {
      required: false,
      type: String,
      default: 'value',
    },
    clearOnSelect: {
      required: false,
      type: Boolean,
      default: true,
    },
    multipleGroupLabel: {
      required: false,
      type: Boolean,
      default: true,
    },
    multipleGroupLimit: {
      required: false,
      type: Number,
      default: 2,
    },
  },
  setup(props, context: SetupContext) {
    // import(`./EclSelect.component.${useEcl().theme}.scss`);

    if (props.modelValue && isRef(props.modelValue)) {
      throw new Error('EclSelect: error on modelValue type. You must provide a reactive not a Ref!');
    }

    const UUID = makeUUID();

    const cpsSelect = useSelect(props, {
      UUID: UUID,
    });

    const cpsValue = useValue(props, context, {
      UUID: UUID,
      useSelect: cpsSelect,
    });

    const cpsData = useData(props, context, {
      useValue: cpsValue,
    });

    const cpsDropdown = useDropdown(props, context, {
      useSelect: cpsSelect,
    });

    const cpsSearch = useSearch(context);

    const cpsOptions = useOptions(props, context, {
      UUID: UUID,
      useData: cpsData,
      useValue: cpsValue,
      useDropdown: cpsDropdown,
      useSearch: cpsSearch,
    });

    const keyboard = useKeyboard({
      useDropdown: cpsDropdown,
      useOptions: cpsOptions,
    });

    const selectAllLabelWithCount: ComputedRef<string | undefined> = computed(() => {
      return `${props.selectAllLabel} (${unref(cpsOptions.extendedOptions).length})`;
    });

    const visibility = useVisibility(props, {
      useSelect: cpsSelect,
      useValue: cpsValue,
      useOptions: cpsOptions,
    });

    return {
      ...cpsSelect,
      ...cpsSearch,
      ...cpsData,
      ...cpsOptions,
      ...cpsDropdown,
      ...keyboard,
      ...cpsValue,
      ...visibility,
      selectAllLabelWithCount,
      EclIconSize,
      EclButtonIconPosition,
      EclButtonType,
    };
  },
});
