import { computed, ref, toRefs, unref } from 'vue';
import { isNil } from 'lodash';
import { LocaleManager } from '@/managers';
import { EclSiteHeaderCore } from '..';

export default function useLanguage(props: InstanceType<typeof EclSiteHeaderCore>['$props']) {
  const { showLanguage: propShowLanguage } = toRefs(props);

  // ================ DATA ================

  const showLanguageList = ref(false);

  // ============ DEPENDENCIES ============

  // ============== COMPUTED ==============
  const currentLocale = computed(() => LocaleManager.getCurrentCode());

  const currentLanguage = computed(() => {
    return LocaleManager.getCurrent();
  });

  const showLanguage = computed((): boolean => {
    const showLang = unref(propShowLanguage);
    if (!isNil(showLang)) {
      return showLang && !isNil(LocaleManager.getCurrent());
    }
    return LocaleManager.getLanguages().value.length > 1 && !isNil(LocaleManager.getCurrent());
  });

  // =============== METHODS ==============

  const toggleLanguageList = () => {
    showLanguageList.value = !showLanguageList.value;
  };

  const renderLanguageList = () => {
    showLanguageList.value = true;
  };

  const destroyLanguageList = () => {
    showLanguageList.value = false;
  };

  // ============== WATCHERS ==============

  return {
    currentLocale,
    showLanguageList,
    showLanguage,
    currentLanguage,
    toggleLanguageList,
    renderLanguageList,
    destroyLanguageList,
  };
}
