import { ref, SetupContext } from 'vue';

export default function useSearch(context: SetupContext) {
  // ================ DATA ================

  const searchIsVisible = ref(false);

  // ============ DEPENDENCIES ============

  // ============== COMPUTED ==============

  // =============== METHODS ==============

  const toggleSearch = () => {
    searchIsVisible.value = !searchIsVisible.value;
  };

  const performSearch = (value: string, event: Event): void => {
    context.emit('performSearch', value, event);
  };

  // ============== WATCHERS ==============

  return {
    searchIsVisible,
    toggleSearch,
    performSearch,
  };
}
