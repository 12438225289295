import { defineComponent, SetupContext } from 'vue';

import './styles';

import useData from './composables/useData';
import useMap from './composables/useMap';
import useTooltip from './composables/useTooltip';

import { EclParagraph, EclModal } from '@/shared/ecl/src/components';
import { MapSettings } from './types';

import proj4 from 'proj4';
import { register } from 'ol/proj/proj4';
import useHelper from './composables/useHelper';

proj4.defs(
  'EPSG:3035',
  '+proj=laea +lat_0=52 +lon_0=10 +x_0=4321000 +y_0=3210000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
);
register(proj4);

export default defineComponent({
  name: 'JclOlMap',
  emits: ['mounted', 'hoverTerritory', 'clickedTerritory', 'wmsUpdatingLayers', 'wmsUpdatedLayers'],
  components: {
    EclModal,
    EclP: EclParagraph
  },
  props: {
    settings: {
      type: Object as () => MapSettings,
      required: true
    },
    center: {
      type: Array as () => Array<number>,
      required: false
    },
    zoom: {
      type: Number,
      required: false
    }
  },
  setup(props, context: SetupContext) {
    const data = useData(props);
    const tooltip = useTooltip({
      mapComponent: data.mapComponent
    });
    const helper = useHelper();
    const map = useMap(
      props,
      {
        helper: helper,
        data: data,
        tooltip: tooltip
      },
      context
    );

    return {
      ...helper,
      ...data,
      ...tooltip,
      ...map,
      helpers: {
        WFS: {
          request: map.WFS.request,
          readGeoJson: map.WFS.readGeoJson,
          getGeometry: map.WFS.getGeometry
        }
      }
    };
  }
});
