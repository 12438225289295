import { ref, toRefs, computed, Ref, ComputedRef, unref } from 'vue';
import { indexOf } from 'lodash';
import { isMobileOrTablet } from '../../../../helpers/detect-device';
import { EclSelect, BaseDependencies } from '../';

// export type UseSelect = {
//   useNative: boolean;
//   eclSelectBlur: HTMLElement[];
//   eclSelectSelect: Ref<HTMLElement | null>;
//   eclSelectInput: Ref<HTMLElement | null>;
//   selectId: ComputedRef<string>;
//   tabindex: ComputedRef<number>;
//   isSearchable: ComputedRef<boolean>;
//   isMultiple: ComputedRef<boolean>;
//   isDisabled: ComputedRef<boolean>;
//   isInvalid: ComputedRef<boolean>;
//   isRequired: ComputedRef<boolean>;
//   modeSingle: ComputedRef<boolean>;
//   addBlurElementRef: (e: HTMLElement) => void;
//   blurInput: () => void;
// };

export default function useSelect(props: InstanceType<typeof EclSelect>['$props'], dependencies: BaseDependencies) {
  const { searchable, mode, disabled, invalid, required } = toRefs(props);

  // ================ DATA ================

  const UUID = dependencies.UUID;
  const eclSelectBlur: HTMLElement[] = [];
  const eclSelectSelect: Ref<HTMLElement | null> = ref(null);
  const eclSelectInput: Ref<HTMLElement | null> = ref(null);
  const useNative: boolean = isMobileOrTablet();

  // ============== COMPUTED ==============

  const selectId: ComputedRef<string> = computed(() => {
    return 'eclselect-' + UUID;
  });

  const tabindex: ComputedRef<number> = computed(() => {
    return unref(searchable) ? -1 : 0;
  });

  const isSearchable: ComputedRef<boolean> = computed(() => {
    return unref(searchable) ?? false;
  });

  const isMultiple: ComputedRef<boolean> = computed(() => {
    return unref(mode) === 'multiple';
  });

  const isDisabled: ComputedRef<boolean> = computed(() => {
    return unref(disabled) ?? false;
  });

  const isInvalid: ComputedRef<boolean> = computed(() => {
    return unref(invalid) ?? false;
  });

  const isRequired: ComputedRef<boolean> = computed(() => {
    return unref(required) ?? false;
  });

  const modeSingle: ComputedRef<boolean> = computed(() => unref(mode) === 'single');

  // =============== METHODS ==============

  const addBlurElementRef = (e: HTMLElement): void => {
    if (e && indexOf(eclSelectBlur, e) < 0) {
      eclSelectBlur.push(e);
    }
  };

  const blurInput = (): void => {
    const element: HTMLElement | null | undefined = unref(eclSelectSelect)?.querySelector('ecl-text-input');
    if (element) {
      element.blur();
    }
  };

  return {
    useNative,
    addBlurElementRef,
    eclSelectBlur,
    eclSelectSelect,
    eclSelectInput,
    selectId,
    tabindex,
    isSearchable,
    blurInput,
    isMultiple,
    isDisabled,
    isInvalid,
    isRequired,
    modeSingle,
  };
}
