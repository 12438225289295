import { defineComponent, reactive, ref, Ref, watch, computed, unref } from 'vue';
import { each, forEach, isNil } from 'lodash';
// import useMap from './composables/useMap';

import { EclModal } from '@/shared/ecl/src/components/EclModal';
import { EclAccordion, EclAccordionItem } from '@/shared/ecl/src/components/EclAccordion';
import { EclCheckbox } from '@/shared/ecl/src/components/Forms';
import { EclLink } from '@/shared/ecl/src/components/Navigation/EclLink';
import { EclButton, EclButtonType } from '@/shared/ecl/src/components';
import JclHighcharts from '@/shared/jcl/src/components/JclHighcharts/JclHighcharts.component.vue';
import JclAutocomplete from '@/shared/jcl/src/components/JclAutocomplete/JclAutocomplete.component.vue';
import EclIcon from '@/shared/ecl/src/components/EclIcon/EclIcon.component.vue';
import { useRoute } from 'vue-router';
import { SEOManager, BreadcrumbManager, LocaleManager } from '@/managers';
import Modal from '@/components/Modal/Modal.component.vue';
import ParagraphMoreLess from '@/components/ParagraphMoreLess/ParagraphMoreLess.component.vue';
import i18n from '@/locale';
import { decodeString } from '../../helpers/string/mutate';
import { store } from '@/store';
import { isMobileOrTablet } from '@/shared/ecl/src/helpers/detect-device';
import { manageTooltip, manageTooltipPDF } from '../../views/App/composables/useGlossary';

export default defineComponent({
  name: 'Home',
  components: {
    EclButton,
    EclAccordion,
    EclAccordionItem,
    EclCheckbox,
    EclModal,
    EclLink,
    JclHighcharts,
    JclAutocomplete,
    EclIcon,
    Modal,
    ParagraphMoreLess,
  },
  setup() {
    console.log('>>>> FINDER');
    const isMobileTablet = computed(() => isMobileOrTablet());
    // BreadcrumbManager.default();
    // const showPopup = ref(false);
    // const route = useRoute();

    const lang = !isNil(LocaleManager) && !isNil(LocaleManager.locale) && !isNil(unref(LocaleManager.locale))
      ? unref(LocaleManager.locale)
      : 'en';
    const l = lang === 'en' ? '' : '_' + lang.toUpperCase();
    /* let RuralToolkit;
    try {
      RuralToolkit = require('@/_static/ruraltoolkit' + l + '.json');
    } catch (ex) {
      RuralToolkit = require('@/_static/ruraltoolkit.json');
    } */

    let valueTypeAct;
    try {
      valueTypeAct = require('@/_static/filter_type_act' + l + '.json');
    } catch (ex) {
      valueTypeAct = require('@/_static/filter_type_act.json');
    }

    let valueKind;
    try {
      valueKind = require('@/_static/filter_kind' + l + '.json');
    } catch (ex) {
      valueKind = require('@/_static/filter_kind.json');
    }

    let valueTypeSupport;
    try {
      valueTypeSupport = require('@/_static/filter_type_support' + l + '.json');
    } catch (ex) {
      valueTypeSupport = require('@/_static/filter_type_support.json');
    }

    const listFund = ref([]);
    listFund.value = store.getters['tool/listFund'];

    const loading: Ref<boolean> = ref(false);
    const showModal = reactive([]);
    // const map = useMap();

    // console.log(i18n.global.t('ECLV3.GENERAL.OPEN'));
    let filtered = [];
    const fiches = ref([]);
    const filterTypeAct = ref([]);
    const filterKind = ref([]);
    const filterTypeSupport = ref([]);
    const colors = ref([]);
    const classBkgColors = ref([]);
    const classTextColors = ref([]);
    const icons = ref([]);
    const grouped = ref([]);
    const typeColor = ref([]);

    // colors.value = ['#7ac5f0', '#6AB976', '#E63736'];
    colors.value = ['#1998E1', '#24A148', '#E63736'];
    classBkgColors.value = ['color1', 'color2', 'color3'];
    classTextColors.value = ['colorT1', 'colorT2', 'colorT3'];
    icons.value = ['RT-asset_v2_Funds-direct-access.svg', 'RT-asset_v2_Funds-authority.svg', 'RT-asset_v2_Financial-instruments.svg'];
    const colorSelect = '#0e47cb';

    const fichesGrouped = ref([]);
    const typeArrays = ref([]);
    const typeArraysDes = ref([]);
    // fiches.value = unref(RuralToolkit);
    // store.commit('tool/funding', unref(RuralToolkit));
    fiches.value = store.getters['tool/funding'];

    filterTypeAct.value = unref(valueTypeAct);
    filterKind.value = unref(valueKind);
    filterTypeSupport.value = unref(valueTypeSupport);

    const filterKindSelected = ref([]);
    const filterKindSelectedDes = ref('');
    const filterTypeActSelected = ref([]);
    const filterTypeSupportSelected = ref([]);

    const listFilterTypeAct = ref([]);
    const listFilterTypeActOrig = ref([]);

    const handleClickFiche = (e, color): void => {
      // showPopup.value = true;
      console.log(e);
      console.log(color);

      store.commit('tool/clickFoId', String(e));
      store.commit('tool/clickFo', !store.getters['tool/clickFo']);
      store.commit('tool/clickFoColor', String(color));
    };

    const filterRurlaToolkit = (): void => {
      console.log('>>>>filterRurlaToolkit');

      let filterTypeAct = [];
      filterTypeAct = store.getters['tool/filterThemFocus'];
      let filterKind = [];
      filterKind = store.getters['tool/filterWho'];
      let filterTypeSupport = [];
      filterTypeSupport = store.getters['tool/filterType'];

      // filtered = RuralToolkit;
      filtered = store.getters['tool/funding'];
      console.log(filtered);

      let isFilter = false;
      // console.log(filterTypeAct);
      if (filterTypeAct.length > 0) {
        isFilter = true;
        filtered = filtered.filter(val => val.FILTER_THEMFOCUS_CODE.toString().split(',').some(r => Object.values(filterTypeAct).includes(r.trim())));
      }
      if (filterKind.length > 0) {
        isFilter = true;
        filtered = filtered.filter(val => val.FILTER_WHO_CODE.toString().split(',').some(r => Object.values(filterKind).includes(r.trim())));
        console.log(filtered);
      }

      if (filterTypeSupport.length > 0) {
        isFilter = true;
        filtered = filtered.filter(val => val.FILTER_TYPEFUND_CODE.toString().split(';').some(r => Object.values(filterTypeSupport).includes(r.trim())));
        // console.log(filtered);
      }

      const chip = document.getElementById('chip');
      if (!isNil(chip)) {
        chip.style.display = isFilter ? 'block' : 'none';
      }
      const colChips = document.getElementById('col-chips');

      if (!isNil(colChips)) {
        colChips.style.display = isFilter ? 'block' : 'none';
      }

      // typeArrays.value.length = 0;

      fiches.value = unref(filtered);
      let index = 0;
      fiches.value.forEach(function(f) {
        if (typeArrays.value.find(a => a === f.FILTER_TYPOLOGY_CODE) === undefined) {
          typeArrays.value.push(f.FILTER_TYPOLOGY_CODE);
          typeArraysDes.value.push(f.FILTER_TYPOLOGY);
          typeColor.value.push({ type: f.FILTER_TYPOLOGY_CODE, bkgColor: classBkgColors.value[index], textColor: classTextColors.value[index], indexColor: index });
          index += 1;
        }
      });

      store.commit('tool/typeColor', typeColor);
      console.log('>>typeArray');
      console.log(typeArrays.value);

      for (const t in typeArrays.value) {
        fichesGrouped.value[t] = [];
      }
      for (const f in filtered) {
        for (const t in typeArrays.value) {
          if (filtered[f].FILTER_TYPOLOGY_CODE === typeArrays.value[t]) {
            const shortTitle = listFund.value.find(element => element.id === String(filtered[f].FUNDID));

            filtered[f].FICHE_SHORTTITLE_FUND = isNil(shortTitle) ? filtered[f].FICHE_SHORTTITLE : shortTitle.value;
            filtered[f].FICHE_LONGTITLE_FUND = isNil(shortTitle) ? (filtered[f].FICHE_LONGTITLE === filtered[f].FICHE_SHORTTITLE ? null : filtered[f].FICHE_LONGTITLE) : shortTitle.value1;
            fichesGrouped.value[t].push(filtered[f]);
            break;
          }
        }
      }
      console.log('>>>fichesGrouped');
      console.log(fichesGrouped.value);

      typeArraysDes.value[2] = manageTooltip(typeArraysDes.value[2])[0];
    };

    store.commit('tool/filterThemFocus', []);
    store.commit('tool/filterWho', []);
    store.commit('tool/filterType', []);
    filterRurlaToolkit();

    const changeSelectedFilterTypeAct = (id): void => {
      const f = store.getters['tool/filterThemFocus'];
      if (f.length !== 0) {
        // document.getElementById(f).style.backgroundColor = '';
        // document.getElementById(f + '_check').style.display = 'none';
      }
      const index = f.indexOf(id);
      if (index !== -1) {
        const c = document.getElementById('ecl-checkbox__checkbox-' + id) as HTMLInputElement | null;
        c.checked = false;
        c.click();

        f.splice(index, 1);
        // document.getElementById('TA' + id).style.backgroundColor = '';
        // document.getElementById('TA' + id).style.color = '';
      } else {
        f.push(id);
        document.getElementById('TA' + id).style.backgroundColor = colorSelect;
        document.getElementById('TA' + id).style.color = 'white';
      }

      store.commit('tool/filterThemFocus', isNil(id) ? [] : f);
      filterTypeActSelected.value = f;
      // document.getElementById(idImg).style.display = 'block';

      filterRurlaToolkit();
    };

    // FILTER_WHO
    const listFilterKind = ref([]);

    // filterKind
    each(filterKind.value, w => {
      const keys = ['id', 'value'];
      const obj = {};

      for (let k = 0; k < keys.length; k++) {
        obj[keys[k]] = k === 0 ? w.CODE.toString() : w.DES;
      }
      listFilterKind.value.push(obj);
      listFilterKind.value.sort((a, b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0));
    });

    const changeSelectedFilterKind = (id): void => {
      console.log('>>>>>FilterKind>>>>>');
      console.log(id);

      const f = store.getters['tool/filterWho'];

      /* if (f.length !== 0) {
        document.getElementById('K' + f).style.backgroundColor = '';
        document.getElementById('K' + f).style.color = '';
      } */
      const index = f.indexOf(id);
      if (index !== -1) {
        f.value = [];
        // document.getElementById('K' + id).style.backgroundColor = '';
        // document.getElementById('K' + id).style.color = '';
        const radio = document.getElementById('K' + id) as HTMLInputElement | null;
        radio.checked = false;
      } else {
        f.value = [id];
        // document.getElementById('K' + id).style.backgroundColor = colorSelect;
        // document.getElementById('K' + id).style.color = 'white';
      }
      filterKindSelected.value = f.value;

      filterKindSelectedDes.value = listFilterKind.value.find(elemento => elemento.id === filterKindSelected.value[0]);

      store.commit('tool/filterWho', isNil(id) ? [] : f.value);
      // document.getElementById(id).style.backgroundColor = colorSelect;
      // document.getElementById(idImg).style.display = 'block';
      filterRurlaToolkit();
    };

    const changeSelectedFilterTypeSupport = (id, madeFilter = true): void => {
      const f = store.getters['tool/filterType'];
      /* if (f.length !== 0) {
        document.getElementById('T' + f).style.backgroundColor = '';
        document.getElementById('T' + f).style.color = '';
      } */
      const index = f.indexOf(id);
      if (index !== -1) {
        f.value = [];
        // document.getElementById('T' + id).style.backgroundColor = '';
        // document.getElementById('T' + id).style.color = '';
        const radio = document.getElementById('T' + id) as HTMLInputElement | null;
        radio.checked = false;
      } else {
        f.value = [id];
        // document.getElementById('T' + id).style.backgroundColor = colorSelect;
        // document.getElementById('T' + id).style.color = 'white';
      }
      filterTypeSupportSelected.value = f.value;
      store.commit('tool/filterType', isNil(id) ? [] : f.value);
      // document.getElementById(id).style.backgroundColor = colorSelect;
      // document.getElementById(idImg).style.display = 'block';
      if (madeFilter) {
        filterRurlaToolkit();
      }
    };

    const resetFilterSelected = (type, filter): void => {
      switch (type) {
        case 'K':
          changeSelectedFilterKind(filter);
          break;
        case 'TA':
          changeSelectedFilterTypeAct(filter);
          break;
        case 'T':
          changeSelectedFilterTypeSupport(filter);
          break;
        default:
      };
    };

    each(filterTypeAct.value, w => {
      const keys = ['id', 'value'];
      const obj = {};

      for (let k = 0; k < keys.length; k++) {
        obj[keys[k]] = k === 0 ? w.CODE.toString() : w.DES;
      }
      listFilterTypeActOrig.value.push(obj);
      listFilterTypeAct.value.push(obj);
      listFilterTypeAct.value.sort((a, b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0));
    });

    const listFilterTypeActCat = ref([]);

    each(listFilterTypeAct.value, f => {
      // console.log(f.id);
      const cat = filterTypeAct.value.find(x => x.CODE.toString() === f.id);
      if (!isNil(cat)) {
        f.cat = cat.CAT;
        listFilterTypeActCat.value.push(f);
      } else {
        console.log(f.id);
      }
    });

    listFilterTypeActCat.value.sort((a, b) => (a.cat > b.cat) ? 1 : ((b.cat > a.cat) ? -1 : 0));

    let tmp = '';
    each(listFilterTypeActCat.value, f => {
      if (f.cat === tmp) {
        // f.cat = '';
      } else {
        tmp = f.cat;
      }
    });

    listFilterTypeAct.value = listFilterTypeActCat.value;

    const checkbox = ref([]);
    const listCheckboxes = ref([]);
    const listCheckboxesCat = ref([]);
    const listCheckbox = ref([]);
    tmp = listFilterTypeAct.value[0].cat;
    listCheckboxesCat.value.push(tmp);

    each(listFilterTypeAct.value, f => {
      if (f.cat === tmp) {
        listCheckbox.value.push({
          value: f.id,
          label: f.value,
          checked: false,
          disabled: false,
        });
      } else {
        listCheckboxes.value.push(listCheckbox.value);
        listCheckbox.value = [];
        listCheckbox.value.push({
          value: f.id,
          label: f.value,
          checked: false,
          disabled: false,
        });
        checkbox.value.push([]);
        tmp = f.cat;
        listCheckboxesCat.value.push(tmp);
      }
    });
    listCheckboxes.value.push(listCheckbox.value);
    checkbox.value.push([]);

    const changeSelectedFilterTypeActCheck = (ids): void => {
      store.commit('tool/filterThemFocus', isNil(ids) ? [] : ids);
      filterTypeActSelected.value = ids;
      filterRurlaToolkit();
    };
    watch(() => checkbox.value[0], function() {
      const concatenatedArray = checkbox.value[0].concat(checkbox.value[1], checkbox.value[2], checkbox.value[3], checkbox.value[4]);
      changeSelectedFilterTypeActCheck(concatenatedArray);
    });
    watch(() => checkbox.value[1], function() {
      const concatenatedArray = checkbox.value[0].concat(checkbox.value[1], checkbox.value[2], checkbox.value[3], checkbox.value[4]);
      changeSelectedFilterTypeActCheck(concatenatedArray);
    });
    watch(() => checkbox.value[2], function() {
      const concatenatedArray = checkbox.value[0].concat(checkbox.value[1], checkbox.value[2], checkbox.value[3], checkbox.value[4]);
      changeSelectedFilterTypeActCheck(concatenatedArray);
    });
    watch(() => checkbox.value[3], function() {
      const concatenatedArray = checkbox.value[0].concat(checkbox.value[1], checkbox.value[2], checkbox.value[3], checkbox.value[4]);
      changeSelectedFilterTypeActCheck(concatenatedArray);
    });
    watch(() => checkbox.value[4], function() {
      const concatenatedArray = checkbox.value[0].concat(checkbox.value[1], checkbox.value[2], checkbox.value[3], checkbox.value[4]);
      changeSelectedFilterTypeActCheck(concatenatedArray);
    });
    // FILTER_TYPEFUND
    console.log('>>>FILTER_TYPEFUND');
    const listFilterTypeSupport = ref([]);
    const listFilterTypeSupportOrig = ref([]);
    // filterType

    each(filterTypeSupport.value, w => {
      const keys = ['id', 'value', 'valueOrig'];
      const obj = {};

      for (let k = 0; k < keys.length; k++) {
        obj[keys[k]] = k === 0 ? w.CODE.toString() : k === 1 ? manageTooltip(w.DES)[0] : manageTooltipPDF(w.DES);
      }

      listFilterTypeSupport.value.push(obj);

      listFilterTypeSupportOrig.value.push(obj);
      listFilterTypeSupport.value.sort((a, b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0));
    });
    /* setTimeout(() => {
      if (store.getters['tool/filterType'].length > 0) {
        changeSelectedFilterType(store.getters['tool/filterType'][0], false);
      }
    }, 1); */

    const resetFilters = (): void => {
      const t = store.getters['tool/filterThemFocus'];
      t.forEach(id => {
        const c = document.getElementById('ecl-checkbox__checkbox-' + id) as HTMLInputElement | null;
        c.checked = false;
        c.click();
      });

      const w = store.getters['tool/filterWho'];
      w.forEach(id => {
        const radio = document.getElementById('K' + id) as HTMLInputElement | null;
        radio.checked = false;
      });

      const ty = store.getters['tool/filterType'];
      ty.forEach(id => {
        const radio = document.getElementById('T' + id) as HTMLInputElement | null;
        radio.checked = false;
      });

      store.commit('tool/filterThemFocus', []);
      store.commit('tool/filterWho', []);
      store.commit('tool/filterType', []);
      filterKindSelected.value = [];
      filterTypeActSelected.value = [];
      filterTypeSupportSelected.value = [];
      checkbox.value[0] = [];
      checkbox.value[1] = [];
      checkbox.value[2] = [];
      checkbox.value[3] = [];

      filterRurlaToolkit();
      document.getElementById('chip').style.display = 'none';
    };

    const performOpenModal = index => {
      showModal[index] = true;
    };

    const accordionItemToggle = (): void => {
      document.getElementById('financialOpp').scrollIntoView();
    };

    const finderKind = ref('');
    finderKind.value = decodeString(i18n.global.t('finder.kind'));
    const finderTA = ref('');
    finderTA.value = decodeString(i18n.global.t('finder.typeActivities'));
    const finderTS = ref('');
    finderTS.value = decodeString(i18n.global.t('finder.typeSupport'));
    const finderFO = ref('');
    finderFO.value = decodeString(i18n.global.t('finder.financialOpp'));
    const finderTaylor = ref('');
    finderTaylor.value = decodeString(i18n.global.t('finder.tailor'));
    const finderTitle = ref('');
    finderTitle.value = decodeString(i18n.global.t('finder.title'));
    const finderOne = ref('');
    finderOne.value = decodeString(i18n.global.t('finder.oneOption'));
    const finderAll = ref('');
    finderAll.value = decodeString(i18n.global.t('finder.allOption'));
    const finderResetF = ref('');
    finderResetF.value = decodeString(i18n.global.t('reset.filters'));

    const showTooltip = ref(false);
    let n;
    n = 1;
    document.addEventListener('click', () => {
      if (n > 1) {
        const tooltips = document.querySelectorAll('.popper');
        tooltips.forEach(function(tooltip) {
          tooltip.parentNode.removeChild(tooltip);
        });
        showTooltip.value = true;
      }
      n += 1;
    });

    setTimeout(() => {
      const elementsWithTooltip = document.querySelectorAll('[id^="fiche"]');

      elementsWithTooltip.forEach((element) => {
        const tooltip = document.querySelector('#tooltip-' + element.id) as HTMLElement;
        if (!isNil(tooltip)) {
          element.addEventListener('mouseover', function() {
            tooltip.style.display = 'block';
          });

          element.addEventListener('mouseout', function() {
            tooltip.style.display = 'none';
          });
        }
      });
    });

    return {
      showTooltip,
      finderKind,
      finderTA,
      finderTS,
      finderFO,
      finderTaylor,
      finderTitle,
      finderOne,
      finderAll,
      finderResetF,
      loading,
      accordionItemToggle,
      // ...map,
      performOpenModal,
      showModal,
      EclButtonType,
      listFilterTypeAct,
      listFilterTypeActOrig,
      listFilterTypeActCat,
      filterTypeActSelected,
      listFilterKind,
      filterKindSelected,
      filterKindSelectedDes,
      listFilterTypeSupport,
      listFilterTypeSupportOrig,
      filterTypeSupportSelected,
      // RuralToolkit,
      fiches,
      changeSelectedFilterTypeAct,
      changeSelectedFilterKind,
      changeSelectedFilterTypeSupport,
      handleClickFiche,
      resetFilters,
      resetFilterSelected,
      fichesGrouped,
      typeArrays,
      typeArraysDes,
      grouped,
      colors,
      icons,
      // showPopup,
      classBkgColors,
      classTextColors,
      isMobileTablet,
      checkbox,
      listCheckboxes,
      listCheckboxesCat
    };
    // return await promise;
  }
});
