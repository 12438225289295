import { useQuery } from '@vue/apollo-composable';
import QUERY_SITE_LANGUAGE_LIST from './graphql/SiteLanguageList.query.gql';
import QUERY_LOCALE_INTERFACE from './graphql/LocaleInterface.query.gql';
import { Language } from '@/managers/LocaleManager';
import { appConfigs } from '@/configs';

export interface SiteLanguageListResponse {
  site: {
    languageList: Language[];
  };
}

// type labelsType = string | number | object; // Record<string, string | Record<string, string>>;
export interface InterfaceResponse {
  interface: {
    labels: object;
  };
}

class LocaleClient {
  static languageList() {
    const { result, onResult, onError } = useQuery<SiteLanguageListResponse>(QUERY_SITE_LANGUAGE_LIST, {
      site: appConfigs.api.params.language
    });

    return {
      result,
      onResult,
      onError
    };
  }

  static languageInterface() {
    const { result, onResult, onError } = useQuery<InterfaceResponse>(QUERY_LOCALE_INTERFACE, {
      site: appConfigs.api.params.interface
    });

    return {
      result,
      onResult,
      onError
    };
  }
}

export default LocaleClient;
