import { defineComponent, provide, ref, Ref } from 'vue';

export type AccordionData = {
  // numero di item processati
  itemCount: Ref<number>;
  // indice dell'item attivo
  itemActive: Ref<number[]>;
  activate: (index: number) => void;
  deactivate: (index: number) => void;
};

export default defineComponent({
  name: 'EclAccordion',
  props: {
    onlyOneExpanded: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props) {
    const data: AccordionData = {
      itemCount: ref(0),
      itemActive: ref([]),
      activate: (index: number): void => {
        if (props.onlyOneExpanded) {
          data.itemActive.value = [index];
        }
        data.itemActive.value.push(index);
      },
      deactivate: (index: number): void => {
        if (props.onlyOneExpanded) {
          data.itemActive.value = [];
        }
        data.itemActive.value = data.itemActive.value.filter((number: number) => number !== index);
      },
    };
    provide('accordion', data);

    return {
      data,
    };
  },
});
