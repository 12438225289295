import logoMute from '@ecl/preset-eu/dist/images/logo/logo-eu--mute.svg';

import logoNegativeAr from '@ecl/preset-eu/dist/images/logo/standard-version/negative/logo-eu--ar.svg';
import logoNegativeBg from '@ecl/preset-eu/dist/images/logo/standard-version/negative/logo-eu--bg.svg';
import logoNegativeCa from '@ecl/preset-eu/dist/images/logo/standard-version/negative/logo-eu--ca.svg';
import logoNegativeCs from '@ecl/preset-eu/dist/images/logo/standard-version/negative/logo-eu--cs.svg';
import logoNegativeDa from '@ecl/preset-eu/dist/images/logo/standard-version/negative/logo-eu--da.svg';
import logoNegativeDe from '@ecl/preset-eu/dist/images/logo/standard-version/negative/logo-eu--de.svg';
import logoNegativeEl from '@ecl/preset-eu/dist/images/logo/standard-version/negative/logo-eu--el.svg';
import logoNegativeEn from '@ecl/preset-eu/dist/images/logo/standard-version/negative/logo-eu--en.svg';
import logoNegativeEs from '@ecl/preset-eu/dist/images/logo/standard-version/negative/logo-eu--es.svg';
import logoNegativeEt from '@ecl/preset-eu/dist/images/logo/standard-version/negative/logo-eu--et.svg';
import logoNegativeFi from '@ecl/preset-eu/dist/images/logo/standard-version/negative/logo-eu--fi.svg';
import logoNegativeFr from '@ecl/preset-eu/dist/images/logo/standard-version/negative/logo-eu--fr.svg';
import logoNegativeGa from '@ecl/preset-eu/dist/images/logo/standard-version/negative/logo-eu--ga.svg';
import logoNegativeHr from '@ecl/preset-eu/dist/images/logo/standard-version/negative/logo-eu--hr.svg';
import logoNegativeHu from '@ecl/preset-eu/dist/images/logo/standard-version/negative/logo-eu--hu.svg';
import logoNegativeIs from '@ecl/preset-eu/dist/images/logo/standard-version/negative/logo-eu--is.svg';
import logoNegativeIt from '@ecl/preset-eu/dist/images/logo/standard-version/negative/logo-eu--it.svg';
import logoNegativeJp from '@ecl/preset-eu/dist/images/logo/standard-version/negative/logo-eu--jp.svg';
import logoNegativeLb from '@ecl/preset-eu/dist/images/logo/standard-version/negative/logo-eu--lb.svg';
import logoNegativeLt from '@ecl/preset-eu/dist/images/logo/standard-version/negative/logo-eu--lt.svg';
import logoNegativeLv from '@ecl/preset-eu/dist/images/logo/standard-version/negative/logo-eu--lv.svg';
import logoNegativeMt from '@ecl/preset-eu/dist/images/logo/standard-version/negative/logo-eu--mt.svg';
import logoNegativeNl from '@ecl/preset-eu/dist/images/logo/standard-version/negative/logo-eu--nl.svg';
import logoNegativeNo from '@ecl/preset-eu/dist/images/logo/standard-version/negative/logo-eu--no.svg';
import logoNegativePl from '@ecl/preset-eu/dist/images/logo/standard-version/negative/logo-eu--pl.svg';
import logoNegativePt from '@ecl/preset-eu/dist/images/logo/standard-version/negative/logo-eu--pt.svg';
import logoNegativeRo from '@ecl/preset-eu/dist/images/logo/standard-version/negative/logo-eu--ro.svg';
import logoNegativeRu from '@ecl/preset-eu/dist/images/logo/standard-version/negative/logo-eu--ru.svg';
import logoNegativeSk from '@ecl/preset-eu/dist/images/logo/standard-version/negative/logo-eu--sk.svg';
import logoNegativeSl from '@ecl/preset-eu/dist/images/logo/standard-version/negative/logo-eu--sl.svg';
import logoNegativeSv from '@ecl/preset-eu/dist/images/logo/standard-version/negative/logo-eu--sv.svg';
import logoNegativeSw from '@ecl/preset-eu/dist/images/logo/standard-version/negative/logo-eu--sw.svg';
import logoNegativeTr from '@ecl/preset-eu/dist/images/logo/standard-version/negative/logo-eu--tr.svg';
import logoNegativeUk from '@ecl/preset-eu/dist/images/logo/standard-version/negative/logo-eu--uk.svg';
import logoNegativeZh from '@ecl/preset-eu/dist/images/logo/standard-version/negative/logo-eu--zh.svg';

import logoPositiveAr from '@ecl/preset-eu/dist/images/logo/standard-version/positive/logo-eu--ar.svg';
import logoPositiveBg from '@ecl/preset-eu/dist/images/logo/standard-version/positive/logo-eu--bg.svg';
import logoPositiveCa from '@ecl/preset-eu/dist/images/logo/standard-version/positive/logo-eu--ca.svg';
import logoPositiveCs from '@ecl/preset-eu/dist/images/logo/standard-version/positive/logo-eu--cs.svg';
import logoPositiveDa from '@ecl/preset-eu/dist/images/logo/standard-version/positive/logo-eu--da.svg';
import logoPositiveDe from '@ecl/preset-eu/dist/images/logo/standard-version/positive/logo-eu--de.svg';
import logoPositiveEl from '@ecl/preset-eu/dist/images/logo/standard-version/positive/logo-eu--el.svg';
import logoPositiveEn from '@ecl/preset-eu/dist/images/logo/standard-version/positive/logo-eu--en.svg';
import logoPositiveEs from '@ecl/preset-eu/dist/images/logo/standard-version/positive/logo-eu--es.svg';
import logoPositiveEt from '@ecl/preset-eu/dist/images/logo/standard-version/positive/logo-eu--et.svg';
import logoPositiveFi from '@ecl/preset-eu/dist/images/logo/standard-version/positive/logo-eu--fi.svg';
import logoPositiveFr from '@ecl/preset-eu/dist/images/logo/standard-version/positive/logo-eu--fr.svg';
import logoPositiveGa from '@ecl/preset-eu/dist/images/logo/standard-version/positive/logo-eu--ga.svg';
import logoPositiveHr from '@ecl/preset-eu/dist/images/logo/standard-version/positive/logo-eu--hr.svg';
import logoPositiveHu from '@ecl/preset-eu/dist/images/logo/standard-version/positive/logo-eu--hu.svg';
import logoPositiveIs from '@ecl/preset-eu/dist/images/logo/standard-version/positive/logo-eu--is.svg';
import logoPositiveIt from '@ecl/preset-eu/dist/images/logo/standard-version/positive/logo-eu--it.svg';
import logoPositiveJp from '@ecl/preset-eu/dist/images/logo/standard-version/positive/logo-eu--jp.svg';
import logoPositiveLb from '@ecl/preset-eu/dist/images/logo/standard-version/positive/logo-eu--lb.svg';
import logoPositiveLt from '@ecl/preset-eu/dist/images/logo/standard-version/positive/logo-eu--lt.svg';
import logoPositiveLv from '@ecl/preset-eu/dist/images/logo/standard-version/positive/logo-eu--lv.svg';
import logoPositiveMt from '@ecl/preset-eu/dist/images/logo/standard-version/positive/logo-eu--mt.svg';
import logoPositiveNl from '@ecl/preset-eu/dist/images/logo/standard-version/positive/logo-eu--nl.svg';
import logoPositiveNo from '@ecl/preset-eu/dist/images/logo/standard-version/positive/logo-eu--no.svg';
import logoPositivePl from '@ecl/preset-eu/dist/images/logo/standard-version/positive/logo-eu--pl.svg';
import logoPositivePt from '@ecl/preset-eu/dist/images/logo/standard-version/positive/logo-eu--pt.svg';
import logoPositiveRo from '@ecl/preset-eu/dist/images/logo/standard-version/positive/logo-eu--ro.svg';
import logoPositiveRu from '@ecl/preset-eu/dist/images/logo/standard-version/positive/logo-eu--ru.svg';
import logoPositiveSk from '@ecl/preset-eu/dist/images/logo/standard-version/positive/logo-eu--sk.svg';
import logoPositiveSl from '@ecl/preset-eu/dist/images/logo/standard-version/positive/logo-eu--sl.svg';
import logoPositiveSv from '@ecl/preset-eu/dist/images/logo/standard-version/positive/logo-eu--sv.svg';
import logoPositiveSw from '@ecl/preset-eu/dist/images/logo/standard-version/positive/logo-eu--sw.svg';
import logoPositiveTr from '@ecl/preset-eu/dist/images/logo/standard-version/positive/logo-eu--tr.svg';
import logoPositiveUk from '@ecl/preset-eu/dist/images/logo/standard-version/positive/logo-eu--uk.svg';
import logoPositiveZh from '@ecl/preset-eu/dist/images/logo/standard-version/positive/logo-eu--zh.svg';

type Logo = Record<string, { positive: string; negative: string }>;

const logoEu: Logo & {
  get: (code: keyof Logo, type?: keyof Logo['string']) => keyof Logo['string']['positive' | 'negative'];
} = {
  mute: {
    positive: logoMute as string,
    negative: logoMute as string,
  },
  ar: {
    positive: logoPositiveAr as string,
    negative: logoNegativeAr as string,
  },
  bg: {
    positive: logoPositiveBg as string,
    negative: logoNegativeBg as string,
  },
  ca: {
    positive: logoPositiveCa as string,
    negative: logoNegativeCa as string,
  },
  cs: {
    positive: logoPositiveCs as string,
    negative: logoNegativeCs as string,
  },
  da: {
    positive: logoPositiveDa as string,
    negative: logoNegativeDa as string,
  },
  de: {
    positive: logoPositiveDe as string,
    negative: logoNegativeDe as string,
  },
  el: {
    positive: logoPositiveEl as string,
    negative: logoNegativeEl as string,
  },
  en: {
    positive: logoPositiveEn as string,
    negative: logoNegativeEn as string,
  },
  es: {
    positive: logoPositiveEs as string,
    negative: logoNegativeEs as string,
  },
  et: {
    positive: logoPositiveEt as string,
    negative: logoNegativeEt as string,
  },
  fi: {
    positive: logoPositiveFi as string,
    negative: logoNegativeFi as string,
  },
  fr: {
    positive: logoPositiveFr as string,
    negative: logoNegativeFr as string,
  },
  ga: {
    positive: logoPositiveGa as string,
    negative: logoNegativeGa as string,
  },
  hr: {
    positive: logoPositiveHr as string,
    negative: logoNegativeHr as string,
  },
  hu: {
    positive: logoPositiveHu as string,
    negative: logoNegativeHu as string,
  },
  is: {
    positive: logoPositiveIs as string,
    negative: logoNegativeIs as string,
  },
  it: {
    positive: logoPositiveIt as string,
    negative: logoNegativeIt as string,
  },
  jp: {
    positive: logoPositiveJp as string,
    negative: logoNegativeJp as string,
  },
  lb: {
    positive: logoPositiveLb as string,
    negative: logoNegativeLb as string,
  },
  lt: {
    positive: logoPositiveLt as string,
    negative: logoNegativeLt as string,
  },
  lv: {
    positive: logoPositiveLv as string,
    negative: logoNegativeLv as string,
  },
  mt: {
    positive: logoPositiveMt as string,
    negative: logoNegativeMt as string,
  },
  nl: {
    positive: logoPositiveNl as string,
    negative: logoNegativeNl as string,
  },
  no: {
    positive: logoPositiveNo as string,
    negative: logoNegativeNo as string,
  },
  pl: {
    positive: logoPositivePl as string,
    negative: logoNegativePl as string,
  },
  pt: {
    positive: logoPositivePt as string,
    negative: logoNegativePt as string,
  },
  ro: {
    positive: logoPositiveRo as string,
    negative: logoNegativeRo as string,
  },
  ru: {
    positive: logoPositiveRu as string,
    negative: logoNegativeRu as string,
  },
  sk: {
    positive: logoPositiveSk as string,
    negative: logoNegativeSk as string,
  },
  sl: {
    positive: logoPositiveSl as string,
    negative: logoNegativeSl as string,
  },
  sv: {
    positive: logoPositiveSv as string,
    negative: logoNegativeSv as string,
  },
  sw: {
    positive: logoPositiveSw as string,
    negative: logoNegativeSw as string,
  },
  tr: {
    positive: logoPositiveTr as string,
    negative: logoNegativeTr as string,
  },
  uk: {
    positive: logoPositiveUk as string,
    negative: logoNegativeUk as string,
  },
  zh: {
    positive: logoPositiveZh as string,
    negative: logoNegativeZh as string,
  },
  // @ts-ignore
  get: (code, type = 'negative') => {
    if (code in logoEu) {
      return logoEu[code][type];
    }
    return logoEu.mute.negative;
  },
};

export { logoEu };
