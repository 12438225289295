import { defineComponent } from 'vue';
import { EclBreadcrumb, EclBreadcrumbItem } from '../..';
import { baseClassName } from '../../../helpers/helpers';

export enum EclPageHeaderType {
  Core = 'core',
  Harmonised = 'harmonised',
  Standardised = 'standardised',
}

export default defineComponent({
  name: 'EclPageHeader',
  components: {
    EclBreadcrumb,
  },
  props: {
    type: {
      type: String,
      required: false,
      default: EclPageHeaderType.Core,
    },
    metaInfo: {
      type: String,
      required: false,
    },
    pageTitle: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    backgroundImage: {
      type: String,
      required: false,
      // default: null
    },
    breadcrumbList: {
      type: Array as () => EclBreadcrumbItem[],
      required: false,
    },
    breadcrumbEllipsisLimit: EclBreadcrumb.props.ellipsisLimit,
  },
  setup(props) {
    // let breadcrumbItems: EclBreadcrumbItem[] = [];
    // if (props.breadcrumb && props.breadcrumb.length > 0) {
    //   breadcrumbItems = props.breadcrumb as EclBreadcrumbItem[];
    // } else {
    //   breadcrumbItems = inject('breadcrumb') as EclBreadcrumbItem[];
    // }
    const className = (suffix?: string): string => {
      return baseClassName('ecl-page-header', props.type, suffix);
    };
    const classes = [className()];
    if (props.backgroundImage) {
      classes.push(className('--image'));
    }
    return {
      classes: classes.join(' '),
      // breadcrumbItems,
      className,
    };
  },
});
