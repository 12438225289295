import { createVNode as _createVNode, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "ecl-map",
  ref: "mapComponent"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", {
      class: "ecl-map__map",
      ref: "mapElement",
      "data-finger-alert": _ctx.settings?.useDoubleTouchLabel
    }, null, 8, ["data-finger-alert"]),
    (_ctx.tooltipShow)
      ? _renderSlot(_ctx.$slots, "tooltip", {
          key: 0,
          data: _ctx.tooltipData.dataObject,
          text: _ctx.tooltipData.text,
          style: _ctx.tooltipStyle
        }, () => [
          _createVNode("div", {
            class: "ecl-map__tooltip",
            style: _ctx.tooltipStyle,
            innerHTML: _ctx.tooltipData.text
          }, null, 12, ["innerHTML"])
        ])
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "helper", {
      visible: _ctx.helperVisible,
      message: _ctx.helperMessage
    }, () => [
      _createVNode("div", {
        class: "ecl-map__helper",
        "aria-hidden": !_ctx.helperVisible
      }, [
        _createVNode("span", { innerHTML: _ctx.helperMessage }, null, 8, ["innerHTML"])
      ], 8, ["aria-hidden"])
    ])
  ], 512))
}