import { EclBreadcrumbItem } from '@/shared/ecl/src/components';
import { computed } from 'vue';
import { LocaleManager } from '@/managers';

interface BreadcrumbConfigs {
  default: EclBreadcrumbItem[];
}

const breadcrumbConfigs: BreadcrumbConfigs = {
  default: []
};
export default breadcrumbConfigs;
