import { Router, useRouter } from 'vue-router';
import { isNil, find } from 'lodash';

import { LocaleManager } from '@/managers';

export interface VueRoute {
  convertToRouteOrUrl(to: string): string;
}

export class VueRoute implements VueRoute {
  public _DEBUG = false;
  public router: Router;

  constructor(router?: Router) {
    this.router = router || useRouter();
  }

  private getQueryParams = query => {
    const params = {};
    new URLSearchParams(query).forEach((value, key) => {
      let decodedKey = decodeURIComponent(key);
      const decodedValue = decodeURIComponent(value);
      // This key is part of an array
      if (decodedKey.endsWith('[]')) {
        decodedKey = decodedKey.replace('[]', '');
        params[decodedKey] || (params[decodedKey] = []);
        params[decodedKey].push(decodedValue);
        // Just a regular parameter
      } else {
        params[decodedKey] = decodedValue;
      }
    });

    return params;
  };

  /**
   * Genera la url finale
   * Se è link esterno (senza http), viene inclusa la base path del progetto (url assoluta)
   * altrimenti viene restituita la url relativa con lo / iniziale
   *
   * @param {string} to - il parametro da verificare
   *
   * @returns {string}
   */
  public convertToRouteOrUrl(to: string): string {
    const routes = this.router.getRoutes();
    if (!isNil(to) && /((http(s?)):\/\/)/gi.test(to) === false) {
      const toSplitParams = to.split('?');
      const toName = toSplitParams[0];
      const query = toSplitParams.length > 1 ? this.getQueryParams(toSplitParams[1]) : {};
      const foundedRouteOnRouting = find(routes, route => route.name === toName);
      if (!isNil(foundedRouteOnRouting)) {
        return LocaleManager.makeUrlByName(toName, { query: query });
      } else {
        const finalPath = `/${to}`.replace(/\/\/+/g, '/');
        return `${window.location.origin}${finalPath}`;
      }
    }
    return to;
  }
}
