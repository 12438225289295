import { ref, computed, unref, Ref, ComputedRef } from 'vue';
import { isNil } from 'lodash';

export type UseHelper = {
  helperVisible: ComputedRef<boolean>;
  helperMessage: ComputedRef<string | undefined>;
  helperShow: (msg?: string) => void;
  helperHide: () => void;
  platformModifierLabel: () => string;
};

export default function useHelper(): UseHelper {
  // ================ DATA ================
  const visible: Ref<boolean> = ref(false);
  const message: Ref<string | undefined> = ref(undefined);
  let timeout: number;

  // ============ DEPENDENCIES ============

  // ============== COMPUTED ==============
  const helperVisible = computed(() => unref(visible) && !isNil(message));
  const helperMessage = computed(() => unref(message));

  // =============== METHODS ==============
  const platformModifierLabel = (): string => {
    const mac = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
    return mac ? '⌘' : 'CTRL';
  };

  const startTimeout = () => {
    timeout = setTimeout(() => {
      visible.value = false;
    }, 1000);
  };

  const helperShow = (msg?: string) => {
    // document.getElementById('olmap').classList.remove('show-wheel-hint');
    visible.value = false;
    clearTimeout(timeout);
    if (msg) {
      message.value = msg;
    }
    if (isNil(unref(message))) {
      console.error('JclMap: @useHelper > message is undefined or null!');
    } else {
      visible.value = true;
      startTimeout();
    }
  };

  const helperHide = () => {
    clearTimeout(timeout);
    visible.value = false;
  };

  // ============== WATCHERS ==============

  return {
    helperVisible,
    helperMessage,
    helperShow,
    helperHide,
    platformModifierLabel
  };
}
