import { renderSlot as _renderSlot, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, Fragment as _Fragment, withCtx as _withCtx } from "vue"

export function render(_ctx, _cache) {
  const _component_ecl_icon = _resolveComponent("ecl-icon")
  const _component_app_link = _resolveComponent("app-link")

  return (_openBlock(), _createBlock(_component_app_link, {
    to: _ctx.url,
    target: _ctx.linkTarget,
    "aria-label": _ctx.cAriaLabel,
    class: _ctx.className()
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        (!_ctx.icon)
          ? (_openBlock(), _createBlock("span", {
              key: 0,
              innerHTML: _ctx.label
            }, null, 8, ["innerHTML"]))
          : (_openBlock(), _createBlock(_Fragment, { key: 1 }, [
              (_ctx.iconPosition === _ctx.EclLinkIconPosition.Before)
                ? (_openBlock(), _createBlock(_component_ecl_icon, {
                    key: 0,
                    class: "ecl-link__icon",
                    icon: _ctx.icon,
                    size: _ctx.iconSize,
                    rotate: _ctx.iconRotate
                  }, null, 8, ["icon", "size", "rotate"]))
                : _createCommentVNode("", true),
              _createVNode("span", {
                class: "ecl-link__label",
                innerHTML: _ctx.label
              }, null, 8, ["innerHTML"]),
              (_ctx.iconPosition === _ctx.EclLinkIconPosition.After)
                ? (_openBlock(), _createBlock(_component_ecl_icon, {
                    key: 1,
                    class: "ecl-link__icon",
                    icon: _ctx.icon,
                    size: _ctx.iconSize,
                    rotate: _ctx.iconRotate
                  }, null, 8, ["icon", "size", "rotate"]))
                : _createCommentVNode("", true)
            ], 64))
      ])
    ]),
    _: 3
  }, 8, ["to", "target", "aria-label", "class"]))
}