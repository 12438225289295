/* eslint-disable @typescript-eslint/camelcase */
import { ref, Ref, computed, ComputedRef } from 'vue';
import { MapSettings } from '../types';
import { cloneDeep, merge, isNil } from 'lodash';
import { Map } from 'ol';
import { fromLonLat } from 'ol/proj';
import iconSprite from '../icons/sprite-controls.svg';

import { t } from '@/locale';

const makeIcon = (iconName: string): HTMLElement => {
  const element = document.createElement('span');
  element.innerHTML = `<svg aria-hidden="true" focusable="false" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><use xlink:href="${iconSprite}#${iconName}"></use></svg>`;
  return element;
};

export type UseData = {
  mapComponent: Ref<HTMLElement>;
  mapElement: Ref<HTMLElement>;
  mapInstance: Ref<Map>;
  settings: ComputedRef<MapSettings>;
};

export default function useData(props): UseData {
  // ================ DATA ================
  const mapComponent: Ref<HTMLElement> = ref(null);
  const mapElement: Ref<HTMLElement> = ref(null);
  const mapInstance: Ref<Map> = ref(null);
  const defaultSettings: MapSettings = {
    tile: {
      url: 'https://gisco-services.ec.europa.eu/maps/tiles/OSMBlossomComposite/EPSG3857/{z}/{x}/{y}.png'
    },
    attributions: [
      {
        content: t('APP.MAP.ATTRIBUTION_CONTENT'),
        disclaimer: {
          create: true,
          label: t('APP.MAP.DISCLAIMER'),
          separator: '|',
          title: t('APP.MAP.DISCLAIMER'),
          content: t('APP.MAP.DISCLAIMER_CONTENT')
        }
      }
    ],
    WMS: {
      identifyOnOver: false,
      tooltip: {
        formatting: response => {
          if (!isNil(response)) {
            return response.features[0].properties.name_html;
          }
          return '';
        }
      },
      api: {
        basePath: process.env.VUE_APP_MAP_GEO_URL,
        wms: 'geoserver/geonode/wms?',
        wfs: 'geoserver/geonode/wfs?'
      },
      defaultOptions: {
        service: 'WMS',
        version: '1.1.1',
        request: 'GetMap',
        format: 'image/png',
        exceptions: 'application/vnd.ogc.se_inimage',
        info_format: 'application/json',
        transparent: true
      },
      useLayer: 'first',
      layers: []
    },
    olSettings: {
      interactions: {
        altShiftDragRotate: false,
        shiftDragZoom: false
      },
      controls: {
        rotate: false,
        zoomOptions: {
          zoomInLabel: makeIcon('zoom-in'),
          zoomInTipLabel: t('APP.MAP.ZOOM_IN'),
          zoomOutLabel: makeIcon('zoom-out'),
          zoomOutTipLabel: t('APP.MAP.ZOOM_OUT')
        }
      }
    },
    view: {
      projection: 'EPSG:4326',
      center: fromLonLat([-0.09, 51.505]),
      zoom: 3.6,
      animateTime: {
        center: 300,
        zoom: 300
      },
      minZoom: 3,
      maxZoom: 9
    },
    customInteraction: {
      mobile: {
        useDoubleTouch: true,
        useDoubleTouchLabel: 'APP.MAP.HINT_USE_TWO_FINGER'
      },
      desktop: {
        useWheelPlatformKey: false,
        useWheelPlatformKeyLabel: 'APP.MAP.HINT_WHEEL_CTRL_MODIFIER_KEY'
      }
    }
  };

  const settings: ComputedRef<MapSettings> = computed(
    (): MapSettings => {
      const finalSettings = merge(cloneDeep(defaultSettings), props.settings);
      console.log('@JclMap > finalSettings:', finalSettings);
      return finalSettings;
    }
  );
  // ============ DEPENDENCIES ============

  // ============== COMPUTED ==============

  // =============== METHODS ==============

  // ============== WATCHERS ==============

  return {
    mapComponent,
    mapElement,
    mapInstance,
    settings
  };
}
