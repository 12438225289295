import { defineComponent, SetupContext } from 'vue';
import { useModelWrapper } from '../../../utils/modelWrapper';
import { isNil } from 'lodash';
import { makeUUID } from '../../../helpers/random';

export enum EclTextFieldType {
  Text = 'text',
  Password = 'password',
}
export enum EclTextFieldSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export default defineComponent({
  name: 'EclTextField',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: String,
      default: '',
      required: true,
    },
    type: {
      required: false,
      type: String,
      default: EclTextFieldType.Text,
      enum: Object.values(EclTextFieldType),
    },
    classes: {
      required: false,
      type: Array as () => Array<string>,
    },
    inputId: {
      required: false,
      type: String,
    },
    label: {
      required: false,
      type: String,
    },
    helperText: {
      required: false,
      type: String,
    },
    invalid: {
      required: false,
      type: Boolean,
      default: false,
    },
    invalidText: {
      required: false,
      type: String,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    required: {
      required: false,
      type: Boolean,
      default: false,
    },
    requiredText: {
      required: false,
      type: String,
      default: '*',
    },
    optionalText: {
      required: false,
      type: String,
      default: ' (optional)',
    },
    showLabelType: {
      required: false,
      type: Boolean,
      default: true,
    },
    size: {
      required: false,
      type: String,
      default: EclTextFieldSize.Medium,
      enum: Object.values(EclTextFieldSize),
    },
  },
  computed: {
    id(): string {
      return isNil(this.inputId) ? 'ecltxtfield-' + makeUUID() : this.inputId;
    },
    classInput(): string {
      const className: string[] = this.classes ? [...this.classes] : [];
      if (this.size && this.size !== '') {
        className.push(`ecl-text-input--${this.size}`);
      }
      if (this.invalid) {
        className.push('ecl-text-input--invalid');
      }
      className.unshift('ecl-text-input');
      return className.join(' ');
    },
    classLabel(): string {
      const className: string[] = ['ecl-form-label'];
      if (this.invalid) {
        className.push('ecl-form-label--invalid');
      }
      return className.join(' ');
    },
  },
  setup(props, { emit }) {
    return {
      value: useModelWrapper(props, emit as SetupContext['emit']),
    };
  },
});
