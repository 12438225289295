import { Ref, ref, SetupContext, toRefs, unref } from 'vue';
import { some } from 'lodash';
import { EclSelect } from '../';
import useSelect from './useSelect';

export type UseDropdown = {
  isOpen: Ref<boolean>;
  toggleOpen: () => void;
  open: () => void;
  close: () => void;
  handleEnterComponent: () => void;
  handleInputMousedown: () => void;
  handleBlurComponent: () => void;
};

export default function useDropdown(
  props: InstanceType<typeof EclSelect>['$props'],
  context: SetupContext,
  dependencies: {
    useSelect: ReturnType<typeof useSelect>;
  },
): UseDropdown {
  const { disabled } = toRefs(props);

  // ============ DEPENDENCIES ============

  const eclSelectBlur = dependencies.useSelect.eclSelectBlur;

  // ================ DATA ================

  const isOpen: Ref<boolean> = ref(false);

  // =============== METHODS ==============

  const open = (): void => {
    if (unref(disabled)) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    document.body.addEventListener('click', globalBlurEvent);

    isOpen.value = true;
    context.emit('open');
  };

  const close = (): void => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    document.body.removeEventListener('click', globalBlurEvent);
    isOpen.value = false;
    context.emit('close');
  };

  const toggleOpen = (): void => {
    isOpen.value ? close() : open();
  };

  const globalBlurEvent = (event: Event): void => {
    const clickOutside = some(eclSelectBlur, element => {
      return element === event.target || element.contains(event.target as Node);
    });
    if (clickOutside === false) {
      close();
    }
  };

  const handleBlurComponent = (): void => {
    close();
  };

  const handleEnterComponent = (): void => {
    toggleOpen();
  };

  const handleInputMousedown = (): void => {
    toggleOpen();
  };

  return {
    isOpen,
    toggleOpen,
    open,
    close,
    handleEnterComponent,
    handleInputMousedown,
    handleBlurComponent,
  };
}
