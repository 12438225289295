import { useQuery } from '@vue/apollo-composable';
import { appConfigs } from '@/configs';
import QUERY_SITE from './graphql/Site.query.gql';
// import QUERY_SITE_PAGE_LIST from './graphql/SitePageList.query.gql';
import QUERY_SITE_MENU from './graphql/SiteMenu.query.gql';
// import QUERY_CONTEXT_MENU from './graphql/ContextMenu.query.gql';
// import { WidgetOptions } from '../../components/Widgets';
// import { WidgetTypes } from '../../components/Widgets/useWidget';
// import { PRIORITY_FIELD_TYPE } from '@/managers/DataManager/usePriorityField';
// import { EclBreadcrumbItem } from '@/shared/ecl/src/components';

export type SiteContextResponse = {
  slug: string;
};

export type SiteResponse = {
  site: {
    name: string;
    breadcrumbs: { url: string; title: string }[];
    contentOwnerDetails: string;
    contextList: SiteContextResponse[];
  };
};

export type SitePageConfigComponentProps = {
  class?: string;
};

export type SitePageConfigComponentPropsAsyncProps = {
  asyncProps: {
    [key: string]: {
      id: string | number;
      fromContext?: boolean;
    };
  };
};

export type SitePageConfigComponentPropsTitle = {
  value: string;
} & SitePageConfigComponentProps;

export type SitePageConfigComponentPropsText = {
  value: string;
} & SitePageConfigComponentProps;

export type SitePageConfigComponentPropsSpan = {
  value?: string;
} & SitePageConfigComponentProps;

export type SitePageConfigComponentPropsImage = {
  src: string;
  alt?: string;
} & SitePageConfigComponentProps;

export type SitePageConfigComponentPropsButton = {
  text: string;
  url: string;
  target?: string;
  type?: string;
  icon?: string;
  iconRotate?: number;
} & SitePageConfigComponentProps;

// export type SitePageConfigComponentPropsIndicatorHeadlineList = {
//   indicatorSlugList: string[];
//   chartSettings?: WidgetOptions['chartSettings'];
//   chartComponents?: WidgetOptions['chartComponents'];
//   routeName?: string;
//   linkToIndicatorDetail?: boolean;
// } & SitePageConfigComponentProps &
//   SitePageConfigComponentPropsAsyncProps;

// export type SitePageConfigComponentPropsAutocomplete = {
//   autocomplete: {
//     type: string;
//     tree?: boolean;
//     placeholder?: string;
//     labelKey?: string;
//     valueKey?: string;
//     size?: string;
//   };
//   button: SitePageConfigComponentPropsButton;
// } & SitePageConfigComponentProps &
//   SitePageConfigComponentPropsAsyncProps;

// export type SitePageConfigComponent = {
//   type: string;
//   props:
//     | SitePageConfigComponentPropsTitle
//     | SitePageConfigComponentPropsText
//     | SitePageConfigComponentPropsImage
//     | SitePageConfigComponentPropsButton
//     | SitePageConfigComponentPropsIndicatorHeadlineList
//     | SitePageConfigComponentPropsAutocomplete
//     | SitePageConfigComponentPropsSpan;
// };

// export type SitePageConfigColumn = {
//   props: {
//     col: number;
//     lg?: number;
//     md?: number;
//     sm?: number;
//     xs?: number;
//     style?: string;
//   };
//   components: SitePageConfigComponent[];
// };

// export type SitePageConfigRow = {
//   columns: SitePageConfigColumn[];
// };

// export type SitePageConfigSection = {
//   container: false;
//   rows: SitePageConfigRow[];
// };

// export type SitePageConfigDefault = {
//   name: string;
//   header: {
//     title: string;
//     description: string;
//   };
// };

// export type SitePageConfigPageBuilder = {
//   sections: SitePageConfigSection[];
// } & SitePageConfigDefault;

// export type SitePageConfigDashboardIndicatorListChartItem = {
//   slug: keyof WidgetTypes;
//   chartSettings?: WidgetOptions['chartSettings'];
//   chartComponents?: WidgetOptions['chartComponents'];
//   chartWidgetSettings?: WidgetOptions['chartWidgetSettings'];
// };

// export type SitePageConfigDashboardAverage = {
//   tercet: undefined | 'parent' | 'EU'; // Rende visibili le medie (tercet) in my place
// };

// export type SitePageConfigDashboardIndicatorList = {
//   average?: SitePageConfigDashboardAverage;
//   template: string;
//   templateList?: string[];
//   chartItem?: SitePageConfigDashboardIndicatorListChartItem; // slug del grafico da visualizzare per HeadlineIndicatorCard
//   chartList?: SitePageConfigDashboardIndicatorListChartItem[]; // lista degli slug dei grafici da visualizzare per HeadlineIndicatorInline
//   props?: {
//     chartSettings?: WidgetOptions['chartSettings'];
//     chartComponents?: WidgetOptions['chartComponents'];
//     chartWidgetSettings?: WidgetOptions['chartWidgetSettings'];
//     routeName?: string;
//     linkToIndicatorDetail?: boolean;
//   };
// };

// export type SitePageConfigDashboardIndicatorDetail = {
//   average?: SitePageConfigDashboardAverage;
//   defaultTabs: keyof WidgetTypes[];
//   chartProps?: {
//     chartSettings?: WidgetOptions['chartSettings'];
//     chartComponents?: WidgetOptions['chartComponents'];
//     chartWidgetSettings?: WidgetOptions['chartWidgetSettings'];
//   };
// };

export type HeaderExtraGoToDashboardItem = {
  // type: string;
  routeName: string;
  icon?: string;
  // label?: string;
};
// | {
//     type: string;
//     url: string;
//     label: string;
//   };

// export type SitePageConfigDashboardPlace = {
//   highlightList?: {
//     enabled: boolean;
//   };
//   headerExtra?: {
//     goToTrends?: HeaderExtraGoToDashboardItem;
//     goToTercet?: HeaderExtraGoToDashboardItem;
//   };
//   indicatorHeadlineList?: {
//     props: SitePageConfigComponentPropsIndicatorHeadlineList;
//   };
//   indicatorList?: SitePageConfigDashboardIndicatorList;
//   buttonEuDoesForMe?: boolean;
// };

// export type SitePageConfigDashboardTrends = {
//   indicatorDetail?: SitePageConfigDashboardIndicatorDetail;
//   priority?: {
//     type: PRIORITY_FIELD_TYPE[keyof PRIORITY_FIELD_TYPE];
//     showSwitch: boolean;
//   };
// };

export enum MAP_TYPE {
  TERCET = 'tercet'
}

// export type SitePageConfigDashboard = {
//   mainStyle?: string;
//   preset?: Record<string, string | number | boolean>;
//   map: {
//     enabled?: boolean;
//     type?: string;
//     homeControl?: {
//       enabled: boolean;
//     };
//     outermostControl?: {
//       enabled: boolean;
//       defaultVisible: boolean;
//     };
//     legend?: {
//       nutsVersion?: boolean;
//     };
//   };
// } & SitePageConfigDefault &
//   SitePageConfigDashboardPlace &
//   SitePageConfigDashboardTrends;

// export type SitePageConfigResponse = SitePageConfigPageBuilder | SitePageConfigDashboard;

// export type SitePageConfigMeta = {
//   context: boolean;
//   breadcrumb: { type: string; items: EclBreadcrumbItem[] };
// };

// export type SitePageConfigMetaResponse = {
//   context: boolean;
//   breadcrumb: EclBreadcrumbItem[] | { type: string; items: EclBreadcrumbItem[] };
// };

// export type SitePageListItemResponse = {
//   name: string;
//   path: string;
//   type: string;
//   meta: SitePageConfigMetaResponse;
//   content: SitePageConfigResponse;
// };

// export type SitePageListResponse = {
//   pageList: SitePageListItemResponse[];
// };

export type SiteMenuItemResponse = {
  to: string;
  label: string;
  children?: SiteMenuItemResponse[];
};

export type SiteMenuResponse = {
  menu: {
    menu: SiteMenuItemResponse[];
  };
};

export type ContextualMenuItemResponse = SiteMenuItemResponse & {
  icon: string;
};

export type ContextualMenuResponse = {
  contextMenu: {
    items: ContextualMenuItemResponse[];
    context: {
      name: string;
    };
  };
};

class SiteClient {
  static site(siteId: string = appConfigs.api.params.site) {
    const { result, onResult, onError, refetch } = useQuery<SiteResponse>(QUERY_SITE, {
      siteId: siteId
    });

    return {
      result,
      refetch,
      onResult,
      onError
    };
  }

  // static pageList(siteId: string = appConfigs.api.params.routes) {
  //   const { result, onResult, onError, refetch } = useQuery<SitePageListResponse>(QUERY_SITE_PAGE_LIST, {
  //     siteId: siteId
  //   });

  //   return {
  //     result,
  //     refetch,
  //     onResult,
  //     onError
  //   };
  // }

  static menu(siteName: string = appConfigs.api.params.menu) {
    const { result, onResult, onError, refetch } = useQuery<SiteMenuResponse>(QUERY_SITE_MENU, {
      site: siteName
    });

    return {
      result,
      refetch,
      onResult,
      onError
    };
  }

  // static contextualMenu(contextId: number | string) {
  //   const { result, onResult, onError, refetch } = useQuery<ContextualMenuResponse>(QUERY_CONTEXT_MENU, {
  //     contextId: contextId
  //   });

  //   return {
  //     result,
  //     refetch,
  //     onResult,
  //     onError
  //   };
  // }
}

export default SiteClient;
