import { toRefs, computed, ComputedRef, watch, unref, nextTick } from 'vue';
import { assign, filter, isNil, map } from 'lodash';
import { ListItem } from '../types';
import { UseValue } from './useValue';
import { UseDropdown } from './useDropdown';

export default function useList(props, context, dependencies) {
  const {
    multipleModelKeyValue,
    list,
    valueKey,
    labelKey,
    treeChildrenKey,
    listGrouped,
    tree,
    disabled,
    clearSearchOnSelect,
    defaultSearchAction,
    multiple
  } = toRefs(props);

  // ================ DATA ================

  // ============ DEPENDENCIES ============
  const popperInstance: UseDropdown['popperInstance'] = dependencies.popperInstance;
  const internalValue: UseValue['internalValue'] = dependencies.internalValue;
  const internalSelectedObject: UseValue['internalSelectedObject'] = dependencies.internalSelectedObject;
  const update: UseValue['update'] = dependencies.update;
  const close: UseDropdown['close'] = dependencies.close;
  const searchValue: UseValue['searchValue'] = dependencies.searchValue;
  const clearInternalSelectedObject: UseValue['clearInternalSelectedObject'] = dependencies.clearInternalSelectedObject;
  // const searchInputElement: Ref<HTMLElement> = dependencies.searchInputElement;

  // ============== COMPUTED ==============

  const extendItem = (data): ListItem => {
    const obj: ListItem = {
      value: data[valueKey.value],
      label: data[labelKey.value],
      _original: data,
      type: data?.itemType
    };

    if (unref(multipleModelKeyValue)) {
      const multipleModelValue = data[unref(multipleModelKeyValue)];
      if (multipleModelValue && !isNil(unref(internalValue)[multipleModelValue])) {
        // aggiorno l'oggetto selezionato se è imposta una value
        assign(internalSelectedObject.value, {
          [unref(multipleModelKeyValue)]: obj
        });
      }
    } else {
      if (unref(tree) === true) {
        if (!isNil(data[treeChildrenKey.value])) {
          obj[treeChildrenKey.value] = data[treeChildrenKey.value].map(item => extendItem(item));
        }
      }

      if (unref(multiple) === true) {
        if (
          internalValue.value instanceof Array &&
          internalValue.value?.includes(obj?.value) &&
          internalSelectedObject.value instanceof Array
        ) {
          internalSelectedObject.value.push(obj);
        }
      } else if (!isNil(internalValue.value) && obj?.value?.toString() === internalValue.value.toString()) {
        if (unref(tree) === false) {
          // aggiorno l'oggetto selezionato se è imposta una value
          internalSelectedObject.value = obj;
        } else {
          // verifico che l'oggetto sia una foglia (ultimo ramo nella lista) e lo imposto come selezionato
          if (isNil(data[treeChildrenKey.value])) {
            // aggiorno l'oggetto selezionato se è imposta una value
            internalSelectedObject.value = obj;
          }
        }
      }
    }

    return obj;
  };

  const filterList = (list: ListItem[][]): ListItem[][] => {
    if (unref(defaultSearchAction) && unref(searchValue) && list.length > 0) {
      const search = unref(searchValue);
      return map(list, (group: ListItem | ListItem[]) => {
        return filter(group, (item: ListItem) =>
          item.label
            .toString()
            .toLowerCase()
            .includes(search.toString().toLowerCase())
        );
      });
    }
    return list;
  };

  const filterListTree = (list: ListItem[]) => {
    const search = unref(searchValue);
    let newList = list;
    if (unref(defaultSearchAction) && search && list.length > 0) {
      newList = filter(list, (item: ListItem) => {
        let foundedString = false;
        if (item?.children) {
          item.children = filterListTree(item.children);
          foundedString = item.children.length > 0;
        } else if (item?.label) {
          const targetValue = item.label.toString().toLowerCase();
          foundedString = targetValue.includes(search.toString().toLowerCase());
        }
        return foundedString;
      });
    }
    return newList;
  };

  const extendedTreeList: ComputedRef<{ children: ListItem[] }> = computed((): {
    children: ListItem[];
  } => {
    if (unref(tree) === true) {
      clearInternalSelectedObject();
      const currentList = map(unref(list), item => extendItem(item));
      return {
        children: filterListTree(currentList)
      };
    }
    return { children: [] };
  });

  const extendedList: ComputedRef<ListItem[][]> = computed((): ListItem[][] => {
    if (unref(tree) === false) {
      let response: ListItem[][] = [];
      const countItems = list.value.length;
      clearInternalSelectedObject();
      if (countItems > 0) {
        if (listGrouped.value === true) {
          response = map(list.value, arr => {
            return map(arr, item => {
              return extendItem(item);
            });
          });
        } else {
          response = [
            map(list.value, item => {
              return extendItem(item);
            })
          ];
        }
      }

      return filterList(response);
    }
    return [];
  });

  const hasList: ComputedRef<boolean> = computed((): boolean => {
    if (unref(tree)) {
      return !isNil(unref(extendedTreeList)) && unref(extendedTreeList).children.length > 0;
    }
    return !isNil(unref(extendedList)) && unref(extendedList).length > 0;
  });

  // =============== METHODS ==============
  const isSelected = (item: ListItem): boolean => {
    if (unref(multiple) && unref(internalValue) instanceof Array) {
      return (unref(internalValue) as (number | string)[]).includes(item.value);
    } else if (unref(multipleModelKeyValue)) {
      const type = item.type;
      return !isNil(unref(internalValue)[type]) && unref(internalValue)[type] === item.value;
    }
    return internalValue.value === item.value;
  };

  const handleClickItem = (item: ListItem): void => {
    if (unref(disabled) === false) {
      if (unref(clearSearchOnSelect)) {
        searchValue.value = null;
        context.emit('searchClear');
      }

      update(item);
      close();
    }
  };

  // ============== WATCHERS ==============

  // se viene aggiornato il valore dell'externalValue
  // aggiorna tutti i dati
  watch(list, () => {
    // se viene aggiornata la lista viene rimosso l'elemento selezionato
    internalSelectedObject.value = null;
    nextTick(() => {
      // Aggiorna la posizione della dropdown appena cambia la lista delle opzioni
      const popper = unref(popperInstance);
      if (popper) {
        popper.update();
      }
    });
  });

  return {
    extendedList,
    extendedTreeList,
    hasList,
    isSelected,
    handleClickItem
  };
}
