import { defineComponent, ref } from 'vue';
import { isNil, isNumber } from 'lodash';
import { store } from '@/store';

function getTextBetweenCharacters(inputString, startChar, endChar) {
  const startIndex = inputString.indexOf(startChar);
  // console.log(startIndex);

  const endIndex = inputString.indexOf(endChar, startIndex + 1);

  if (startIndex === -1 || endIndex === -1 || endIndex <= startIndex) {
    return '';
  }

  return inputString.substring(startIndex + 1, endIndex);
}

export function manageTooltip(s: string): [string, number] {
  const listGlossary = ref([]);
  listGlossary.value = store.getters['tool/listGlossary'];

  if (isNil(s)) {
    return [s, -1];
  }

  const patternT = /\(([^)]*?)\)/g;
  const matchT = s.match(patternT);
  // console.log(matchT);

  if (!isNil(matchT)) {
    for (const n in matchT) {
      const code = getTextBetweenCharacters(matchT[n], '*', ')');
      // console.log(code.trim());
      if (code.trim() !== '') {
        const des = listGlossary.value.find(x => x.id === code.trim()).value;
        const orig = matchT[n].replace('(', '').replace(')', '').replace(' * ' + code.trim(), '');

        s = s.replace(matchT[n], '<a class="tooltip"> <span class="highlighted-text">' + orig + '</span><span class="tooltip-text">' + des + '</span></a> ');
      }
    }

    return [s, s.indexOf('<a class="tooltip">')];
  } else {
    return [s, -1];
  }
};

export function manageTooltipPDF(s: string) {
  const listGlossary = ref([]);
  listGlossary.value = store.getters['tool/listGlossary'];

  if (isNil(s)) {
    return s;
  }

  const patternT = /\(([^)]*?)\)/g;
  const matchT = s.match(patternT);

  if (!isNil(matchT)) {
    for (const n in matchT) {
      const code = getTextBetweenCharacters(matchT[n], '*', ')');
      if (code.trim() !== '') {
        const orig = matchT[n].replace('(', '').replace(')', '').replace(' * ' + code.trim(), '');
        s = s.replace(matchT[n], orig);
      }
    }

    return s;
  } else {
    return s;
  }
};
