import { Language } from '@/managers/LocaleManager';

interface LocaleConfigs {
  fallbackLocale: string;
  languages?: Language[];
}

const localeConfigs: LocaleConfigs = {
  fallbackLocale: 'en'
};

export default localeConfigs;
