import { computed } from 'vue';
import LocaleManager, { Language } from '@/managers/LocaleManager';

export default function useLanguage() {
  // ================ DATA ================

  // ============ DEPENDENCIES ============

  // ============== COMPUTED ==============
  const euLanguages = computed((): Language[] => {
    return LocaleManager.getEuLanguages().value;
  });

  const extraEuLanguages = computed((): Language[] => {
    return LocaleManager.getExtraEuLanguages().value;
  });

  // =============== METHODS ==============

  // ============== WATCHERS ==============

  return {
    euLanguages,
    extraEuLanguages,
  };
}
