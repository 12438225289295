import { toRefs, computed, ComputedRef, unref } from 'vue';
import { assign, isNil, isArray, isEmpty, map } from 'lodash';
import { MenuItem } from '../EclMenu.component';
import { EclMenu } from '..';
import { Router } from 'vue-router';

export type MenuItemExtended = {
  href: string;
  target: string;
  current: boolean;
  hasLink: boolean;
  hasChildren: boolean;
  hasChildrenNullable: null | boolean;
} & MenuItem;

export default function useNavItem(props: InstanceType<typeof EclMenu>['$props'], dependencies: { router: Router }) {
  const { items } = toRefs(props);

  // ================ DATA ================

  // ============ DEPENDENCIES ============

  const router = dependencies.router;
  const currentRoute = dependencies.router.currentRoute;

  // =============== METHODS ==============

  const getCleanToValue = (to: string | ComputedRef<string>): string => {
    return unref(to)
      ?.split('?')[0]
      ?.split('#')[0];
  };

  // ============== COMPUTED ==============

  const navItems = computed((): MenuItemExtended[] => {
    const currentPath: string = currentRoute.value.path;
    return map(unref(items), item => {
      const tmpItem = assign(
        {
          target: 'self',
          current: false,
          hasLink: true,
          hasChildren: false,
        },
        item,
      ) as MenuItemExtended;

      let to = unref(tmpItem.to);
      if (to && router.hasRoute(to)) {
        tmpItem.to = to = router.resolve({ name: to }).href;
      }
      const baseUrl = process?.env?.VUE_APP_BASE_URL ? `/${process.env.VUE_APP_BASE_URL}` : '';
      tmpItem.href = `${baseUrl.replace('//', '/')}${unref(tmpItem.to).replace('//', '/')}`.replace('//', '/');

      // Verifica se l'item ha un link valido
      tmpItem.hasLink = !isNil(tmpItem.to) && !isEmpty(tmpItem.to) && tmpItem.to !== 'javascript:void(0)';

      // Verifica se l'item ha dei child
      tmpItem.hasChildren = !isNil(tmpItem.children) && isArray(tmpItem.children);

      // Se non ha figli imposta il null (serve per la rimozione dell'atttributo html - con null viene rimosso con false si vede false)
      tmpItem.hasChildrenNullable = tmpItem.hasChildren ? true : null;

      // set/reset current flag for main and child items
      tmpItem.current = isNil(to) ? false : getCleanToValue(to) === currentPath;

      if (tmpItem.hasChildren) {
        tmpItem.children = map(tmpItem.children, (child: MenuItem) => {
          const tmpChild = assign(
            {
              target: 'self',
              current: false,
              hasLink: true,
              hasChildren: false,
            },
            child,
          ) as MenuItemExtended;
          let toChild = unref(tmpChild.to);
          if (toChild && router.hasRoute(toChild)) {
            tmpChild.to = toChild = router.resolve({ name: toChild }).href;
          }
          tmpChild.current = false;
          if (toChild && getCleanToValue(toChild) === currentPath) {
            tmpChild.current = true;
            tmpItem.current = true;
          }
          return tmpChild;
        });
      }

      return tmpItem;
    });
  });

  // ============== WATCHERS ==============

  return {
    navItems,
  };
}
