import { defineComponent, Ref, ref } from 'vue';
import { DeviceTypeCheckerTypes, DeviceTypeChecker } from '@/shared/ecl/src/helpers/detect-device';
import { EclLink, EclIcon } from '@/shared/ecl/src/components';
import i18n from '@/locale';
import { decodeString } from '../../helpers/string/mutate';

export default defineComponent({
  name: 'Accessibility-statement',
  props: {
  },
  directives: {
  },
  components: {
    EclLink, EclIcon
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  },
  setup(props) {
    const isMobileOrTablet: Ref<boolean> = ref(false);
    console.log('>>>>>>acceess1');
    const title = ref('');
    title.value = decodeString(i18n.global.t('accessibility-statement.title'));
    const s1 = ref('');
    s1.value = decodeString(i18n.global.t('accessibility-statement.section.1.content'));

    console.log('>>>>>>acceess2');

    const titles = ref([]);
    const sections = ref([]);
    for (let k = 2; k < 8; k++) {
      const title = ref('');
      title.value = decodeString(i18n.global.t('accessibility-statement.section.' + k + '.title'));
      console.log('>>>>>>1acceess' + k);
      const section = ref('');
      section.value = decodeString(i18n.global.t('accessibility-statement.section.' + k + '.content'));
      console.log('>>>>>>2acceess' + k);
      titles.value.push(title.value);
      sections.value.push(section.value);
    }

    console.log('>>>>>>acceess3');
    console.log(s1.value);

    console.log(titles.value);
    console.log(sections.value);

    const checkTabletOrMobile = () => {
      isMobileOrTablet.value =
        [DeviceTypeCheckerTypes.MOBILE, DeviceTypeCheckerTypes.TABLET].indexOf(DeviceTypeChecker()) >= 0;
    };

    window.addEventListener('resize', () => {
      checkTabletOrMobile();
    });
    checkTabletOrMobile();

    return {
      isMobileOrTablet,
      sections,
      titles,
      title,
      s1
    };
  }

});
