import { ref, Ref } from 'vue';

export default function useData() {
  // ecl menu root HTML element
  const eclMenuRoot: Ref<HTMLElement | null> = ref(null);
  // ecl menu inner HTML element
  const eclMenuInner: Ref<HTMLElement | null> = ref(null);
  // menu items HTML elements
  const navItemsElWrapper: Ref<HTMLElement[]> = ref([]);

  // ================ DATA ================

  // ============ DEPENDENCIES ============

  // ============== COMPUTED ==============

  // =============== METHODS ==============

  // ============== WATCHERS ==============

  return {
    eclMenuRoot,
    eclMenuInner,
    navItemsElWrapper,
  };
}
