import { computed, reactive, UnwrapRef, onUnmounted, Ref, onMounted, unref, ref, ComputedRef } from 'vue';
import { TooltipData } from '../types';
import { isNil } from 'lodash';

export type UseTooltip = {
  tooltipData: UnwrapRef<TooltipData>;
  tooltipShow: ComputedRef<boolean>;
  tooltipText: ComputedRef<string>;
  tooltipStyle: ComputedRef<string>;
};

export default function useTooltip(dependencies): UseTooltip {
  // ================ DATA ================
  const mouseInMap: Ref<boolean> = ref(false);
  const tooltipData: UnwrapRef<TooltipData> = reactive({
    throttle: null,
    position: null,
    text: null,
    dataObject: null
  });

  // ============ DEPENDENCIES ============
  const mapComponent: Ref<HTMLElement> = dependencies.mapComponent;

  // ============== COMPUTED ==============
  const show = computed(() => mouseInMap.value && !isNil(tooltipData.text) && tooltipData.text !== '');
  const text = computed(() => tooltipData.text);
  const style = computed(() => {
    return `left: ${tooltipData.position[0]}px; top: ${tooltipData.position[1]}px;`;
  });

  // =============== METHODS ==============
  // Triggered when mouse enter on map
  const mouseEnterMap = () => {
    mouseInMap.value = true;
  };

  // Triggered when mouse leave the map
  const mouseLeaveMap = () => {
    mouseInMap.value = false;
  };

  onMounted(() => {
    const mapElement = unref(mapComponent);
    if (!isNil(mapElement)) {
      // add mouse listeners
      mapElement.addEventListener('mouseleave', mouseLeaveMap.bind(this));
      mapElement.addEventListener('mouseenter', mouseEnterMap.bind(this));
    }
  });

  onUnmounted(() => {
    const mapElement = unref(mapComponent);
    if (!isNil(mapElement)) {
      // remove mouse listeners
      mapElement.removeEventListener('mouseleave', mouseLeaveMap.bind(this));
      mapElement.removeEventListener('mouseenter', mouseEnterMap.bind(this));
    }
  });

  // ============== WATCHERS ==============

  return {
    tooltipData,
    tooltipShow: show,
    tooltipText: text,
    tooltipStyle: style
  };
}
