import { defineComponent, watch, ref, onMounted, unref } from 'vue';
import { isNil, assign } from 'lodash';
import { useRouter, useRoute } from 'vue-router';
import EclLoader from '@/shared/ecl/src/components/EclLoader/EclLoader.component.vue';
import EclButton from '@/shared/ecl/src/components/EclButton/EclButton.component.vue';
import EclIcon from '@/shared/ecl/src/components/EclIcon/EclIcon.component.vue';
import Sticky from '@/directives/sticky';
import { store } from '@/store';
import FactSheet from '@/components/FactSheet/FactSheet.component.vue';
import useSticky from './composables/useSticky';

export default defineComponent({
  name: 'DetailPanel',

  directives: { Sticky },
  props: {
  },
  components: {
    EclLoader,
    EclIcon,
    EclButton,
    FactSheet,
  },
  methods: {
    forceUpdate: function() {
      this.$forceUpdate();
    }
  },
  setup(props) {
    // const route = useRoute();
    // const routeName = route.name as string;
    console.log('>>>>>>>>DETAIL PANEL >>>>>>>');
    const router = useRouter();
    const currentRoute = unref(router.currentRoute);
    const cmpSticky = useSticky();

    watch(() => store.getters['tool/urbadevId'], function() {
      console.log('AA');
    });

    const deactivateIds = () => {
      const tablinksId = document.getElementsByClassName('tablinksStrategy');
      for (let i = 0; i < tablinksId.length; i++) {
        tablinksId[i].className = tablinksId[i].className.replace(' active', '');
      }
    };

    function openTab(evt, tabId, s = null) {
      let i;
      const tabcontent = document.getElementsByClassName('tabcontent') as HTMLCollectionOf<HTMLElement>;
      for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = 'none';
      }
      const tablinks = document.getElementsByClassName('tablinks');
      for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(' active', '');
      }
      if (isNil(s)) {
        deactivateIds();
      }
      if (tabId !== '') {
        document.getElementById(tabId).style.display = 'block';
      }

      if (!isNil(evt)) {
        evt.target.classList.toggle('active');
      }
    };
    const keyDetail = ref('');
    const lfpId = ref('');
    const clickLfpds = ref([]);

    watch(() => store.getters['tool/clickInspire'], function() {
      console.log('>>>>>>WATCH CLICK INSPIRE');
      console.log(store.getters['tool/clickInspireId']);

      if (store.getters['tool/clickInspireId'] !== '') {
        console.log('>>openTab');
        console.log(store.getters['tool/clickInspireId']);

        openTab(null, 'FactSheet', store.getters['tool/clickInspireId']);

        lfpId.value = store.getters['tool/clickInspireId'];

        console.log(store.getters['tool/clickInspireIds']);
        clickLfpds.value = store.getters['tool/clickInspireIds'];

        keyDetail.value += 1;
        setTimeout(() => {
          store.commit('tool/openInspire', !store.getters['tool/openInspire']);
        }, 100);
      }
    });

    watch(() => store.getters['tool/clickInspireIds'], function() {
      const ids = store.getters['tool/clickInspireIds'];
      if (ids.length === 0) {
        openTab(null, '');
        // document.getElementById('OverviewBtn').classList.toggle('active');
      }
    });

    const activeId = () => {
      deactivateIds();
      if (!isNil(document.getElementById(store.getters['tool/clickInspireId']))) {
        document.getElementById(store.getters['tool/clickInspireId']).classList.toggle('active');
      }
    };
    watch(() => store.getters['tool/clickInspireId'], function() {
      console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>tool/clickInspireId');
      setTimeout(activeId, 400);
    });

    function closeInspire(id) {
      const ids = store.getters['tool/clickInspireIds'];
      // const id = store.getters['tool/clickInspireId'];
      const i = ids.indexOf(id);
      if (i !== -1) {
        ids.splice(i, 1);
        console.log('>>splice');
      }
      store.commit('tool/clickInspire', !store.getters['tool/clickInspire']);
      if (ids.length === 0) {
        store.commit('tool/clickInspireId', '');
        store.commit('tool/clickInspireIds', []);
        store.commit('tool/panelOpen', false);
      } else {
        store.commit('tool/clickInspireId', ids[0]);
        store.commit('tool/clickInspireIds', ids);
      }
    };
    const openInspire = (s) => {
      if (store.getters['tool/clickInspireIds'].indexOf(s) !== -1) {
        store.commit('tool/clickInspireId', s);
        store.commit('tool/clickInspire', !store.getters['tool/clickInspire']);

        deactivateIds();
        document.getElementById(s).classList.toggle('active');
      };
    };

    return {
      ...cmpSticky,
      openTab,
      lfpId,
      clickLfpds,
      openInspire,
      closeInspire,
      keyDetail
    };
  }
});
