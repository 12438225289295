import { ToolModuleStore } from './types';
import { MutationTree } from 'vuex';

export const mutations: MutationTree<ToolModuleStore> = {
  clickFo(state, comment: boolean): void {
    state.tool.clickFo = comment;
  },
  clickFoId(state, comment: string): void {
    state.tool.clickFoId = comment;
  },
  clickFoName(state, comment: string): void {
    state.tool.clickFoName = comment;
  },
  clickFoColor(state, comment: string): void {
    state.tool.clickFoColor = comment;
  },
  bboxSv(state, comment: []): void {
    state.tool.bboxSv = comment;
  },
  filterWho(state, comment: []): void {
    state.tool.filterWho = comment;
  },
  filterThemFocus(state, comment: []): void {
    state.tool.filterThemFocus = comment;
  },
  filterType(state, comment: []): void {
    state.tool.filterType = comment;
  },
  funding(state, comment: []): void {
    state.tool.funding = comment;
  },
  inspired(state, comment: []): void {
    state.tool.inspired = comment;
  },
  typeColor(state, comment: []): void {
    state.tool.typeColor = comment;
  },
  text1(state, comment: string): void {
    state.tool.text1 = comment;
  },
  clickInspire(state, comment: boolean): void {
    state.tool.clickInspire = comment;
  },
  clickInspireId(state, comment: string): void {
    state.tool.clickInspireId = comment;
  },
  clickInspireIds(state, comment: []): void {
    state.tool.clickInspireIds = comment;
  },
  openInspire(state, comment: boolean): void {
    state.tool.openInspire = comment;
  },
  combInspire(state, comment: boolean): void {
    state.tool.combInspire = comment;
  },
  monoInspire(state, comment: boolean): void {
    state.tool.monoInspire = comment;
  },
  totalCountInspire(state, comment: number): void {
    state.tool.totalCountInspire = comment;
  },
  panelOpen(state, comment: boolean): void {
    state.tool.panelOpen = comment;
  },
  inspireKey(state, comment: []): void {
    state.tool.inspireKey = comment;
  },
  inspireFund(state, comment: []): void {
    state.tool.inspireFund = comment;
  },
  listFund(state, comment: []): void {
    state.tool.listFund = comment;
  },
  listPillar(state, comment: []): void {
    state.tool.listPillar = comment;
  },
  pillarInspire(state, comment: []): void {
    state.tool.pillarInspire = comment;
  },
  listGlossary(state, comment: []): void {
    state.tool.listGlossary = comment;
  },
  listKeyw(state, comment: []): void {
    state.tool.listKeyw = comment;
  },
};
