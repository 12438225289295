import { Module } from 'vuex';
import { RootState } from '../../types';
import { ToolModuleStore } from './types';

import { getters } from './getters';
import { mutations } from './mutations';

export const getDefaultToolMeta = (): ToolModuleStore => {
  return {
    tool: {
      clickFo: false,
      clickFoId: '',
      clickFoName: '',
      clickFoColor: '',
      bboxSv: [],
      filterWho: [],
      filterThemFocus: [],
      filterType: [],
      funding: [],
      typeColor: [],
      text1: '',
      clickInspire: false,
      clickInspireId: '',
      clickInspireIds: [],
      openInspire: false,
      combInspire: true,
      monoInspire: true,
      totalCountInspire: 0,
      panelOpen: false,
      inspireKey: [],
      inspireFund: [],
      listFund: [],
      listPillar: [],
      pillarInspire: [],
      listGlossary: [],
      listKeyw: [],
      inspired: [],
    }
  };
};

export const state: ToolModuleStore = getDefaultToolMeta();

export const tool: Module<ToolModuleStore, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations
};
