import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "ecl-container" }

export function render(_ctx, _cache) {
  const _component_ecl_breadcrumb = _resolveComponent("ecl-breadcrumb")

  return (_openBlock(), _createBlock("div", {
    class: _ctx.classes,
    style: _ctx.backgroundImage ? 'background-image: url(' + _ctx.backgroundImage + ');' : null
  }, [
    _createVNode("div", _hoisted_1, [
      (_ctx.breadcrumbList)
        ? (_openBlock(), _createBlock(_component_ecl_breadcrumb, {
            key: 0,
            class: _ctx.className('__breadcrumb'),
            breadcrumb: _ctx.breadcrumbList,
            ellipsisLimit: _ctx.breadcrumbEllipsisLimit,
            type: _ctx.type
          }, _createSlots({ _: 2 }, [
            _renderList(['breadcrumb_before_list','breadcrumb_before_item','breadcrumb_after_item','breadcrumb_after_list'], (slotName) => {
              return {
                name: slotName,
                fn: _withCtx((slotData) => [
                  _renderSlot(_ctx.$slots, slotName, slotData)
                ])
              }
            })
          ]), 1032, ["class", "breadcrumb", "ellipsisLimit", "type"]))
        : _createCommentVNode("", true),
      (_ctx.metaInfo)
        ? (_openBlock(), _createBlock("div", {
            key: 1,
            class: _ctx.className('__meta'),
            innerHTML: _ctx.metaInfo
          }, null, 10, ["innerHTML"]))
        : _createCommentVNode("", true),
      (_ctx.pageTitle)
        ? (_openBlock(), _createBlock("h1", {
            key: 2,
            class: _ctx.className('__title'),
            innerHTML: _ctx.pageTitle
          }, null, 10, ["innerHTML"]))
        : _createCommentVNode("", true),
      (_ctx.description)
        ? (_openBlock(), _createBlock("p", {
            key: 3,
            class: _ctx.className('__description'),
            innerHTML: _ctx.description
          }, null, 10, ["innerHTML"]))
        : _createCommentVNode("", true)
    ])
  ], 6))
}