import { getCurrentInstance } from 'vue';

export default function useEcl(): { theme: string } {
  const app = getCurrentInstance();
  const eclTheme = app?.appContext.config.globalProperties.$ecl.theme;

  return {
    theme: eclTheme,
  };
}
