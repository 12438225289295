export * from './deep-merge';
export * from './logo';

function baseClassName(baseClass: string, type: string | undefined | null, suffix: string | undefined | null): string {
  let newClass = baseClass;
  if (type) {
    newClass += '-' + type;
  }
  if (suffix) {
    newClass += suffix;
  }
  return newClass;
}

export { baseClassName };
